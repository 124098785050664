import React, { useEffect, useState } from 'react'
import Header from '../components/shared/Header'
import Navbar from '../components/shared/Navbar'
import TransactionTagSummaryByDateWidget from '../components/transactions/TransactionTagSummaryByDateWidget'
import { useSearchParams } from 'react-router-dom';
import TransactionMonthlySummaryWidget from '../components/transactions/TransactionMonthlySummaryWidget';
import TransactionMonthlySummaryByTagWidget from '../components/transactions/TransactionMonthlySummaryByTagWidget';


function TransactionsAnalyticsPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageParams, setPageParams] = useState({
    start_date: searchParams.get("startDate") || true,
    end_date: searchParams.get("endDate") || true
  });

  useEffect(() => {
    setPageParams((prevState) => ({
      ...prevState,
      start_date: searchParams.get("startDate"),
      end_date: searchParams.get("endDate")
    }))
  }, [searchParams])

  return (
    <>
      <Header title='Transactions' />
      <Navbar currentPage='Transactions' />

      <TransactionTagSummaryByDateWidget 
        startDate={pageParams.start_date} 
        endDate={pageParams.end_date} 
      />
      <div className="mb-8"></div>

      <TransactionMonthlySummaryWidget />
      <div className="mb-8"></div>

      <TransactionMonthlySummaryByTagWidget />
    </>
  )
}

export default TransactionsAnalyticsPage