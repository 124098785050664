import React, { useContext, useEffect, useState } from 'react'
import Card from '../shared/Card'
import TransactionContext from '../../context/TransactionContext'
import TransactionTagContext from '../../context/TransactionTagContext'
import CurrencyContext from '../../context/CurrencyContext'
import UserContext from '../../context/UserContext'
import moment from 'moment'
import CurrencyLabel from '../shared/CurrencyLabel'
import WidgetHeader from '../shared/WidgetHeader'
import ResponsiveTable from '../shared/ResponsiveTable'
import DataDependencyContext from '../../context/DataDependencyContext'

function TransactionTagSummaryByDateWidget({startDate, endDate}) {
  const {transactionList, createViewModel} = useContext(TransactionContext)
  const {transactionTagList} = useContext(TransactionTagContext)
  const {calculateCurrency} = useContext(CurrencyContext)
  const {transactionBaseCurrency} = useContext(UserContext)
  const {isFullDataLoaded} = useContext(DataDependencyContext)

  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isFullDataLoaded) {
      let dataTable = []

      // Get all valid tag list
      const validTagList = transactionTagList.filter(tag => tag.analytics_period_summary)
      const validTagIdList = validTagList.map((tag) => tag.id)
      
      // Get all transaction from defined date
      // Create VM for easy filtering
      const validTransactionList = transactionList.filter((t) => {
        return t.transaction_date >= startDate && t.transaction_date <= endDate && !t.is_prepaid
      })
      const validTransactionVMList = validTransactionList.map(t => createViewModel(t))

      // For each tag, calculate the income and expense total
      validTagIdList.forEach((tagId, index) => {
        const incomeVMList = validTransactionVMList.filter(vm => vm.type === 'Income' && vm.tagIdList.includes(tagId))

        const incomeTotal = incomeVMList.reduce((total, item) => {
          const v = calculateCurrency(transactionBaseCurrency, item.currency, moment().format('YYYY-MM-DD'), item.amount)      
          return total + v
        }, 0.0)    
        
        const expenseVMList = validTransactionVMList.filter(vm => vm.type === 'Expense' && vm.tagIdList.includes(tagId))

        const expenseTotal = expenseVMList.reduce((total, item) => {
          const v = calculateCurrency(transactionBaseCurrency, item.currency, moment().format('YYYY-MM-DD'), item.amount)      
          return total - v
        }, 0.0)    

        const netTotal = incomeTotal + expenseTotal

        if (incomeVMList.length > 0 || expenseVMList.length > 0) {
          dataTable.push([
            validTagList[index].name, 
            incomeTotal > 0.0 ? 
              <CurrencyLabel 
                currency={transactionBaseCurrency}
                value={incomeTotal}
                maximumFractionDigits={0}
                theme='gray'
              /> : <></>, 
            expenseTotal < 0.0 ? 
              <CurrencyLabel 
                currency={transactionBaseCurrency}
                value={expenseTotal}
                maximumFractionDigits={0}
                theme='gray'
              /> : <></>, 
            netTotal !== 0.0 ? 
              <CurrencyLabel 
                currency={transactionBaseCurrency}
                value={netTotal}
                maximumFractionDigits={0}
              /> : '-'
          ])        
        }
      });

      setTableHeaderList(['Tag', `Income in ${transactionBaseCurrency}`, `Expense in ${transactionBaseCurrency}`, `Total in ${transactionBaseCurrency}`])
      setTableDataList(dataTable)

      setIsLoading(false)
    }    
  }, [isFullDataLoaded])

  if (isLoading) {
    return (
      <Card className={`mb-8`}>
        <WidgetHeader title='Tag Summary' />
        <div className="text-xl">Loading ...</div>
      </Card>
    )
  }

  return (
    <>
      <Card>
        <WidgetHeader title='Tag Summary' />

        <div className='mb-2'>Date Period: {startDate} - {endDate}</div>

        <div className="flex">
          <ResponsiveTable headerList={tableHeaderList} dataTable={tableDataList} />
        </div>
      </Card>
    </>
  )
}

export default TransactionTagSummaryByDateWidget