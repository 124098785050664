import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../context/UserContext'

function ToggleButton({className, id, value, onClick, state, label}) {
  const {colorTheme} = useContext(UserContext)

  const [isActive, setIsActive] = useState(state)

  useEffect(() => {
    setIsActive(state)
  }, [state])

  return (
    <>
      <button type='button' className={`px-3 py-1 mr-2 rounded ${isActive ? colorTheme.primary_bg_color + ' ' + colorTheme.inverted_text_color : colorTheme.secondary_bg_color + ' ' + colorTheme.default_text_color} ${className}`} id={id} value={value} onClick={(e) => {
        setIsActive(!isActive)
        onClick(e)
      }}>
        {label}
      </button>
    </>
  )
}

export default ToggleButton
