import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import UserContext from '../../context/UserContext'
import RolePermissionContainer from './RolePermissionContainer'

function Navbar({currentPage}) {
  const {colorTheme} = useContext(UserContext)

  const selectedClass = `uppercase ${colorTheme.primary_text_color} ${colorTheme.primary_border_color} border-b-2 mr-4 lg:mr-8 mb-4`
  const nonSelectedClass = `uppercase ${colorTheme.secondary_text_color} hover:${colorTheme.primary_text_color} ${colorTheme.default_border_color} border-b-2 mr-4 lg:mr-8 mb-4`
  return (
    <div className='flex mb-8 flex-wrap'>
      <Link to='/dashboard' className={`${currentPage === 'Dashboard' ? selectedClass : nonSelectedClass}`}>Dashboard</Link>
      <Link to='/accounts' className={`${currentPage === 'Accounts' ? selectedClass : nonSelectedClass}`}>Accounts</Link>
      <Link to='/investments' className={`${currentPage === 'Investments' ? selectedClass : nonSelectedClass}`}>Investments</Link>
      <Link to='/transactions' className={`${currentPage === 'Transactions' ? selectedClass : nonSelectedClass}`}>Transaction</Link>
      <Link to='/transaction-tags' className={`${currentPage === 'TransactionTags' ? selectedClass : nonSelectedClass}`}>Transaction Tags</Link>
      <RolePermissionContainer permission={'view-currency-page'}>
        <Link to='/currency' className={`${currentPage === 'Currency' ? selectedClass : nonSelectedClass}`}>Currency</Link>
      </RolePermissionContainer>
      <RolePermissionContainer permission={'enable-movie-app'}>
        <Link to='/actors' className={`${currentPage === 'Actors' ? selectedClass : nonSelectedClass}`}>Actors</Link>
        <Link to='/movies' className={`${currentPage === 'Movies' ? selectedClass : nonSelectedClass}`}>Movies</Link>
      </RolePermissionContainer>
      <RolePermissionContainer permission={'enable-energy-app'}>
        <Link to='/energy' className={`${currentPage === 'Energy' ? selectedClass : nonSelectedClass}`}>Energy</Link>
      </RolePermissionContainer>
      <Link to='/settings' className={`${currentPage === 'Settings' ? selectedClass : nonSelectedClass}`}>Settings</Link>
      
    </div>
  )
}

export default Navbar