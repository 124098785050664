import React, { useContext } from 'react'
import {useState, useEffect} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import ActorContext from '../../context/ActorContext'
import Inputbox from '../shared/Inputbox'
import UserContext from '../../context/UserContext'
import Card from '../shared/Card'
import { LargerFont } from '../shared/Constants'
import PrimaryButton from '../shared/PrimaryButton'
import HighlightLabel from '../shared/HighlightLabel'
import SecondaryButton from '../shared/SecondaryButton'
import { FaCheck, FaTrashAlt } from 'react-icons/fa'
import IconLabel from '../shared/IconLabel'
import DataDependencyContext from '../../context/DataDependencyContext'
import SupabaseContext from '../../context/SupabaseContext'

function AddMovie() {
  const {supabaseClient} = useContext(SupabaseContext)
  const {actorList} = useContext(ActorContext)
  const {userId} = useContext(UserContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [actorMap, setActorMap] = useState({})
  const [categoryMap, setCategoryMap] = useState({})



  const [parseMovieId, setParseMovieId] = useState('')
  const [parseCategoryList, setParseCategoryList] = useState([])
  const [parseActorList, setParseActorList] = useState([])
  const [foundMovie, setFoundMovie] = useState(false)
  const [foundMovieCategoryList, setFoundMovieCategoryList] = useState([])
  const [foundMovieActorList, setFoundMovieActorList] = useState([])
  const [textInput, setTextInput] = useState('')

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isQuickDataLoaded) {
      const tempList = actorList.filter(actor => actor.type === 'Normal' || actor.type === 'Highlight')
      const actorMap = tempList.reduce((map, item) => {
        return { ...map, [item.number_id]: item}
      }, {})      
      
      setActorMap(actorMap)
      setIsLoading(false)
    }
  }, [isQuickDataLoaded])

  useEffect(() => {
    if (!isLoading) {
      const fetchData = async () => {
        const { data, error } = await (await supabaseClient())
          .from('movie_category')
          .select()
          .eq('user_id', userId)
  
        if (data !== null) {
          const categoryMap = data.reduce((map, item) => {
            return { ...map, [item.symbol]: item}
          }, {})    
          setCategoryMap(categoryMap)
        }
      }
  
      fetchData()
    }    
  }, [isLoading])

  const decodeCategorySymbol = (ca) => {
    switch (ca) {
      case ' SI ':
        return 'SI'
      case ' TI ':
        return 'TI'
      case ' MU ':
        return 'MU'
      case ' SP ':
        return 'SP'
      case ' UN ':
        return 'UN'
      case ' - X':
        return 'X'
      default:
        break
    }
    return ''
  }

  const findMovieCategoryAndActor = async (movieId) => {
    const { data, error } = await (await supabaseClient())
      .from('movie_movie')
      .select(`
        id, 
        movie_movie_r_category (
          movie_category (
            name
          )
        ),
        movie_movie_r_actor (
          movie_actor (
            name_ch,
            name_en,
            number_id
          )
        )
        `)
      .eq('id', movieId)
      .eq('user_id', userId)

    
    if (data.length > 0) {
      const catList = data[0].movie_movie_r_category.map(c => {
        return c.movie_category.name
      })
      setFoundMovieCategoryList(catList)

      const actorList = data[0].movie_movie_r_actor.map(a => {
        return a.movie_actor.number_id + " / " + a.movie_actor.name_ch + " / " + a.movie_actor.name_en
      })
      setFoundMovieActorList(actorList)
    }
  }

  const parseInput = async (e) => {
    const value = e.target.value.toUpperCase()
    setTextInput(value)

    let foundCategoryList = []

    // Check for category first
    let ccc = [' SI ', ' TI ', ' MU ', ' SP ', ' UN ', ' - X']
    ccc.forEach((item) => {
      if (value.includes(item)) {
        const c = categoryMap[decodeCategorySymbol(item)]
        foundCategoryList.push(c)
      }
    })
    setParseCategoryList(foundCategoryList)


    const tArray = value.split(' - ')

    // Check for movie and duplication
    const movieId = tArray[0]
    setParseMovieId(movieId)
    const { data, error } = await (await supabaseClient())
      .from('movie_movie')
      .select()
      .eq('id', movieId)
      .eq('user_id', userId)

    if (data.length > 0) {
      setFoundMovie(true)

      await findMovieCategoryAndActor(movieId)
    } else {
      setFoundMovie(false)
    }


    // Get actor list
    let foundActorList = []
    if (tArray.length === 3) {
      const actorArray = tArray[2].split(' ')
      actorArray.forEach((item) => {
        const a = actorMap[item]
        if (a) {
          foundActorList.push(a)
        }        
      })
    }
    setParseActorList(foundActorList)
  }

  const onAdd = async (e) => {
    e.preventDefault()

    const result = await _addMovie()
    if (result) {
      toast.success('Success: Add movie')
      _clearInput()
    } else {
      toast.error('ERROR Cannot add movie')
    }

  }

  const _addMovie = async () => {
      const newMovie = {
        'id': parseMovieId,
        'note': '',
        'user_id': userId
      }
  
      let newCategoryList = []
      parseCategoryList.forEach((item) => {
        const newCategory = {
          'id': uuidv4(),
          'movie_id': parseMovieId,
          'category_id': item.id,
          'user_id': userId
        }
        newCategoryList.push(newCategory)
      })
  
      let newActorList = []
      parseActorList.forEach((item) => {
        const newActor = {
          'id': uuidv4(),
          'movie_id': parseMovieId,
          'actor_id': item.id,
          'user_id': userId
        }
        newActorList.push(newActor)
      })
  
      const {error} = await (await supabaseClient())
        .from('movie_movie')
        .insert(newMovie)
      await (await supabaseClient())
        .from('movie_movie_r_actor')
        .insert(newActorList)
      await (await supabaseClient())
        .from('movie_movie_r_category')
        .insert(newCategoryList)
  
      return true
  }

  const onEdit = async (e) => {
    e.preventDefault()

    await _deleteMovie(parseMovieId)
    const result = await _addMovie()
    if (result) {
      toast.success('Success: Edit movie')
      _clearInput()
    } else {
      toast.error('ERROR Cannot edit movie')
    }

  }
  

  const onDelete = async (e) => {
    e.preventDefault()

    if (window.confirm(`Confirm delete movie - ${parseMovieId}?`)) { 
      const result = await _deleteMovie(parseMovieId)
      if (result) {
        toast.success('Success: Delete movie')
        _clearInput()
      } else {
        toast.error('ERROR Cannot delete movie')
      }
    }
  }

  const _deleteMovie = async (movieId) => {
    const { error } = await (await supabaseClient())
        .from('movie_movie')
        .delete()
        .eq('id', movieId)
    
    if (error) {
      return false
    }
    return true
  }

  const _clearInput = () => {
    setParseMovieId('')
    setParseCategoryList([])
    setParseActorList([])
    setFoundMovie(false)
    setFoundMovieCategoryList([])
    setFoundMovieActorList([])
    setTextInput('')
  }

  return (
    <Card>
      <div className={`${LargerFont} mb-2`}>Add / Edit Movie</div>
      
      <Inputbox
        name="textInput"
        id="textInput"
        placeholder='Search'
        value={textInput}
        onChange={parseInput}
        className='mb-4'
      />

      <div className="">
        <span className=''>Movie: </span>{parseMovieId}
      </div>

      {textInput !== '' && (foundMovie ? (
        <>
          <div className="mb-2"></div>
          <div className='flex flex-wrap'>
            <HighlightLabel theme='red' className={`mr-2 mb-2`}>Movie Found</HighlightLabel>
          {foundMovieCategoryList.map((item, index) => (
            <HighlightLabel key={index} theme='red' className={`mr-2 mb-2`}>{item}</HighlightLabel>
          ))}
          {foundMovieActorList.map((item, index) => (
            <HighlightLabel key={index} theme='red' className={`mr-2 mb-2`}>{item}</HighlightLabel>
          ))}
          </div>
        </>
      ) : (
        <>
          <div className="mb-2"></div>
          <div className="flex items-center">
            <div>
              <HighlightLabel theme='green'>New Movie</HighlightLabel>
            </div>            
          </div>          
        </>
        
      ))}
      
      <div className="mb-4"></div>

      <div className='mb-2'>Category:</div>
      <div className='flex flex-row flex-wrap'>
        {parseCategoryList.map((item, index) => (
          <HighlightLabel key={index} theme='green' className={`mr-2 mb-2`}>{item.name}</HighlightLabel>
        ))}
      </div>
      <div className="mb-2"></div>
      
      <div className='mb-2'>Actor:</div>
      <div className='flex flex-row flex-wrap'>
        {parseActorList.map((item, index) => (
          <HighlightLabel key={index} theme='green' className={`mr-2 mb-2`}>{item.number_id} / {item.name_ch} / {item.name_en}</HighlightLabel>
        ))}
      </div>
      <div className="mb-4"></div>

        <div className="flex items-center">
          {foundMovie ? (
            <>
              <PrimaryButton onClick={onEdit} className={`mr-4`}>
                <IconLabel
                  left={<FaCheck />}
                  right='Update'
                />
              </PrimaryButton>              
              <SecondaryButton onClick={onDelete}>
                <IconLabel
                  left={<FaTrashAlt />}
                  right='Delete'
                />          
              </SecondaryButton>
            </>
          ) : (
            <PrimaryButton onClick={onAdd}>
              <IconLabel
                left={<FaCheck />}
                right='Create'
              />
            </PrimaryButton>
          )}
        </div>
        
    </Card>
  )
}

export default AddMovie