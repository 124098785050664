import React from 'react'

function IconLabel({left, right, className}) {
  return (
    <>
      <div className={`flex items-center ${className}`}>
        <div className='mr-1.5'>{left}</div>
        <div>{right}</div>
      </div>
    </>
  )
}

export default IconLabel