import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Header from '../components/shared/Header'
import Card from '../components/shared/Card'
import Inputbox from '../components/shared/Inputbox'
import PrimaryButton from '../components/shared/PrimaryButton'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import Checkbox from '../components/shared/Checkbox'
import TransactionTagContext from '../context/TransactionTagContext'
import { FaCheck, FaTrashAlt } from "react-icons/fa";
import { SmallerFont } from '../components/shared/Constants'
import IconLabel from '../components/shared/IconLabel'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'

function TransactionTagsEditPage() {
  const {findTransactionTag, updateTransactionTag, deleteTransactionTag} = useContext(TransactionTagContext)
  const {role, colorTheme} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [formDetailsData, setFormDetailsData] = useState({})
  const [isLoading, setIsLoading] = useState(true)


  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'edit-transaction-tag')) {
        const transactionTag = findTransactionTag(params.id)
        setFormDetailsData({
          name: transactionTag.name,
          id: transactionTag.id,
          is_income: transactionTag.is_income,
          is_expense: transactionTag.is_expense,
          is_highlight: transactionTag.is_highlight,
          is_active: transactionTag.is_active,
          type: transactionTag.type,
          user_id: transactionTag.user_id,
          analytics_period_summary: transactionTag.analytics_period_summary,
          analytics_monthly_summary: transactionTag.analytics_monthly_summary,
          transaction_count: transactionTag.transaction_count
        })

        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/transaction-tags')
      }
    }    
  }, [isQuickDataLoaded])

  const onUpdateDetails = async (e) => {
    e.preventDefault()

    // delete transaction_count key
    delete formDetailsData.transaction_count;
    
    const result = await updateTransactionTag(formDetailsData)
    if (result) {
      toast.success('Success: Update transaction tag details')
      navigate('/transaction-tags')
    } else {
      toast.error('ERROR Cannot update transaction tag details')
    }
  }

  const onDeleteDetails = async (e) => {
    e.preventDefault()

    if (window.confirm(`Confirm delete tag: ${formDetailsData.name}? Currently there are ${formDetailsData.transaction_count} transaction(s) linked to this tag.`)) { 
      const result = await deleteTransactionTag(formDetailsData.id)
      if (result) {
        toast.success('Success: Delete transaction tag')
        navigate('/transaction-tags')
      } else {
        toast.error('ERROR Cannot delete transaction tag')
      }
    }
  }

  const onMutateDetails = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormDetailsData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  const onToggleCheckbox = (e) => {
    const newValue = formDetailsData[e.target.id];
    setFormDetailsData((prevState) => ({
      ...prevState,
      [e.target.id]: !newValue
    }))
  }

  if (isLoading) {
    return (<h1>Loading...</h1>)
  }

  return (
    <>
      <Header title="Edit Transaction Tag" />

      <Card className='mb-4'>
        <Inputbox name='name' id='name' placeholder='Name' value={formDetailsData.name} onChange={onMutateDetails}/>

        <div className="mb-2">Tag Type</div>
        <Checkbox
          label='Income Tag'
          name='is_income'
          id='is_income'
          checked={formDetailsData.is_income}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Expense Tag'
          name='is_expense'
          id='is_expense'
          checked={formDetailsData.is_expense}
          onChange={onToggleCheckbox}
        />
        <div className="mb-2"></div>
        <Checkbox
          label='Is Active'
          name='is_active'
          id='is_active'
          checked={formDetailsData.is_active}
          onChange={onToggleCheckbox}
        />

        <div className="mb-4"></div>
        <div className='mb-2'>Analytics</div>
        <Checkbox
          label='Period Summary'
          name='analytics_period_summary'
          id='analytics_period_summary'
          checked={formDetailsData.analytics_period_summary}
          onChange={onToggleCheckbox}
        />
        <div className={`${SmallerFont} ${colorTheme.secondary_text_color}`}>* Shown in Tag Amount Summary in Transaction Analytics page</div>

        <div className="mb-2"></div>
        <Checkbox
          label='Monthly Summary'
          name='analytics_monthly_summary'
          id='analytics_monthly_summary'
          checked={formDetailsData.analytics_monthly_summary}
          onChange={onToggleCheckbox}
        />
        <div className={`${SmallerFont} ${colorTheme.secondary_text_color}`}>* Shown in Tag Monthly Summary in Transaction Analytics page</div>
        
        <div className="mb-8"></div>
        <div className="flex items-center">        
          <PrimaryButton onClick={onUpdateDetails} className={`mr-2`}>
            <IconLabel
              left={<FaCheck />}
              right='Update'
            />
          </PrimaryButton>
          <SecondaryButton onClick={onDeleteDetails}>
            <IconLabel
              left={<FaTrashAlt />}
              right='Delete'
            />            
          </SecondaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/transaction-tags")}>Cancel</SecondaryButton>  
        </div>  
      </Card>
    </>
  )
}

export default TransactionTagsEditPage