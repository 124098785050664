import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserButton } from '@clerk/clerk-react'
import { LargestFont } from './Constants'
import UserContext from '../../context/UserContext'

function Header({title, children}) {
  const {colorTheme} = useContext(UserContext)

  return (
    <>
      <header className='flex mb-8'>
        <div className={`${LargestFont} ${colorTheme.primary_text_color} font-bold`}><Link to='/dashboard'>{title}</Link></div>
        <div className='ml-auto'>
          <div className='mr-4'>{children}</div>
            <UserButton 
              afterSignOutUrl='/sign-in' 
              appearance={{
              elements: {
                avatarBox: "w-12 h-12"
              }
            }} />
        </div>          
      </header>
    </>
    
  )
}

export default Header