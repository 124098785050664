import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import Header from '../components/shared/Header'
import Inputbox from '../components/shared/Inputbox'
import Radiobox from '../components/shared/Radiobox'
import PrimaryButton from '../components/shared/PrimaryButton'
import AccountContext from '../context/AccountContext'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import OrderContext from '../context/OrderContext'
import Navbar from '../components/shared/Navbar'
import ToggleButton from '../components/shared/ToggleButton'
import { LargerFont } from '../components/shared/Constants'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import IconLabel from '../components/shared/IconLabel'
import { FaCheck } from 'react-icons/fa'

function InvestmentsAddOrderPage() {
  const {userId, role, colorTheme} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {addOrder, findStock} = useContext(OrderContext)
  const {accountList, getAccountName} = useContext(AccountContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
  
  const [order, setOrder] = useState({})
  const [validAccountList, setValidAccountList] = useState([])
  const [formData, setFormData] = useState({
    trade_date: moment().format('YYYY-MM-DD'),
    buy_sell: '',
    account_id: '',
    price: '',
    position: '',
    total_amount: '',    
    strategy: '',
    note: '',

    found_stock_id: '',
    found_stock_code: '',
    found_stock_name: '',
    is_found_stock: false,
    new_stock_name: '',
    new_stock_lot_size: null
  })
 
  const [exchange, setExchange] = useState('')
  const [searchStockCode, setSearchStockCode] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const strategyList = [
    'Dividend',
    'IPO',
    'Cup and Handle',
    'Double Bottom / Double Top',
    'Pullback with Support (Trend Continuation)',
    'Pullback with Lower Timeframe Breakout (Trend Continuation)',
    'Comples Pullback (Trend Continuation)',
    'Anti (Trend Termination)',
    'Breakout (Range Breaking)',
    'Spring / Upthrust (Range Holding / Failure Test)',
    'Other'
  ]

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'create-order')) {
        setValidAccountList(accountList.filter((a) => a.is_investment))
        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/investments')
      }
    }    
  }, [isQuickDataLoaded])

  useEffect(() => {
    if (exchange && exchange !== '' && searchStockCode && searchStockCode !== '') {
      const stock = findStock(searchStockCode, exchange)
      if (stock && stock !== null) {
        setFormData((prevState) => ({
          ...prevState,
          found_stock_id: stock.id,
          found_stock_name: stock.name,
          found_stock_code: stock.code,
          is_found_stock: true,
          new_stock_name: '',
          new_stock_lot_size: null
        }))
      } else {
        setFormData((prevState) => ({
          ...prevState,
          found_stock_id: '',
          found_stock_name: '',
          found_stock_code: '',
          is_found_stock: false,
          new_stock_name: '',
          new_stock_lot_size: null
        }))
      }    
    } else {
      setFormData((prevState) => ({
        ...prevState,
        found_stock_id: '',
        found_stock_name: '',
        found_stock_code: '',
        is_found_stock: false,
        new_stock_name: '',
        new_stock_lot_size: null
      }))
    }
  }, [exchange, searchStockCode])

  const onSave = async (e) => {
    e.preventDefault()

    let commission = 0.0
    if (formData.buy_sell === 'Buy') {
      commission = formData.total_amount - formData.price * formData.position
    } else if (formData.buy_sell === 'Sell') {
      commission = formData.price * formData.position - formData.total_amount
    }

    // Save stock, order and trade
    const stockId = formData.is_found_stock ? formData.found_stock_id : uuidv4()

    const newStock = {
      id: stockId,
      code: searchStockCode,
      exchange: exchange,
      lot_size: formData.new_stock_lot_size,
      name: formData.new_stock_name,
      user_id: userId,
      type: 'Stock'
    }


    const newOrderId = uuidv4()
    const newOrder = {
      id: newOrderId,
      stock_id: stockId,
      note: '',
      user_id: userId
    }

    const newTrade = {
      id: uuidv4(),
      trade_date: formData.trade_date,
      buy_sell: formData.buy_sell,
      price: formData.price,
      position: formData.position,
      commission: commission,
      user_id: userId,
      note: formData.note,
      order_id: newOrderId,
      strategy: formData.strategy,
      account_id: formData.account_id
    }

    const result = await addOrder(formData.is_found_stock, newStock, newOrder, newTrade)
    if (result) {
      toast.success('SUCCESS: Create Order')
    } else {
      toast.error('ERROR: Cannot Create Order')
    }

    setFormData({
      buy_sell: '',
      account_id: '',
      price: '',
      position: '',
      total_amount: '',    
      strategy: '',
      note: '',

      found_stock_id: '',
      found_stock_code: '',
      found_stock_name: '',
      is_found_stock: false,
      new_stock_name: '',
      new_stock_lot_size: null
    })
    setSearchStockCode('')
    setExchange('')
  }

  const onMutate = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  const onMutateStrategy = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onMutateAccount = (accountId) => {
    setFormData((prevState) => ({
      ...prevState,
      account_id: accountId
    }))
  }

  if (isLoading) {
    return (
      <>
        <Header title='Investments' />
        <Navbar currentPage='Investments' />
        <div className="text-xl">Loading ...</div>
      </>
    )
  }

  return (
    <>
      <Header title='Add Order' />

      <Inputbox 
        name='trade_date'
        id='trade_date'
        type='date'
        placeholder='Trade Date'
        value={formData.trade_date}
        onChange={onMutate} 
      />

      <div className='mb-2'>Exchange</div>
      <div className="flex items-center mb-2">
        {['ASX', 'HKEX', 'NYSE'].map((item, index) => (
          <ToggleButton 
            id='exchange' 
            value={item} 
            label={item} 
            onClick={(e) => {
              setExchange(e.target.value)
            }} 
            state={item === exchange} 
          />
        ))}
      </div>
      <div className="mb-4"></div>    

      <Inputbox 
        name='stock_code'
        id='stock_code'
        placeholder='Stock Code'
        value={searchStockCode}
        onChange={(e) => {
          setSearchStockCode(e.target.value.toUpperCase())
        }} 
      />

      {(formData.is_found_stock && formData.is_found_stock !== '') ? (
        <>
          <div className={`border-l-4 ${colorTheme.secondary_border_color} py-2 px-4 mb-4 ${LargerFont} font-bold`}>{formData.found_stock_name} ({formData.found_stock_code}.{exchange})</div>
        </>
      ) : (
        <>
          <div className={`pt-2 pb-1 px-4 mb-4 border-l-4 ${colorTheme.secondary_border_color} ${colorTheme.secondary_bg_color}`}>
            <div className='font-bold mb-2'>Create New Stock</div>
            <Inputbox 
              name='new_stock_name'
              id='new_stock_name'
              placeholder='Stock Name'
              value={formData.new_stock_name}
              onChange={onMutate} 
            />
            <Inputbox 
              name='new_stock_lot_size'
              id='new_stock_lot_size'
              type='number'
              placeholder='Lot Size'
              value={formData.new_stock_lot_size}
              onChange={onMutate} 
            />
          </div>
        </>
      )}
      

      <div className='mb-2'>Type</div>
      <div className="flex items-center mb-2">
        {['Buy', 'Sell'].map((item, index) => (
          <ToggleButton id='buy_sell' value={item} label={item} onClick={onMutate} state={item === formData.buy_sell} />
        ))}
      </div>
      <div className="mb-4"></div>    

      <div className='mb-1'>Account</div>
      {validAccountList && validAccountList.map((item, index) => {
        return (<div key={index}>
          <Radiobox 
            label={getAccountName(item)}
            name='account'
            id={item.id} 
            checked={formData.account_id === item.id} 
            onChange={(e) => {
              onMutateAccount(item.id)
            }}
          />
        </div>)
      })}
      <div className="mb-4"></div>    

      <Inputbox 
        name='price'
        id='price'
        type='number'
        placeholder='Price'
        value={formData.price}
        onChange={onMutate} 
      />

      <Inputbox 
        name='position'
        id='position'
        type='number'
        placeholder='Position'
        value={formData.position}
        onChange={onMutate} 
      />


      <Inputbox 
        name='total_amount'
        id='total_amount'
        type='number'
        placeholder='Total Amount'
        value={formData.total_amount}
        onChange={onMutate} 
      />

      <div className='mb-1'>Strategy</div>
      {strategyList.map((item, index) => (
        <div key={index}>
          <Radiobox 
            label={item}
            name='strategy'
            id={item}
            value={item}
            checked={formData.strategy === item}
            onChange={onMutateStrategy} 
          />
        </div>
      ))}

      <div className="mb-4"></div>

      <Inputbox 
        name='note'
        id='note'
        placeholder='Note'
        value={formData.note}
        onChange={onMutate}   
      />
          
      <div className="mb-8"></div>
      <div className="flex items-center">        
        <PrimaryButton onClick={onSave} className={`mr-2`}>
          <IconLabel
            left={<FaCheck />}
            right='Create Order'
          />
        </PrimaryButton>
        <SecondaryButton className='ml-auto' onClick={e => navigate("/investments")}>Cancel</SecondaryButton>  
      </div>  
    </>
  )
}

export default InvestmentsAddOrderPage