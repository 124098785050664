import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'

function Card({children, className}) {
  const {colorTheme} = useContext(UserContext)

  return (
    <div className={`${colorTheme.card_bg_color} shadow-md rounded-lg p-4 ${className}`}>
      {children}
    </div>
  )
}

export default Card