import React from 'react'
import { FaCheck, FaXmark } from 'react-icons/fa6'
import HighlightLabel from '../shared/HighlightLabel'

function AccountTypeLabel({className, value, enabled}) {
  let theme = enabled ? 'primary' : 'gray'
  return (
    <HighlightLabel className={`flex items-center mr-2 mb-2 ${className}`} theme={theme}>
      <div className="mr-1">{enabled ? <FaCheck /> : <FaXmark />}</div>
      <div>{value}</div>
    </HighlightLabel>
  )
}

export default AccountTypeLabel