import { Outlet, useNavigate } from 'react-router-dom'
import { ClerkProvider } from '@clerk/clerk-react'
import { UserProvider } from '../context/UserContext'
import { TestProvider } from '../context/TestContext'
import { CurrencyProvider } from '../context/CurrencyContext'
import { AccountProvider } from '../context/AccountContext'
import { TransactionTagProvider } from '../context/TransactionTagContext'
import { TransactionProvider } from '../context/TransactionContext'
import { OrderProvider } from '../context/OrderContext'
import { ActorProvider } from '../context/ActorContext'
import { RoleProvider } from '../context/RoleContext'
import { SupabaseProvider } from '../context/SupabaseContext'
import { DataDependencyProvider } from '../context/DataDependencyContext'
 
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY
 
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

function RootLayout() {
  const navigate = useNavigate();

  return (
    <ClerkProvider 
      navigate={navigate}
      publishableKey={PUBLISHABLE_KEY}
      signInFallbackRedirectUrl='/dashboard'
      signUpFallbackRedirectUrl='/dashboard'
      appearance={{
        variables: {
          colorPrimary: "black",
          fontSize: "1rem"
        }
      }}
      >
        <SupabaseProvider>
        <RoleProvider>
        <UserProvider>
        <TestProvider>
        <CurrencyProvider>
        <AccountProvider>
        <TransactionTagProvider>
        <TransactionProvider>
        <OrderProvider>
        <ActorProvider>
        <DataDependencyProvider>
          <Outlet />
        </DataDependencyProvider>
        </ActorProvider>
        </OrderProvider>
        </TransactionProvider>
        </TransactionTagProvider>
        </AccountProvider>
        </CurrencyProvider>
        </TestProvider>
        </UserProvider>
        </RoleProvider>
        </SupabaseProvider>
        
    </ClerkProvider>
  )
}

export default RootLayout