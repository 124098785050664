import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'

function PercentageLabel({
  value, 
  maximumFractionDigits = 2, 
  theme = 'dynamic',
  className, 
  children}) {

  const {colorTheme, getValueColor} = useContext(UserContext)

  // primary theme
  let color = colorTheme.primary_text_color
  if (theme === 'dynamic') {
    color = getValueColor(value)
  } else if (theme === 'gray') {
    color = colorTheme.secondary_text_color
  } else if (theme === 'default') {
    color = colorTheme.default_text_color
  }
  
  return (
    <>
      <div className={`${className} ${color}`}> 
        {children}
        {isNaN(value) ? '0%' : new Intl.NumberFormat('zh', 
          { style: 'percent', 
            maximumFractionDigits: maximumFractionDigits }
          ).format(value)
        }
      </div>
    </>
  )
}

export default PercentageLabel