import React, { useContext } from 'react'
import ColorNumberText from './ColorNumberText'
import Card from '../shared/Card'
import UserContext from '../../context/UserContext'
import { LargerFont } from '../shared/Constants'
import PercentageLabel from '../shared/PercentageLabel'

function InvestmentExchangeSummaryWidget({exchange, currency, orderList}) {
  const {getExchangeColor, colorTheme} = useContext(UserContext)

  const calculateSummary = (localOrderList) => {
    let winYieldSum = 0.0
    let lossYieldSum = 0.0

    let totalWin = 0
    let totalLoss = 0

    let totalMarketValue = 0.0

    let totalRealPnL = 0.0
    let totalDividend = 0.0
    let totalUnRealPnL = 0.0
    
    localOrderList.forEach((order) => {

      if (order.analytics.realized_pnl > 0.0) {
        totalWin += 1
        winYieldSum += order.analytics.realized_yield
      }

      if (order.analytics.realized_pnl < 0.0) {
        totalLoss += 1
        lossYieldSum += order.analytics.realized_yield
      }

      totalRealPnL += order.analytics.realized_pnl

      totalUnRealPnL += order.analytics.unrealized_pnl
      totalMarketValue += order.analytics.market_value

      totalDividend += order.analytics.total_dividend - order.analytics.total_dividend_commission
    })
    

    return {
      'average_win_yield': winYieldSum / totalWin,
      'average_loss_yield': lossYieldSum / totalLoss,
      'number_of_win': totalWin,
      'number_of_loss': totalLoss,
      'win_rate': totalWin / (totalWin + totalLoss),

      'market_value': totalMarketValue,
      'realized_pnl': totalRealPnL,
      'dividend_gain': totalDividend,
      'realized_pnl_with_dividend': totalRealPnL + totalDividend,
      'unrealized_pnl': totalUnRealPnL,
      'net_pnl': totalRealPnL + totalDividend + totalUnRealPnL
    }
  }

  const summary = calculateSummary(orderList)

  const createRowConponent = ({className, left, right}) => {
    return (
      <>
        <div className={`flex items-center ${className}`}>
          <div>{left}</div>
          <div className="ml-auto">{right}</div>
        </div>
      </>
    )
  }

  if (!orderList || orderList.length === 0) {
    return (<></>)
  }
 
  return (
    <Card>
      <div className={`${LargerFont} mb-2 ${getExchangeColor(exchange, 'text')}`}>{exchange}</div>
      <div className={`grid grid-cols-1 divide-y divide-dashed ${colorTheme.secondary_divide_color} gap-1`}>
        {createRowConponent({
          left:'Average Yield', 
          right: 
          <div className='flex items-center'>
            <PercentageLabel
              value={summary.average_win_yield}
              maximumFractionDigits={0}
              theme='default'
              className={`mr-1`}
            />
            <div className="mr-1">/</div>
            <PercentageLabel
              value={summary.average_loss_yield}
              maximumFractionDigits={0}
              theme='default'
            />
          </div>
        })}
        {createRowConponent({
          left:'Win/Loss Rate', 
          right:
            <div className='flex items-center'>
              <div className='mr-2'>{summary.number_of_win} / {summary.number_of_loss}</div>
              <div>(</div>
              <PercentageLabel
                value={summary.win_rate}
                maximumFractionDigits={0}
                theme='default'
              />
              <div>)</div>
            </div>
        })}
        {createRowConponent({
          left:'Market Value', 
          right: <ColorNumberText value={summary.market_value} dp={0} currency={currency} isColoured={false} />
        })}

        {createRowConponent({
          left:'Realized PnL', 
          right: <ColorNumberText value={summary.realized_pnl} dp={0} currency={currency} isColoured={true} />
        })}
        {createRowConponent({
          left:'Dividend', 
          right: <ColorNumberText value={summary.dividend_gain} dp={0} currency={currency} isColoured={false} />
        })}
        {createRowConponent({
          className:'font-bold',
          left:'Total Realized PnL', 
          right: <ColorNumberText value={summary.realized_pnl_with_dividend} dp={0} currency={currency} isColoured={true} />
        })}
        {createRowConponent({
          left:'Unrealized PnL', 
          right: <ColorNumberText value={summary.unrealized_pnl} dp={0} currency={currency} isColoured={true} />
        })}
        {createRowConponent({
          className:'font-bold',
          left:'Net PnL', 
          right: <ColorNumberText value={summary.net_pnl} dp={0} currency={currency} isColoured={true} />
        })}
      </div>      
    </Card>
  )
}

export default InvestmentExchangeSummaryWidget