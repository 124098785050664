import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { evaluate } from 'mathjs'
import Header from '../components/shared/Header'
import Inputbox from '../components/shared/Inputbox'
import PrimaryButton from '../components/shared/PrimaryButton'
import AccountContext from '../context/AccountContext'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import Checkbox from '../components/shared/Checkbox'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import IconLabel from '../components/shared/IconLabel'
import { FaCheck } from 'react-icons/fa'

function AccountsAddPage() {
  const {addAccount} = useContext(AccountContext)
  const {role, colorTheme} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [formData, setFormData] = useState({
    name: '',
    is_income: false,
    is_expense: false,
    is_cash: false,
    is_investment: false,
    is_active: false,
    institution: '',
    currency: '',
    country: '',
    note: ''
  })

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'create-account')) {
        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/accounts')
      }
    }
  }, [isQuickDataLoaded])

  const onMutate = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  const onSave = async (e) => {
    e.preventDefault()

    let newAccountId = uuidv4();
    let submitFormData = {
      ...formData,
      id: newAccountId,
      total_amount: Math.round(evaluate(formData.total_amount.toString()) * 100) / 100,
      pending_amount: Math.round(evaluate(formData.pending_amount.toString()) * 100) / 100
    }

    const result = await addAccount(submitFormData);

    if (result) {
      toast.success('Success: Create new account')
    } else {
      toast.error('ERROR: Cannot create account')
    }
    
    navigate('/accounts')
  }


  const onToggleCheckbox = (e) => {
    const currentValue = formData[e.target.id];
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: !currentValue
    }))
  }

  if (isLoading) {
    return (<h1>Loading...</h1>)
  }

  return (
    <>
      <Header title="Create Account" />
        <Inputbox name='name' id='name' placeholder='Name' value={formData.name} onChange={onMutate}/>
        <Inputbox name='institution' id='institution' placeholder='Institution' value={formData.institution} onChange={onMutate}/>
        <Inputbox name='currency' id='currency' placeholder='Currency' value={formData.currency} onChange={onMutate} annotation={`Only supported: HKD, USD, AUD, GBP, EUR`}/>
        <Inputbox name='country' id='country' placeholder='Country' value={formData.country} onChange={onMutate} annotation={`Only Supported: Hong Kong, Australia, United Kingdom`}/>

        <Inputbox 
          placeholder='Initial Total Amount' 
          type='text' 
          id='total_amount'
          name='total_amount' 
          value={formData.total_amount}
          onChange={onMutate}
          annotation="Accepts Math Expression, e.g. 1+2-3"
        />
        <Inputbox 
          placeholder='Initial Pending Amount' 
          type='text' 
          id='pending_amount'
          name='pending_amount' 
          value={formData.pending_amount}
          onChange={onMutate}
          annotation="Accepts Math Expression, e.g. 1+2-3"
        />

        <div className="mb-2">Account Type</div>
        <Checkbox
          label='Is Active Account'
          name='is_active'
          id='is_active'
          checked={formData.is_active}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Cash'
          name='is_cash'
          id='is_cash'
          checked={formData.is_cash}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Investment'
          name='is_investment'
          id='is_investment'
          checked={formData.is_investment}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Income'
          name='is_income'
          id='is_income'
          checked={formData.is_income}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Expense'
          name='is_expense'
          id='is_expense'
          checked={formData.is_expense}
          onChange={onToggleCheckbox}
        />
        

        <div className="mb-4"></div>
        <div className="mb-2">Note</div>
        <textarea name="note" id="note" rows="20" value={formData.note} onChange={onMutate} className={`${colorTheme.default_bg_color} border-2 ${colorTheme.secondary_border_color} focus:outline-none focus:${colorTheme.primary_border_color} rounded px-3 py-2 w-full`}></textarea>
        
        <div className="mb-4"></div>
        <div className="flex items-center">        
          <PrimaryButton onClick={onSave}>
            <IconLabel
              left={<FaCheck />}
              right='Create'
            />
          </PrimaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/accounts")}>Cancel</SecondaryButton>  
        </div>  
    </>
  )
}

export default AccountsAddPage