import React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import UserContext from './UserContext'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import SupabaseContext from './SupabaseContext'


const AccountContext = createContext()

export const AccountProvider = ({children}) => {
  const {userId} = useContext(UserContext);
  const {supabaseClient} = useContext(SupabaseContext)
  const [accountList, setAccountList] = useState([])
  const [isAccountLoaded, setIsAccountLoaded] = useState(false)

  const fetchAccount = async () => {
    const { data, error } = await (await supabaseClient())
      .from('account_v2')
      .select(`
      id,
      name,
      is_income,
      is_expense,
      is_cash,
      is_investment,
      is_active,
      institution,
      currency,
      country,
      note,
      accountSnapshot_v2 (
        id,
        snapshot_date,
        total_amount,
        pending_amount
      )
      `)
      .eq('user_id', userId)

    data.sort(_sort)
    setAccountList(data)
    setIsAccountLoaded(true)  
  }

  useEffect(() => {
    if (userId) {
      fetchAccount()    
    }    
  }, [userId])

  const _loadSingleFromDB = async (accountId) => {
    const { data, error } = await (await supabaseClient())
      .from('account_v2')
      .select(`
        id,
        name,
        is_income,
        is_expense,
        is_cash,
        is_investment,
        is_active,
        institution,
        currency,
        country,
        note,
        accountSnapshot_v2 (
          id,
          snapshot_date,
          total_amount,
          pending_amount
        )
      `)
      .eq('user_id', userId)
      .eq('id', accountId)
    
    return data[0]
  }

  const findAccount = (id) => {
    const result = accountList.filter((a) => a.id === id)
    if (result && result.length > 0) {
      return result[0]
    }
    return null
  }

  const _sort = (a, b) => {
    if (a.institution !== b.institution) {
      return a.institution.localeCompare(b.institution)
    }
    if (a.country !== b.country) {
      return a.country.localeCompare(b.country)
    }
    if (a.name !== b.name) {
      return a.name.localeCompare(b.name)
    }
    if (a.currency !== b.currency) {
      return a.currency.localeCompare(b.currency)
    }
    return 0
    
  }

  const filterAccount = (activeOnly) => {
    let tempList = [...accountList]
    if (activeOnly) {
      tempList = tempList.filter((t) => t.is_active)
    }
    tempList.sort(_sort)
    return tempList
  }

  const addAccount = async (formDetails) => {
    let accountId = formDetails.id;

    const newAccount = {
      id: accountId,
      name: formDetails.name,
      is_active: formDetails.is_active,
      is_cash: formDetails.is_cash,
      is_investment: formDetails.is_investment,
      is_income: formDetails.is_income,
      is_expense: formDetails.is_expense,
      institution: formDetails.institution,
      currency: formDetails.currency,
      user_id: userId,
      country: formDetails.country,
      note: formDetails.note
    }

    const {error} = await (await supabaseClient())
      .from('account_v2')
      .insert(newAccount)

    // Populate a zero initial snapshot
    let today = moment().format('YYYY-MM-DD')
    let newSnapshot = {
      id: uuidv4(),
      account_id: accountId,
      snapshot_date: today,
      total_amount: formDetails.total_amount,
      pending_amount: formDetails.pending_amount,
      user_id: userId
    }

    const {error2} = await (await supabaseClient())
      .from('accountSnapshot_v2')
      .insert(newSnapshot)

    const newT = await _loadSingleFromDB(accountId)

    let tempList = [...accountList.filter((a) => a.id !== accountId), newT]
    tempList.sort(_sort)        
    setAccountList(tempList)

    if (error || error2) {
      return false
    } else {
      return true
    }
  }

  const updateAccountDetails = async (id, formDetails) => {
    const {error} = await (await supabaseClient())
      .from('account_v2')
      .update(formDetails)
      .eq('id', id)

    const newT = await _loadSingleFromDB(id)

    let tempList = [...accountList.filter((a) => a.id !== id), newT]
    tempList.sort(_sort)        
    setAccountList(tempList)

    if (error) {
      return false
    } else {
      return true
    }
  }

  const updateAccountSnapshot = async (operation, newSnapshot)  => {
    if (operation === "Add") {
      const { error } = await (await supabaseClient())
        .from('accountSnapshot_v2')
        .insert(newSnapshot)
      
      if (error) {
        return false
      }
    } else if (operation === "Edit") {
      const { error } = await (await supabaseClient())
        .from('accountSnapshot_v2')
        .update(newSnapshot)
        .eq('id', newSnapshot.id)
      
      if (error) {
        return false
      }
    }

    const newT = await _loadSingleFromDB(newSnapshot.account_id)

    let tempList = accountList.filter((a) => a.id !== newSnapshot.account_id)
    tempList = [...tempList, newT]
    tempList.sort(_sort)        
    setAccountList(tempList)

    return true
  }

  const getLatestSnapshot = (accountId, asOfDate) => {    
    let filteredList = findAccount(accountId).accountSnapshot_v2.filter((s) => s.snapshot_date.localeCompare(asOfDate) <= 0)
    
    if (filteredList.length > 0) {
      // Use reduce to return the max snapshot date
      const maxSnapshot = filteredList.reduce(function(a, b) {
        return a.snapshot_date.localeCompare(b.snapshot_date) < 0 ? b : a
      })
      return maxSnapshot
    }
    return null
  }

  const getAccountName = (account) => {
    return account.institution + " - " + account.name + " (" + account.currency + ")"
  }

  return <AccountContext.Provider 
    value={{
      isAccountLoaded,
      accountList,
      findAccount,
      filterAccount,
      addAccount,
      updateAccountDetails,
      updateAccountSnapshot,
      getLatestSnapshot,
      getAccountName
    }}>
      {children}
    </AccountContext.Provider>
}

export default AccountContext