import { SignedIn, SignedOut } from '@clerk/clerk-react'
import React from 'react'
import { Link } from 'react-router-dom'

function IndexPage() {
  return (
    <>
      <div>IndexPage</div>
      <SignedIn>
        <div>Signed In</div>
        <Link to="/dashboard">Dashboard</Link>
      </SignedIn>
      <SignedOut>
        <Link to="/sign-in">Sign In</Link>
      </SignedOut>
    </>
    
  )
}

export default IndexPage