import React from 'react'
import { useContext } from 'react'
import UserContext from '../context/UserContext';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import DashboardTotalAssetWidget from '../components/dashboard/DashboardTotalAssetWidget';
import { LargerFont } from '../components/shared/Constants';
import DashboardTransactionWidget from '../components/dashboard/DashboardTransactionWidget';
import DashboardInvestmentWidget from '../components/dashboard/DashboardInvestmentWidget';


function DashboardPage() {
  const {userName} = useContext(UserContext);

  return (
    <>
      <Header title='Dashboard' />
      <Navbar currentPage='Dashboard' />
      <div className="mb-8"></div>
      <div className={`${LargerFont} mb-8`}>Welcome {userName}</div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <DashboardTotalAssetWidget />
        <DashboardTransactionWidget />
        <DashboardInvestmentWidget />
      </div>
    </>
    
  )
}

export default DashboardPage