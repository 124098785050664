import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { evaluate } from 'mathjs'
import Header from '../components/shared/Header'
import Checkbox from '../components/shared/Checkbox'
import Inputbox from '../components/shared/Inputbox'
import Radiobox from '../components/shared/Radiobox'
import PrimaryButton from '../components/shared/PrimaryButton'
import TransactionContext from '../context/TransactionContext'
import TransactionTagContext from '../context/TransactionTagContext'
import AccountContext from '../context/AccountContext'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import { SmallerFont } from '../components/shared/Constants'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import { FaCheck } from 'react-icons/fa'
import IconLabel from '../components/shared/IconLabel'

function TransactionsAddPage() {
  const {role, userId, colorTheme} = useContext(UserContext);
  const {addTransaction} = useContext(TransactionContext)
  const {accountList, getAccountName} = useContext(AccountContext)
  const {transactionTagList} = useContext(TransactionTagContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [isLoading, setIsLoading] = useState(true)

  const [localTagList, setLocalTagList] = useState([])
  const [localAccountList, setLocalAccountList] = useState([])

  const [formAmount, setFormAmount] = useState('')
  const [formDate, setFormDate] = useState(moment().format('YYYY-MM-DD'))
  const [formType, setFormType] = useState('Expense')
  const [formIsSpecial, setFormIsSpecial] = useState(false)
  const [formIsPrepaid, setFormIsPrepaid] = useState(false)
  const [formAccountId, setFormAccountId] = useState('')
  const [formTagList, setFormTagList] = useState([])
  const [formNote, setFormNote] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'create-transaction')) {
        if (formType === 'Income') {
          setLocalAccountList(accountList.filter((a) => a.is_income))
        } else if (formType === 'Expense') {
          setLocalAccountList(accountList.filter((a) => a.is_expense))
        }
        setFormAccountId('')
        setFormTagList([])

        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/transactions')
      }
    }
  }, [isQuickDataLoaded, accountList, formType])

  useEffect(() => {
    if (formType === 'Income') {
      setLocalTagList(transactionTagList.filter((a) => a.is_income && a.is_active))
    } else if (formType === 'Expense') {
      setLocalTagList(transactionTagList.filter((a) => a.is_expense && a.is_active))
    }   
    setFormAccountId('')
    setFormTagList([])   
  }, [isQuickDataLoaded, transactionTagList, formType])

  const updateFormTagList = (tagId) => {
    let tempList = [...formTagList]
    if (tempList.includes(tagId)) {
      // Remove
      tempList = tempList.filter(item => item !== tagId)
    } else {
      tempList.push(tagId)
    }

    setFormTagList(tempList)
  }

  const onSave = async (e) => {
    e.preventDefault()

    if (formAccountId === '')
    {
      toast.error('ERROR Account is empty')
      return
    }
    if (formAmount === '')
    {
      toast.error('ERROR Transaction amount is empty')
      return
    }
    if (formIsSpecial && formIsPrepaid) {
      toast.error('ERROR A transaction cannot be special and prepaid')
      return
    }

    // create new
    const newTransactionId = uuidv4()
    const newTransaction = {
      id: newTransactionId,
      type: formType, 
      transaction_date: formDate,
      note: formNote,
      amount: Math.round(evaluate(formAmount.toString()) * 100) / 100,
      account_id: formAccountId,
      user_id: userId,
      is_special: formIsSpecial,
      is_prepaid: formIsPrepaid,
    }

    let newTagList = []
    if (formTagList && formTagList.length > 0) {
      formTagList.forEach((item, index) => {
        const newTag = {
          id: uuidv4(),
          transaction_id: newTransactionId,
          transactionTag_id: item,
          user_id: userId
        }

        newTagList.push(newTag)
      })
    } else {
      // Error?
    }

    const result = await addTransaction(newTransaction, newTagList)
    if (result) {
      toast.success('Success: Create new transaction')
      
      setFormAmount('')
      setFormIsPrepaid(false)
      setFormIsSpecial(false)
      setFormType('Expense')
      setFormAccountId('')
      setFormTagList([])
      setFormNote('')

    } else {
      toast.error('ERROR Cannot create transaction')
    }
  }

  if (isLoading) {
    return (<h1>Loading...</h1>)
  }

  return (
    <>
      <Header title='Create Transaction' />
      <Inputbox 
        name='amount'
        id='amount'
        placeholder='Amount'
        value={formAmount}
        onChange={(e) => {setFormAmount(e.target.value)}} 
        annotation="Accepts Math Expression, e.g. 1+2-3"      
      />

      <Inputbox 
        name='transaction_date'
        id='transaction_date'
        type='date'
        placeholder='Transaction Date'
        value={formDate}
        onChange={(e) => {setFormDate(e.target.value)}}   
      />

      <div className="mb-4"></div>

      <div className='mb-1'>Type</div>
      <div className="flex items-center mb-2">
        <div className='mr-4'>
          <Radiobox 
            label='Income'
            name='formTypeIncome'
            id='formTypeIncome'
            checked={formType === 'Income'} 
            onChange={(e) => {
              setFormType('Income')
            }} 
          />
        </div>
        <div>
          <Radiobox 
            label='Expense'
            name='formTypeExpense'
            id='formTypeExpense'
            checked={formType === 'Expense'} 
            onChange={(e) => {
              setFormType('Expense')
            }} 
          />
        </div>
        
      </div>

      <Checkbox
        label='Special (One-off transaction)'
        name='is_special'
        id='is_special'
        checked={formIsSpecial}
        onChange={(e) => {
          const newValue = !formIsSpecial
          setFormIsSpecial(newValue)
        }}   
      />
      <Checkbox
        label='Prepaid (Not calculated in total)'
        name='is_prepaid'
        id='is_prepaid'
        checked={formIsPrepaid}
        onChange={(e) => {
          const newValue = !formIsPrepaid
          setFormIsPrepaid(newValue)
        }}   
      />
      <div className={`${SmallerFont} ${colorTheme.red_text_color}`}>NOTE: A transaction can only be EITHER special or prepaid</div>

      <div className="mb-4"></div>

      <div className='mb-1'>Account</div>
        {localAccountList && localAccountList.map((item, index) => {
          return (<div key={index}>
            <Radiobox 
              label={getAccountName(item)}
              name='formAccount'
              id={item.id} 
              checked={formAccountId === item.id} 
              onChange={(e) => {
                setFormAccountId(item.id)
              }}
            />
          </div>)
        })}
        <div className="mb-4"></div>    

        <div className='mb-1'>Tag</div>
        <div className="flex items-center flex-wrap mb-4">
          {localTagList && localTagList.map((item, index) => {
            return (<div key={index} className='mr-4 mb-1'>
              <Checkbox 
                label={item.name}
                name='formTagList'
                id={item.id}
                checked={formTagList.includes(item.id)}
                onChange={(e) => {
                  updateFormTagList(item.id)
                }} 
              />
            </div>)
          })}
        </div>    

        <Inputbox 
          name='note'
          id='note'
          placeholder='Note'
          value={formNote}
          onChange={(e) => {setFormNote(e.target.value)}}   
        />

        
        <div className="mb-8"></div>
        <div className="flex items-center">        
          <PrimaryButton onClick={onSave}>
            <IconLabel
              left={<FaCheck />}
              right='Create'
            />
          </PrimaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/transactions")}>Cancel</SecondaryButton>  
        </div>  
    </>
  )
}

export default TransactionsAddPage