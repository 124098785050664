import React from 'react'
import { createContext } from 'react'
import { useAuth } from "@clerk/clerk-react";
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

const SupabaseContext = createContext()

export const SupabaseProvider = ({children}) => {
  const { getToken } = useAuth();

  const supabaseClient = async () => {
    const token = await getToken({
      template: "supabase",
    });

    const supabaseClient = createClient(supabaseUrl, supabaseAnonKey, {
      global: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    })

    return supabaseClient
  }

  return <SupabaseContext.Provider 
    value={{
      supabaseClient
    }}>
      {children}
    </SupabaseContext.Provider>
}

export default SupabaseContext