import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import TransactionTagContext from '../context/TransactionTagContext';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import HighlightLabel from '../components/shared/HighlightLabel';
import { FaPen } from 'react-icons/fa6';
import PrimaryButton from '../components/shared/PrimaryButton';
import IconLink from '../components/shared/IconLink';
import RolePermissionContainer from '../components/shared/RolePermissionContainer';
import DataDependencyContext from '../context/DataDependencyContext';
import IconLabel from '../components/shared/IconLabel';
import { FaPlus } from 'react-icons/fa';

function TransactionTagsPage() {
  const navigate = useNavigate();

  const {colorTheme} = useContext(UserContext);
  const {transactionTagList} = useContext(TransactionTagContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
  
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isQuickDataLoaded && transactionTagList && transactionTagList.length > 0) {
      setIsLoading(false)      
    }    
  }, [isQuickDataLoaded, transactionTagList])

  if (isLoading) {
    return (<div>Loading...</div>)
  }

  return (
    <>
      <Header title='Transaction Tags' />
      <Navbar currentPage='TransactionTags' />
      <RolePermissionContainer permission={'create-transaction-tag'}>
        <PrimaryButton onClick={e => navigate("/transaction-tags/add")}>
          <IconLabel
            left={<FaPlus />}
            right='Transaction Tag'
          />
        </PrimaryButton>      
      </RolePermissionContainer>
      <div className="mb-8"></div>

      <div className={`border ${colorTheme.secondary_border_color} rounded`}>
      {transactionTagList.map((item, index) => (
        <div key={index} className={`group py-2 pr-4 pl-2 odd:${colorTheme.secondary_bg_color}`}>
          <div className="flex flex-col md:items-center md:flex-row">
            <div className={`mr-4 pl-2 border-l-4 border-transparent group-hover:${colorTheme.primary_border_color}`}>{item.name}</div>

            <div className="flex flex-wrap grow items-center mt-2 md:mt-0 pl-3 md:pl-0">
              <div>{item.is_active ? 
                (<></>): 
                (<HighlightLabel 
                  className={`mr-2 mb-2 md:mb-0`} 
                  theme='gray'>
                    Inactive
                  </HighlightLabel>
                )}</div>
              <div>{item.is_income ? 
                (<HighlightLabel 
                  className={`mr-2 mb-2 md:mb-0`} 
                  theme='green'>
                    Income
                  </HighlightLabel>
                ) : (<></>)}</div>
              <div>{item.is_expense ? 
                (<HighlightLabel 
                  className={`mr-2 mb-2 md:mb-0`} 
                  theme='red'>
                    Expense
                  </HighlightLabel>
                ) : (<></>)}</div>
                <div>{item.analytics_period_summary ? 
                (<HighlightLabel 
                  className={`mr-2 mb-2 md:mb-0`} 
                  theme='blue'>
                    Period Summary
                  </HighlightLabel>
                ) : (<></>)}</div>
                <div>{item.analytics_monthly_summary ? 
                (<HighlightLabel 
                  className={`mr-2 mb-2 md:mb-0`} 
                  theme='blue'>
                    Monthly Summary
                  </HighlightLabel>
                ) : (<></>)}</div>

              <div className="flex items-center ml-auto">
                <div>
                  <HighlightLabel 
                    className={``} 
                    theme='gray'>
                      Count: {item.transaction_count}
                  </HighlightLabel>
                </div>
                <RolePermissionContainer permission={'edit-transaction-tag'}>
                  <IconLink
                    url={`/transaction-tags/edit/${item.id}`}
                    left={<FaPen />}
                    right='Edit'
                    className={'ml-4 text-sm'}
                  />
                </RolePermissionContainer>
              </div>
            </div>
            
          </div>
        </div>
      ))}

      </div>
    </>
    
  )
}

export default TransactionTagsPage