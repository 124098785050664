import React, { useContext } from 'react'
import { createContext, useState, useEffect } from 'react'
import SupabaseContext from './SupabaseContext'
import AccountContext from './AccountContext'
import ActorContext from './ActorContext'
import CurrencyContext from './CurrencyContext'
import OrderContext from './OrderContext'
import RoleContext from './RoleContext'
import TransactionContext from './TransactionContext'
import TransactionTagContext from './TransactionTagContext'
import UserContext from './UserContext'

const DataDependencyContext = createContext()

export const DataDependencyProvider = ({children}) => {
  const {isAccountLoaded} = useContext(AccountContext)
  const {isCurrencyLoaded} = useContext(CurrencyContext)
  const {isOrderLoaded, isStockLoaded} = useContext(OrderContext)
  const {isRoleLoaded} = useContext(RoleContext)
  const {isTransactionLoaded} = useContext(TransactionContext)
  const {isTransactionTagLoaded} = useContext(TransactionTagContext)
  const {isUserLoaded} = useContext(UserContext)
  const {isActorLoaded} = useContext(ActorContext)

  const [isQuickDataLoaded, setIsQuickDataLoaded] = useState(false)
  const [isFullDataLoaded, setIsFullDataLoaded] = useState(false)


  useEffect(() => {
    if (isAccountLoaded && isCurrencyLoaded && isOrderLoaded && isStockLoaded && isRoleLoaded && isTransactionTagLoaded && isUserLoaded && isActorLoaded) {
      setIsQuickDataLoaded(true)    
    }    
  }, [isAccountLoaded, isCurrencyLoaded, isOrderLoaded, isStockLoaded, isRoleLoaded, isTransactionTagLoaded, isUserLoaded, isActorLoaded])

  useEffect(() => {
    if (isQuickDataLoaded && isTransactionLoaded) {
      setIsFullDataLoaded(true)    
    }    
  }, [isQuickDataLoaded, isTransactionLoaded])

  return <DataDependencyContext.Provider 
    value={{
      isQuickDataLoaded,
      isFullDataLoaded,
    }}>
      {children}
    </DataDependencyContext.Provider>
}

export default DataDependencyContext