import React, { useContext, useEffect, useState } from 'react'
import SimpleTable from '../shared/SimpleTable'
import UserContext from '../../context/UserContext';
import CurrencyContext from '../../context/CurrencyContext';
import moment from 'moment'
import Card from '../shared/Card';
import CurrencyLabel from '../shared/CurrencyLabel';
import { FaShareFromSquare } from 'react-icons/fa6';
import WidgetHeader from '../shared/WidgetHeader';
import { LargestFont, SmallerFont } from '../shared/Constants';
import IconLink from '../shared/IconLink';
import DataDependencyContext from '../../context/DataDependencyContext';

function TransactionTotalWidget({transactionViewModelList, startDate, endDate}) {
  const {transactionBaseCurrency} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [total, setTotal] = useState(0.0)
  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isQuickDataLoaded) {
      let tempSupportedCurrencyList = []
      let tempIncomeList = []
      let tempExpenseList = []
      let tempSpecialList = []
      let tempPrepaidList = []

      let tempTotal = 0.0

      let today = moment().format("YYYY-MM-DD");

      // Get all country from accounts and remove duplicate
      tempSupportedCurrencyList = [...new Set(transactionViewModelList.map((a) => a.currency))]
      tempSupportedCurrencyList.sort()
      

      tempSupportedCurrencyList.forEach((currency) => {
        let currencyIncome = calculateIncome(currency);
        tempIncomeList.push(currencyIncome)

        tempTotal += calculateCurrency(transactionBaseCurrency, currency, today, currencyIncome);

        let currencyExpense = calculateExpense(currency);
        tempExpenseList.push(currencyExpense)

        tempTotal += calculateCurrency(transactionBaseCurrency, currency, today, currencyExpense);
        
        let currencySpecial = calculateSpecial(currency);
        tempSpecialList.push(currencySpecial)

        tempTotal += calculateCurrency(transactionBaseCurrency, currency, today, currencySpecial);

        let currencyPrepaid = calculatePrepaid(currency);
        tempPrepaidList.push(currencyPrepaid)

        // Not include prepaid in total
      });

      setTotal(tempTotal)
      
      setTableHeaderList([])
      let tempTableData = []
      tempSupportedCurrencyList.forEach((currency, index) => {
        // income row
        if (tempIncomeList[index] !== 0.0) {
          let tempRow = [
            `${currency} Income`, 
            <CurrencyLabel
              currency={currency}
              value={tempIncomeList[index]}
              maximumFractionDigits={2}
            />
          ]
  
          tempTableData.push(tempRow)
        }

        // expense row
        if (tempExpenseList[index] !== 0.0) {
          let tempRow = [
            `${currency} Expense`, 
            <CurrencyLabel
              currency={currency}
              value={tempExpenseList[index]}
              maximumFractionDigits={2}
            />
          ]
  
          tempTableData.push(tempRow)
        }

        // special row
        if (tempSpecialList[index] !== 0.0) {
          let tempRow = [
            `${currency} Special`, 
            <CurrencyLabel
              currency={currency}
              value={tempSpecialList[index]}
              maximumFractionDigits={2}
            />
          ]

          tempTableData.push(tempRow)
        }

        // prepaid row
        if (tempPrepaidList[index] !== 0.0) {
          let tempRow = [
            `${currency} Prepaid`, 
            <CurrencyLabel
              currency={currency}
              value={tempPrepaidList[index]}
              maximumFractionDigits={2}
              theme='gray'
            />
          ]

          tempTableData.push(tempRow)
        }
      })
      setTableDataList(tempTableData)

      setIsLoading(false)
    }    
  }, [isQuickDataLoaded, transactionViewModelList])

  const calculateIncome = (currency) => {
    let tempTotal = 0;
    transactionViewModelList.forEach((vm) => {
      if (vm.currency === currency && vm.type === 'Income' && !vm.is_special && !vm.is_prepaid) {
        tempTotal += vm.amount
      }
    })
    return tempTotal;
  }

  const calculateExpense = (currency) => {
    let tempTotal = 0;
    transactionViewModelList.forEach((vm) => {
      if (vm.currency === currency && vm.type === 'Expense' && !vm.is_special && !vm.is_prepaid) {
        tempTotal -= vm.amount
      }
    })
    return tempTotal;
  }

  const calculateSpecial = (currency) => {
    let tempTotal = 0;
    transactionViewModelList.forEach((vm) => {
      if (vm.currency === currency && vm.is_special) {
        tempTotal += vm.type === 'Income' ? vm.amount : -1 * vm.amount
      }
    })
    
    return tempTotal;
  }

  const calculatePrepaid = (currency) => {
    let tempTotal = 0;
    transactionViewModelList.forEach((vm) => {
      if (vm.currency === currency && vm.is_prepaid) {
        tempTotal += vm.type === 'Income' ? vm.amount : -1 * vm.amount
      }
    })
    
    return tempTotal;
  }

  if (isLoading) {
    return (
      <Card className={`mb-8`}>
        <WidgetHeader title='Total Transactions' />
        <div className="text-xl">Loading ...</div>
      </Card>
    )
  }

  return (
    <>
      <Card className={`mb-8`}>
        <WidgetHeader title='Total Transactions' />
        <div className={`mb-4 ${LargestFont}`}>
          <CurrencyLabel
            currency={transactionBaseCurrency}
            value={total}
          />
        </div>

        <SimpleTable 
          headerList={tableHeaderList}
          dataTable={tableDataList}
          option={{
            color: 'gray'
          }}
        />

        <div className="mb-4"></div>
        <IconLink
          url={`/transactions/analytics?startDate=${startDate}&endDate=${endDate}`}
          left={<FaShareFromSquare />}
          right='View Analytics'
          className={`${SmallerFont}`}
        />
      </Card>
    </>
  )
}

export default TransactionTotalWidget