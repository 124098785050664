import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import Header from '../components/shared/Header'
import Inputbox from '../components/shared/Inputbox'
import Radiobox from '../components/shared/Radiobox'
import PrimaryButton from '../components/shared/PrimaryButton'
import AccountContext from '../context/AccountContext'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import OrderContext from '../context/OrderContext'
import Navbar from '../components/shared/Navbar'
import ToggleButton from '../components/shared/ToggleButton'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import IconLabel from '../components/shared/IconLabel'
import { FaCheck } from 'react-icons/fa'

function InvestmentsAddTradePage() {
  const {findOrder, addTrade} = useContext(OrderContext)
  const {accountList, getAccountName} = useContext(AccountContext)
  const {userId, role} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
 
  const [order, setOrder] = useState({})
  const [validAccountList, setValidAccountList] = useState([])
  const [formData, setFormData] = useState({
    trade_date: moment().format('YYYY-MM-DD'),
    buy_sell: '',
    account_id: '',
    price: '',
    position: '',
    total_amount: '',    
    strategy: '',
    note: ''
  })
 
  const [isLoading, setIsLoading] = useState(true)

  const strategyList = [
    'Dividend',
    'IPO',
    'Cup and Handle',
    'Double Bottom / Double Top',
    'Pullback with Support (Trend Continuation)',
    'Pullback with Lower Timeframe Breakout (Trend Continuation)',
    'Comples Pullback (Trend Continuation)',
    'Anti (Trend Termination)',
    'Breakout (Range Breaking)',
    'Spring / Upthrust (Range Holding / Failure Test)',
    'Other'
  ]

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'create-trade')) {
        const order = findOrder(params.id)

        // return back to main page if no active order, i.e. closed
        if (order.analytics.active_position === 0) {
          toast.error('ERROR: The Order has been Closed')
          navigate('/investments')
        }

        setOrder(order)

        setValidAccountList(accountList.filter((account) => account.is_investment && account.currency === order.currency))

        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/investments')
      }
    }    
  }, [isQuickDataLoaded])

  const onSave = async (e) => {
    e.preventDefault()

    let commission = 0.0
    if (formData.buy_sell === 'Buy') {
      commission = formData.total_amount - formData.price * formData.position
    } else if (formData.buy_sell === 'Sell') {
      commission = formData.price * formData.position - formData.total_amount
    }

    // Save trade
    const newTrade = {
      id: uuidv4(),
      trade_date: formData.trade_date,
      buy_sell: formData.buy_sell,
      price: formData.price,
      position: formData.position,
      commission: commission,
      user_id: userId,
      note: formData.note,
      order_id: order.id,
      strategy: formData.strategy,
      account_id: formData.account_id
    }

    const result = await addTrade(newTrade)
    if (result) {
      toast.success('SUCCESS: Create Trade')
    } else {
      toast.error('ERROR: Cannot Create Trade')
    }

    setFormData({
      buy_sell: '',
      account_id: '',
      price: '',
      position: '',
      total_amount: '',    
      strategy: '',
      note: ''
    })
  }

  const onMutate = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  const onMutateStrategy = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onMutateAccount = (accountId) => {
    setFormData((prevState) => ({
      ...prevState,
      account_id: accountId
    }))
  }

  if (isLoading) {
    return (
      <>
        <Header title='Investments' />
        <Navbar currentPage='Investments' />
        <div className="text-xl">Loading ...</div>
      </>
    )
  }

  return (
    <>
      <Header title='Add Trade' />

      <div className='text-2xl'>{order.stock.name} ({order.stock.code}.{order.stock.exchange})</div>
      <div className='mb-4'>Active Position: {order.analytics.active_position}</div>

      <Inputbox 
        name='trade_date'
        id='trade_date'
        type='date'
        placeholder='Trade Date'
        value={formData.trade_date}
        onChange={onMutate} 
      />

      <div className='mb-2'>Type</div>
      <div className="flex items-center mb-2">
        {['Buy', 'Sell'].map((item, index) => (
          <ToggleButton id='buy_sell' value={item} label={item} onClick={onMutate} state={item === formData.buy_sell} />
        ))}
      </div>
      <div className="mb-4"></div>    

      <div className='mb-1'>Account</div>
      {validAccountList && validAccountList.map((item, index) => {
        return (<div key={index}>
          <Radiobox 
            label={getAccountName(item)}
            name='account'
            id={item.id} 
            checked={formData.account_id === item.id} 
            onChange={(e) => {
              onMutateAccount(item.id)
            }}
          />
        </div>)
      })}
      <div className="mb-4"></div>    

      <Inputbox 
        name='price'
        id='price'
        type='number'
        placeholder='Price'
        value={formData.price}
        onChange={onMutate} 
      />

      <Inputbox 
        name='position'
        id='position'
        type='number'
        placeholder='Position'
        value={formData.position}
        onChange={onMutate} 
      />


      <Inputbox 
        name='total_amount'
        id='total_amount'
        type='number'
        placeholder='Total Amount'
        value={formData.total_amount}
        onChange={onMutate} 
      />

      <div className='mb-1'>Strategy</div>
      {strategyList.map((item, index) => (
        <div key={index}>
          <Radiobox 
            label={item}
            name='strategy'
            id={item}
            value={item}
            checked={formData.strategy === item}
            onChange={onMutateStrategy} 
          />
        </div>
      ))}

      <div className="mb-4"></div>

      <Inputbox 
        name='note'
        id='note'
        placeholder='Note'
        value={formData.note}
        onChange={onMutate}   
      />
          
      <div className="mb-8"></div>
      <div className="flex items-center">        
        <PrimaryButton onClick={onSave} className={`mr-2`}>
          <IconLabel
            left={<FaCheck />}
            right='Create Trade'
          />
        </PrimaryButton>
        <SecondaryButton className='ml-auto' onClick={e => navigate("/investments")}>Cancel</SecondaryButton>  
      </div>  
    </>
  )
}

export default InvestmentsAddTradePage