import React from 'react'
import { createContext, useContext, useState, useEffect } from 'react'
import UserContext from './UserContext'
import SupabaseContext from './SupabaseContext'

const TestContext = createContext()

export const TestProvider = ({children}) => {
  const {supabaseClient} = useContext(SupabaseContext)
  const {userId} = useContext(UserContext);
  const [accountList, setAccountList] = useState([])

  const fetchAccount = async () => {
    const { data, error } = await (await supabaseClient())
      .from('test')
      .select(`
        id,
        value
      `)
      .eq('user_id', userId)

    // console.log(data)
  }

  useEffect(() => {
    if (userId) {
      fetchAccount()    
    }
  }, [userId])

  return <TestContext.Provider 
    value={{
      accountList
    }}>
      {children}
    </TestContext.Provider>
}

export default TestContext