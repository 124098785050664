import React, { useEffect, useContext, useState } from 'react'
import AccountContext from '../context/AccountContext';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import AccountCurrencyBreakdownWidget from '../components/accounts/AccountCurrencyBreakdownWidget';
import AccountCountryBreakdownWidget from '../components/accounts/AccountCountryBreakdownWidget';
import AccountMonthlySummaryWidget from '../components/accounts/AccountMonthlySummaryWidget';
import DataDependencyContext from '../context/DataDependencyContext';

function AccountsAnalyticsPage() {
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isQuickDataLoaded) {
      setIsLoading(false)      
    }    
  }, [isQuickDataLoaded])

  if (isLoading) {
    return (<div>Loading...</div>)
  }

  return (
    <>
      <Header title='Accounts Analytics' />
      <Navbar currentPage='Accounts' />

      <AccountCurrencyBreakdownWidget />
      <AccountCountryBreakdownWidget />

      <AccountMonthlySummaryWidget />

    </>
  )
}

export default AccountsAnalyticsPage