import React, { useContext, useEffect, useState } from 'react'
import OrderContext from '../context/OrderContext'
import Header from '../components/shared/Header'
import Navbar from '../components/shared/Navbar'
import Card from '../components/shared/Card'
import InvestmentMonthlySummaryWidget from '../components/investments/InvestmentMonthlySummaryWidget'
import moment from 'moment'
import DataDependencyContext from '../context/DataDependencyContext'

function InvestmentsAnalyticsPage() {
  const {orderList} = useContext(OrderContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [isLoading, setIsLoading] = useState(true)
  const [monthList, setMonthList] = useState([])

  useEffect(() => {
    if (isQuickDataLoaded) {
      let tempMonthList = []

      orderList.forEach((order) => {
        tempMonthList = [...tempMonthList, ...order.trade.map((t) => moment(t.trade_date).format('YYYY-MM'))]

        if (order.dividend.length > 0) {
          tempMonthList = [...tempMonthList, ...order.dividend.map((d) => moment(d.payment_date).format('YYYY-MM'))]
        }
      })

      const tempMonthSet = new Set(tempMonthList);
      tempMonthList = [...tempMonthSet]
      tempMonthList.sort()
      tempMonthList.reverse()

      setMonthList(tempMonthList)

      setIsLoading(false)
    }    
  }, [isQuickDataLoaded, orderList])

  if (isLoading) {
    return (
      <>
        <Header title='Investments Analysis' />
        <Navbar currentPage='Investments' />
        <div className="text-xl">Loading ...</div>
      </>
    )
  }

  return (
    <>
      <Header title='Investments Analysis' />
      <Navbar currentPage='Investments' />      
            
      {monthList.map((month, index) => (
        <Card className='mb-8' theme='gray'>
          <InvestmentMonthlySummaryWidget key={index} month={month} />
        </Card>
      ))}      
    </>
  )
}

export default InvestmentsAnalyticsPage