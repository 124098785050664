import React, { useContext, useEffect, useState } from 'react'
import OrderContext from '../../context/OrderContext';
import UserContext from '../../context/UserContext';
import CurrencyContext from '../../context/CurrencyContext';
import moment from 'moment';
import WidgetHeader from '../shared/WidgetHeader';
import Card from '../shared/Card';
import CurrencyLabel from '../shared/CurrencyLabel';
import { LargestFont } from '../shared/Constants';
import AccountContext from '../../context/AccountContext';
import PercentageLabel from '../shared/PercentageLabel';
import DataDependencyContext from '../../context/DataDependencyContext';

function DashboardTotalAssetWidget() {
  const {orderList} = useContext(OrderContext)
  const {accountBaseCurrency, investmentBaseCurrency} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {accountList, getLatestSnapshot} = useContext(AccountContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [isLoading, setIsLoading] = useState(true)
  const [totalAssets, setTotalAssets] = useState(0.0)
  const [totalCash, setTotalCash] = useState(0.0)
  const [totalInvestments, setTotalInvestments] = useState(0.0)
  const [cashPercent, setCashPercent] = useState(0.0)
  const [investmentsPercent, setInvestmentsPercent] = useState(0.0)

  useEffect(() => {
    let today = moment().format("YYYY-MM-DD");
    let cashTotal = 0.0
    let investmentTotal = 0.0
    let cashTotalInAccountBase = 0.0
    let investmentTotalInAccountBase = 0.0

    if (isQuickDataLoaded) {
      // Investment
      let activeOrderList = orderList.filter((order) => order.attribute === 'Active')
      
      activeOrderList.forEach((order) => {
        const marketValue = order.analytics.market_value
        const localMarketValue = calculateCurrency(investmentBaseCurrency, order.currency, today, marketValue)
        investmentTotal += localMarketValue
      });
      investmentTotalInAccountBase = calculateCurrency(accountBaseCurrency, investmentBaseCurrency, today, investmentTotal)

      // Cash
      let thisMonthDate = moment().endOf('month').format("YYYY-MM-DD");

      let thisMonthTotal = calculateCashTotal(thisMonthDate);
      cashTotal += thisMonthTotal
      cashTotalInAccountBase = cashTotal // same in account base currency

      setTotalCash(cashTotal)
      setTotalInvestments(investmentTotal)
      setTotalAssets(cashTotalInAccountBase + investmentTotalInAccountBase)
      setCashPercent(cashTotalInAccountBase / (cashTotalInAccountBase + investmentTotalInAccountBase))
      setInvestmentsPercent(investmentTotalInAccountBase / (cashTotalInAccountBase + investmentTotalInAccountBase))

      setIsLoading(false)      
    }
    setIsLoading(false)
  }, [isQuickDataLoaded, orderList, accountList])

  const calculateCashTotal = (asOfDate) => {
    let tempTotal = 0;
    accountList.forEach((account) => {
      const latestSnapshot = getLatestSnapshot(account.id, asOfDate)
      if (latestSnapshot !== null) {
        let convertedValue = calculateCurrency(accountBaseCurrency, account.currency, asOfDate, latestSnapshot.total_amount - latestSnapshot.pending_amount);
        tempTotal += convertedValue
      }
    })
    return tempTotal;
  }

  if (isLoading) {
    return (
      <Card className={``}>
        <WidgetHeader title='Total Assets' />
        <div className="text-xl">Loading ...</div>
      </Card>
    )
  }

  return (
    <>
      <Card className={``}>
        <WidgetHeader title='Total Assets' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={accountBaseCurrency} 
          value={totalAssets} 
          maximumFractionDigits={0}
          theme='primary'
        />

        
      </Card>
      <Card className={``}>
        <WidgetHeader title='Total Cash' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={accountBaseCurrency} 
          value={totalCash} 
          maximumFractionDigits={0}
          theme='primary'
        />
        <PercentageLabel 
          value={cashPercent} 
          theme='gray' 
          maximumFractionDigits={0} />
      </Card>
      <Card className={``}>
        <WidgetHeader title='Total Investments' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={investmentBaseCurrency} 
          value={totalInvestments} 
          maximumFractionDigits={0}
          theme='primary'
        />
        <PercentageLabel 
          value={investmentsPercent} 
          theme='gray' 
          maximumFractionDigits={0} />
      </Card>
    </>
  )
}

export default DashboardTotalAssetWidget