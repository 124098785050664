import React, { useContext } from 'react'
import { createContext, useState, useEffect } from 'react'
import SupabaseContext from './SupabaseContext'

const RoleContext = createContext()

export const RoleProvider = ({children}) => {
  const {supabaseClient} = useContext(SupabaseContext)
  const [roleList, setRoleList] = useState([])
  const [isRoleLoaded, setIsRoleLoaded] = useState(false)

  const fetchRole = async () => {
    const { data, error } = await (await supabaseClient())
      .from('role_permission')
      .select(`
        *
      `)
    setRoleList(data)
    setIsRoleLoaded(true)
  }

  useEffect(() => {
    fetchRole()    
  }, [])

  const checkPermission = (role, permission) => {
    let temp = roleList.filter((role) => role.id === permission)[0]
    return temp[role]
  }

  return <RoleContext.Provider 
    value={{
      isRoleLoaded,
      roleList,
      checkPermission
    }}>
      {children}
    </RoleContext.Provider>
}

export default RoleContext