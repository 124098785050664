import React, { useContext, useEffect, useState } from 'react'
import OrderContext from '../../context/OrderContext';
import UserContext from '../../context/UserContext';
import CurrencyContext from '../../context/CurrencyContext';
import moment from 'moment';
import WidgetHeader from '../shared/WidgetHeader';
import Card from '../shared/Card';
import CurrencyLabel from '../shared/CurrencyLabel';
import { LargestFont } from '../shared/Constants';
import DataDependencyContext from '../../context/DataDependencyContext';

function DashboardInvestmentWidget() {
  const {orderList} = useContext(OrderContext)
  const {investmentBaseCurrency} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [isLoading, setIsLoading] = useState(true)

  const [unrealisedPnL, setUnrealisedPnL] = useState(0.0)
  const [realisedPnL, setRealisedPnL] = useState(0.0)
  const [dividend, setDividend] = useState(0.0)

  useEffect(() => {
    let unrealisedPnL = 0.0
    let realisedPnL = 0.0
    let dividend = 0.0

    if (isQuickDataLoaded) {
      const today = moment().format('YYYY-MM-DD')
      const month = moment().format('YYYY-MM')

      orderList.forEach((order) => {
        const currency = order.currency

        // Unrealized PnL
        if (order.attribute === 'Active') {
          // console.log(order)
          const temp = order.analytics.unrealized_pnl
          
          unrealisedPnL += calculateCurrency(investmentBaseCurrency, currency, today, temp, 2)
        }

        // Realized PnL
        const tradeList = order.trade.filter((item) => item.buy_sell === 'Sell' && item.trade_date.startsWith(month))
        tradeList.forEach((item) => {
          const temp = item.analytics.realized_pnl
          realisedPnL += calculateCurrency(investmentBaseCurrency, currency, item.trade_date, temp, 2)
        })

        // Dividend
        if (order.dividend && order.dividend.length > 0) {
          const dividendList = order.dividend.filter((item) => item.payment_date.startsWith(month))
          dividendList.forEach((item) => {
            const payment = item.position * item.amount_per_share - item.commission
            dividend += calculateCurrency(investmentBaseCurrency, currency, item.payment_date, payment, 2)
          })
        }
      })


      setUnrealisedPnL(unrealisedPnL)
      setRealisedPnL(realisedPnL)
      setDividend(dividend)

      setIsLoading(false)      
    }
  }, [isQuickDataLoaded, orderList])

  if (isLoading) {
    return (
      <Card className={``}>
        <WidgetHeader title='Investments' />
        <div className="text-xl">Loading ...</div>
      </Card>
    )
  }

  return (
    <>
      <Card className={``}>
        <WidgetHeader title='Unrealised PnL' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={investmentBaseCurrency} 
          value={unrealisedPnL} 
          maximumFractionDigits={0}
          theme='dynamic'
        />

        
      </Card>
      <Card className={``}>
        <WidgetHeader title='Realised PnL' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={investmentBaseCurrency} 
          value={realisedPnL} 
          maximumFractionDigits={0}
          theme='dynamic'
        />
      </Card>
      <Card className={``}>
        <WidgetHeader title='Dividends' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={investmentBaseCurrency} 
          value={dividend} 
          maximumFractionDigits={0}
          theme='primary'
        />
      </Card>
    </>
  )
}

export default DashboardInvestmentWidget