import React, { useContext, useEffect, useState } from 'react'
import Card from '../shared/Card'
import UserContext from '../../context/UserContext';
import AccountContext from '../../context/AccountContext';
import CurrencyContext from '../../context/CurrencyContext';
import moment from 'moment'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import CurrencyLabel from '../shared/CurrencyLabel';
import WidgetHeader from '../shared/WidgetHeader';
import ResponsiveTable from '../shared/ResponsiveTable';
import DataDependencyContext from '../../context/DataDependencyContext';

function AccountMonthlySummaryWidget() {
  const {accountBaseCurrency, colorTheme, accountMonthlySummaryLimit, calculateAccountCutOffDate} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {accountList, getLatestSnapshot} = useContext(AccountContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [chartData, setChartData] = useState([]);

  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // TODO Listen to account deletion and currency change from supabase once it is supported
    if (isQuickDataLoaded) {
      const numberOfMonth = accountMonthlySummaryLimit
      let tempMonthList = []
      let tempCutOffMonthList = []
      let tempTotalList = []
      let tempDiffList = []
      let tempChartDataList = []

      let thisMonthDate = moment().endOf('month').format("YYYY-MM-DD");

      tempMonthList.push(thisMonthDate);
      tempCutOffMonthList.push(calculateAccountCutOffDate(thisMonthDate));

      for (let i = 1; i < numberOfMonth; i++) {
        let tempDate = moment().endOf('month').subtract(i, 'months').format("YYYY-MM-DD");  
        let cutOffDate = calculateAccountCutOffDate(tempDate)
        tempMonthList.push(tempDate)
        tempCutOffMonthList.push(cutOffDate)
      }

      // sort month from latest to old
      tempMonthList.sort((a, b) => {return a > b ? -1 : 1})
      tempCutOffMonthList.sort((a, b) => {return a > b ? -1 : 1})

      tempMonthList.forEach((month, index) => {
        let tempTotal = calculateTotal(tempCutOffMonthList[index]);
        tempTotalList.push(tempTotal)

        tempChartDataList.push({
          name: month.substring(0, 7),
          total: Math.round(tempTotal)
        })

        // sort from oldest to newest month
        tempChartDataList.sort((a, b) => {return a.name > b.name ? 1 : -1})
      });

      for (let i = 0; i < numberOfMonth - 1; i++) {
        let diff = tempTotalList[i] - tempTotalList[i+1]
        tempDiffList.push(diff)
      }
      tempDiffList.push('')

      setChartData(tempChartDataList)    
      
      setTableHeaderList(['Month', 'Local Amount', 'Change'])
      let tempTableData = []
      tempMonthList.forEach((month, index) => {
        let tempRow = [
          month.substring(0, 7), 
          <CurrencyLabel 
            currency={accountBaseCurrency}
            value={tempTotalList[index]}
            maximumFractionDigits={0}
            theme='default'
          />
          , 
          index < tempDiffList.length - 1 ? 
          <CurrencyLabel 
            currency={accountBaseCurrency}
            value={tempDiffList[index]}
            maximumFractionDigits={0}
          /> : <></>
        ]

        tempTableData.push(tempRow)
      })
      setTableDataList(tempTableData)

      setIsLoading(false)
    }    
  }, [isQuickDataLoaded, accountList])

  const calculateTotal = (asOfDate) => {
    let tempTotal = 0;
    accountList.forEach((account) => {
      const latestSnapshot = getLatestSnapshot(account.id, asOfDate)
      if (latestSnapshot !== null) {
        let convertedValue = calculateCurrency(accountBaseCurrency, account.currency, asOfDate, latestSnapshot.total_amount - latestSnapshot.pending_amount);
        tempTotal += convertedValue
      }
    })
    return tempTotal;
  }

  const contentStyle = {
    backgroundColor: colorTheme.default_bg_color_name
  }

  if (isLoading) {
    return (
      <>
        <Card className={`mb-8`}>
          <WidgetHeader title='Monthly Summary' />
          <div className="text-xl">Loading ...</div>
        </Card>
      </>
    )
  }

  return (
    <>
      <Card className={`mb-8`}>
        <WidgetHeader title='Monthly Summary' />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-2">
          <div className=''>
            <ResponsiveTable headerList={tableHeaderList} dataTable={tableDataList} />
          </div>
          <div className='col-span-2'>
            <ResponsiveContainer width="100%" height={350}>
              <LineChart 
                width={500} 
                height={250} 
                data={chartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="2 2" stroke={colorTheme.line_chart_grid_color} />
                <XAxis dataKey="name" stroke={colorTheme.line_chart_color} />
                <YAxis stroke={colorTheme.line_chart_color} />
                <Tooltip contentStyle={contentStyle} />
                <Line type="monotone" dataKey="total" stroke={colorTheme.line_chart_color} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Card>
    </>
  )
}

export default AccountMonthlySummaryWidget