import React, { useContext, useEffect, useState } from 'react'
import OrderContext from '../../context/OrderContext'
import UserContext from '../../context/UserContext'
import CurrencyContext from '../../context/CurrencyContext'
import ColorNumberText from './ColorNumberText'
import ResponsiveTable from '../shared/ResponsiveTable'
import ToggleButton from '../shared/ToggleButton'
import { LargerFont, SmallerFont } from '../shared/Constants'
import CurrencyLabel from '../shared/CurrencyLabel'
import DataDependencyContext from '../../context/DataDependencyContext'

function InvestmentMonthlySummaryWidget({month, className, showDetails = false}) {
  const {orderList, sortTradeDesc, sortDividend} = useContext(OrderContext)
  const {calculateCurrency} = useContext(CurrencyContext)
  const {colorTheme, investmentBaseCurrency} = useContext(UserContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [isLoading, setIsLoading] = useState(true)
  const [showTrade, setShowTrade] = useState(showDetails)
  const [tradeList, setTradeList] = useState([])
  const [showDividend, setShowDividend] = useState(showDetails)
  const [dividendList, setDividendList] = useState([])
  const [pnlMap, setPnlMap] = useState({
    'ASX': 0.0,
    'HKEX': 0.0,
    'NYSE': 0.0,
  })
  const [dividendMap, setDividendMap] = useState({
    'ASX': 0.0,
    'HKEX': 0.0,
    'NYSE': 0.0,
  })
  const [total, setTotal] = useState(0.0)

  const [tradeTableHeaderList, setTradeTableHeaderList] = useState([])
  const [tradeTableDataList, setTradeTableDataList] = useState([])
  const [dividendTableHeaderList, setDividendTableHeaderList] = useState([])
  const [dividendTableDataList, setDividendTableDataList] = useState([])

  useEffect(() => {
    if (isQuickDataLoaded) {
      let tempPnlMap = {
        'ASX': 0.0,
        'HKEX': 0.0,
        'NYSE': 0.0,
      }
      let tempDividendMap = {
        'ASX': 0.0,
        'HKEX': 0.0,
        'NYSE': 0.0,
      }
      let tempTotal = 0.0
      let tempTradeList = []
      let tempDividendList = []

      orderList.forEach((order) => {
        const exchange = order.stock.exchange
        const tradeList = order.trade.filter((item) => item.buy_sell === 'Sell' && item.trade_date.startsWith(month))
        tradeList.forEach((item) => {
          const realizedPnl = item.analytics.realized_pnl
          tempPnlMap = {
            ...tempPnlMap,
            [exchange]: tempPnlMap[exchange] + realizedPnl
          }

          // Calculate total
          const currency = item.account.currency
          tempTotal += calculateCurrency(investmentBaseCurrency, currency, item.trade_date, realizedPnl, 2)

          // Insert stock name
          item.stock_name = order.stock.name
          item.stock_code = order.stock.code + '.' + order.stock.exchange

          tempTradeList.push(item)
        })

        if (order.dividend && order.dividend.length > 0) {
          const dividendList = order.dividend.filter((item) => item.payment_date.startsWith(month))
          dividendList.forEach((item) => {
            const payment = item.position * item.amount_per_share - item.commission
            tempDividendMap = {
              ...tempDividendMap,
              [exchange]: tempDividendMap[exchange] + payment
            }

            const currency = item.account.currency
            tempTotal += calculateCurrency(investmentBaseCurrency, currency, item.payment_date, payment, 2)

            // Insert stock name
            item.stock_name = order.stock.name
            item.stock_code = order.stock.code + '.' + order.stock.exchange

            tempDividendList.push(item)
          })
        }
      })

      tempTradeList.sort(sortTradeDesc)
      tempDividendList.sort(sortDividend)

      setTradeTableHeaderList(['Date', 'Stock', 'Code', 'Position', 'Price', 'Realized PnL', 'Strategy', 'Note'])
      let tempTradeTableDataList = []
      if (tempTradeList && tempTradeList.length > 0) {

        tempTradeList.forEach((item, index) => {
          let newRow = [
            item.trade_date, 
            item.stock_name,
            item.stock_code,
            item.position, 
            item.price, 
            <CurrencyLabel 
              value={item.analytics.realized_pnl} 
              currency={item.account.currency}
              maximumFractionDigits={0} />,
            item.strategy,
            item.note
          ]
          tempTradeTableDataList.push(newRow)
        })
        setTradeTableDataList(tempTradeTableDataList)
      }

      setDividendTableHeaderList(['Date', 'Stock', 'Code', 'Position', 'Amount@Share', 'Payment'])
      let tempDividendTableDataList = []
      if (tempDividendList && tempDividendList.length > 0) {

        tempDividendList.forEach((item, index) => {
          let newRow = [
            item.payment_date,
            item.stock_name,
            item.stock_code,
            item.position,
            item.amount_per_share,
            <CurrencyLabel 
              value={item.position * item.amount_per_share - item.commission}
              currency={item.account.currency}
              maximumFractionDigits={0}
              theme={'gray'}
            />,
          ]
          tempDividendTableDataList.push(newRow)
        })
        setDividendTableDataList(tempDividendTableDataList)
      }

      setPnlMap(tempPnlMap)
      setDividendMap(tempDividendMap)
      setTotal(tempTotal)
      setTradeList(tempTradeList)
      setDividendList(tempDividendList)
      setIsLoading(false)
    }
  }, [isQuickDataLoaded, orderList])

  const tableItem = ({title, number, currency, isColoured, className}) => {
    return (
      <div className="flex lg:flex-col">
        <div className={`${className} text-center`}>{number === 0.0 ? '' : title}</div>
        <div className='ml-auto lg:mx-auto'>{number === 0.0 ? '' : <ColorNumberText value={number} currency={currency} isColoured={isColoured} dp={0} className={`${className}`} />}</div>
      </div>
    )
  }

  if (isLoading) {
    return (<div>Loading ...</div>)
  }


  return (
    <div className={className}>
      <div className="grid grid-cols-1 lg:grid-cols-8 gap-4">
        <div className="flex lg:flex-col">
          <div className={`font-bold mb-2 ${LargerFont}`}>{month}</div>
          <div className='ml-auto lg:ml-0'>
            <ToggleButton 
              id='show_hide_details' 
              label={(showTrade && showDividend) ? 'Hide' : 'Show'}
              state={showTrade && showDividend} 
              className={`${SmallerFont}`}
              onClick={(e) => {
                e.preventDefault()
                setShowTrade((prevState) => !prevState)
                setShowDividend((prevState) => !prevState)
              }}              
            />
          </div>
          
        </div>
        {tableItem({
          title: 'ASX Trade', 
          number: pnlMap.ASX,
          currency: 'AUD',
          isColoured: true
        })}
        {tableItem({
          title: 'ASX Dividend', 
          number: dividendMap.ASX,
          currency: 'AUD',
          isColoured: false
        })}
        {tableItem({
          title: 'HKEX Trade', 
          number: pnlMap.HKEX,
          currency: 'HKD',
          isColoured: true
        })}
        {tableItem({
          title: 'HKEX Dividend', 
          number: dividendMap.HKEX,
          currency: 'HKD',
          isColoured: false
        })}
        {tableItem({
          title: 'NYSE Trade', 
          number: pnlMap.NYSE,
          currency: 'USD',
          isColoured: true
        })}
        {tableItem({
          title: 'NYSE Dividend', 
          number: dividendMap.NYSE,
          currency: 'USD',
          isColoured: false
        })}
        {tableItem({
          title: `Total (${investmentBaseCurrency})`, 
          number: total,
          currency: investmentBaseCurrency,
          isColoured: true,
          className: 'font-bold'
        })}
      </div>
        

      {showTrade && tradeList && tradeList.length > 0 && (
        <>
          <div className={`border-l-4 ${colorTheme.primary_border_color} pl-4`}>
            <div className='font-bold mb-2 mt-4'>Trades</div>
            <ResponsiveTable headerList={tradeTableHeaderList} dataTable={tradeTableDataList} 
              option={{
                size: 'small',
                color: 'gray'
              }} 
              className={`w-full`}
            />
            <div className="mb-4"></div>
          </div>
        </>          
      )}

      {showDividend && dividendList && dividendList.length > 0 && (
        <>
          <div className={`border-l-4 ${colorTheme.primary_border_color} pl-4`}>
            <div className='font-bold mb-2 mt-4'>Dividends</div>
            <ResponsiveTable headerList={dividendTableHeaderList} dataTable={dividendTableDataList} 
              option={{
                size: 'small',
                color: 'gray'
              }} 
            />
            <div className="mb-4"></div>
          </div>
        </>         
      )}
      
    </div>
  )
}

export default InvestmentMonthlySummaryWidget