import React, { useContext } from 'react'
import { createContext, useState, useEffect } from 'react'
import SupabaseContext from './SupabaseContext'

const CurrencyContext = createContext()

export const CurrencyProvider = ({children}) => {
  const {supabaseClient} = useContext(SupabaseContext)
  const [supportedCurrencyList, setSupportedCurrencyList] = useState(["AUD", "HKD", "EUR", "GBP", "USD"])
  const [currencyList, setCurrencyList] = useState([])
  const [isCurrencyLoaded, setIsCurrencyLoaded] = useState(false)

  const fetchCurrency = async () => {
    const { data, error } = await (await supabaseClient())
      .from('currency_v2')
      .select(`
        *
      `)
      .order('date', {ascending: true})
    setCurrencyList(data)
    setIsCurrencyLoaded(true)
  }

  useEffect(() => {
    fetchCurrency()    
  }, [])

  const getCurrency = (asOfDate) => {
    let temp = currencyList.filter((currency) => currency.date <= asOfDate)
    let result = temp.reduce((max, currency) => max.date > currency.date ? max : currency);
    return result
  }

  const calculateCurrency = (base, foreign, asOfDate, value = 1, dp = 2) => {
    if (base === foreign) {
      return value
    }
    const baseLowerCase = base.toLowerCase()
    const foreignLowerCase = foreign.toLowerCase()
    let ccySet = getCurrency(asOfDate)
    let rate = 1.0
    if (ccySet) {
      if (baseLowerCase === 'hkd') {
        rate = ccySet[foreignLowerCase]
      } else if (foreignLowerCase === 'hkd') {
        rate = 1 / (ccySet[baseLowerCase])
      } else {
        const baseRate = ccySet[foreignLowerCase]
        const foreignRate = ccySet[baseLowerCase]
        rate = (baseRate / foreignRate)
      }
    }
    
    let result = rate * value

    if (dp >= 0) {
      result = Math.round(result * Math.pow(10, dp)) / Math.pow(10, dp)
    }
    return result
  }

  return <CurrencyContext.Provider 
    value={{
      isCurrencyLoaded,
      getCurrency,
      calculateCurrency,
      supportedCurrencyList
    }}>
      {children}
    </CurrencyContext.Provider>
}

export default CurrencyContext