import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import Header from '../components/shared/Header'
import Inputbox from '../components/shared/Inputbox'
import Radiobox from '../components/shared/Radiobox'
import PrimaryButton from '../components/shared/PrimaryButton'
import AccountContext from '../context/AccountContext'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import OrderContext from '../context/OrderContext'
import Navbar from '../components/shared/Navbar'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import IconLabel from '../components/shared/IconLabel'
import { FaCheck } from 'react-icons/fa'

function InvestmentsAddDividendPage() {
  const {userId, role} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)

  const {findOrder, addDividend} = useContext(OrderContext)
  const {accountList, getAccountName} = useContext(AccountContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
  
  const [order, setOrder] = useState({})
  const [validAccountList, setValidAccountList] = useState([])
  const [formData, setFormData] = useState({})
 
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'create-dividend')) {
        const order = findOrder(params.id)
        setOrder(order)

        setValidAccountList(accountList.filter((account) => account.is_investment && account.currency === order.currency))

        setFormData({
          payment_date: moment().format('YYYY-MM-DD'),
          account_id: '',
          amount_per_share: '',
          position: order.analytics.active_position,
          total_amount: ''
        })

        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/investments')
      }
    }    
  }, [isQuickDataLoaded])

  const onSave = async (e) => {
    e.preventDefault()

    const commission = formData.amount_per_share * formData.position - formData.total_amount

    // Save dividend
    const newDividend = {
      id: uuidv4(),
      payment_date: formData.payment_date,
      order_id: order.id,
      account_id: formData.account_id,
      position: formData.position,
      amount_per_share: formData.amount_per_share,
      commission: commission,
      user_id: userId
    }

    const result = await addDividend(newDividend)
    if (result) {
      toast.success('SUCCESS: Create Dividend')
    } else {
      toast.error('ERROR: Cannot Create Dividend')
    }

    navigate('/investments')
  }

  const onMutate = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  const onMutateAccount = (accountId) => {
    setFormData((prevState) => ({
      ...prevState,
      account_id: accountId
    }))
  }

  if (isLoading) {
    return (
      <>
        <Header title='Investments' />
        <Navbar currentPage='Investments' />
        <div className="text-xl">Loading ...</div>
      </>
    )
  }

  return (
    <>
      <Header title='Add Dividend' />

      <div className='text-2xl'>{order.stock.name} ({order.stock.code}.{order.stock.exchange})</div>
      <div className='mb-4'>Active Position: {order.analytics.active_position}</div>

      <Inputbox 
        name='payment_date'
        id='payment_date'
        type='date'
        placeholder='Payment Date'
        value={formData.payment_date}
        onChange={onMutate} 
      />

      <div className='mb-1'>Account</div>
      {validAccountList && validAccountList.map((item, index) => {
        return (<div key={index}>
          <Radiobox 
            label={getAccountName(item)}
            name='account'
            id={item.id} 
            checked={formData.account_id === item.id} 
            onChange={(e) => {
              onMutateAccount(item.id)
            }}
          />
        </div>)
      })}
      <div className="mb-4"></div>    

      <Inputbox 
        name='amount_per_share'
        id='amount_per_share'
        type='number'
        placeholder='Amount Per Share'
        value={formData.amount_per_share}
        onChange={onMutate} 
      />

      <Inputbox 
        name='position'
        id='position'
        type='number'
        placeholder='Position'
        value={formData.position}
        onChange={onMutate} 
      />


      <Inputbox 
        name='total_amount'
        id='total_amount'
        type='number'
        placeholder='Total Amount'
        value={formData.total_amount}
        onChange={onMutate} 
      />

      <div className="mb-8"></div>
      <div className="flex items-center">        
        <PrimaryButton onClick={onSave} className={`mr-2`}>
          <IconLabel
            left={<FaCheck />}
            right='Create Dividend'
          />
        </PrimaryButton>
        <SecondaryButton className='ml-auto' onClick={e => navigate("/investments")}>Cancel</SecondaryButton>  
      </div>  
    </>
  )
}

export default InvestmentsAddDividendPage