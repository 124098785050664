import React, { useContext, useEffect, useState } from 'react';
import { FaTrashAlt } from "react-icons/fa";
import PrimaryButton from '../components/shared/PrimaryButton';
import Inputbox from '../components/shared/Inputbox';
import Card from '../components/shared/Card';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import UserContext from '../context/UserContext';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify'
import { LargerFont } from '../components/shared/Constants';
import RoleContext from '../context/RoleContext';
import { useNavigate } from 'react-router-dom';

// Firebase configuration (replace with your actual config)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRESTORE_APP_ID,
  measurementId: process.env.REACT_APP_FIRESTORE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const EnergyPage = () => {
  const {isUserLoaded, role, colorTheme} = useContext(UserContext)
  const {isRoleLoaded, checkPermission} = useContext(RoleContext)

  const [providers, setProviders] = useState([]);
  const [usageData, setUsageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const saveToFirebase = async () => {
    try {
      await setDoc(doc(db, 'moneyApp', 'energy'), {
        providers: providers,
        usageData: usageData
      });
      toast.success('SUCCESS: Saved to Firebase');
    } catch (error) {
      toast.error('Error saving states to Firebase:', error);
    }
  };

   // Function to load states from Firebase
   const loadFromFirebase = async () => {
    try {
      const docRef = doc(db, 'moneyApp', 'energy');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setProviders(data.providers);
        setUsageData(data.usageData);
        toast.success('SUCCESS: loaded from Firebase');
      } else {
        toast.error('No saved states found in Firebase');
      }
    } catch (error) {
      toast.error('Error loading states from Firebase:', error);
    }
  };

  // Load states from Firebase when component mounts
  useEffect(() => {
    if (isUserLoaded && isRoleLoaded) {
      if (checkPermission(role, 'enable-energy-app')) {
        loadFromFirebase();
        setIsLoading(false)  
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/dashboard')
      }
    }    
  }, [isUserLoaded, isRoleLoaded]);

  const addProvider = () => {
    setProviders([...providers, {
      name: '',
      categories: [],
      weekendCategories: [],
      discount: 0
    }]);
  };

  const updateProvider = (index, field, value) => {
    const updatedProviders = [...providers];
    updatedProviders[index][field] = value;
    setProviders(updatedProviders);
  };

  const deleteProvider = (index) => {
    const updatedProviders = [...providers];
    updatedProviders.splice(index, 1);
    setProviders(updatedProviders);
  };

  const addCategory = (providerIndex, isWeekend = false) => {
    const updatedProviders = [...providers];
    let newCategory = {
      name: '',
      startDate: '',
      endDate: '',
      startHour: '',
      endHour: '',
      rate: ''
    };
    if (isWeekend) {
      // set start and end date for weekend since it will apply for all periods
      newCategory = {
        ...newCategory,
        startDate: '2000-01-01',
        endDate: '2050-12-31'
      }
      updatedProviders[providerIndex].weekendCategories.push(newCategory);
    } else {
      updatedProviders[providerIndex].categories.push(newCategory);
    }
    setProviders(updatedProviders);
  };

  const updateCategory = (providerIndex, categoryIndex, field, value, isWeekend = false) => {
    const updatedProviders = [...providers];
    const categoryArray = isWeekend ? 'weekendCategories' : 'categories';
    updatedProviders[providerIndex][categoryArray][categoryIndex][field] = value;
    setProviders(updatedProviders);
  };

  const deleteCategory = (providerIndex, categoryIndex, isWeekend = false) => {
    const updatedProviders = [...providers];
    const categoryArray = isWeekend ? 'weekendCategories' : 'categories';
    updatedProviders[providerIndex][categoryArray].splice(categoryIndex, 1);
    setProviders(updatedProviders);
  };

  const addUsageData = () => {
    setUsageData([...usageData, {
      startDate: '',
      endDate: '',
      category: '',
      usage: ''
    }]);
  };

  const updateUsageData = (dataIndex, field, value) => {
    const updatedUsageData = [...usageData];
    updatedUsageData[dataIndex][field] = value;
    setUsageData(updatedUsageData);
  };

  const deleteUsageData = (dataIndex) => {
    const updatedUsageData = [...usageData];
    updatedUsageData.splice(dataIndex, 1);
    setUsageData(updatedUsageData);
  };

  const isWeekend = (date) => {
    const d = new Date(date);
    return d.getDay() === 0 || d.getDay() === 6;
  };

  const findApplicableRate = (date, hour, category, provider, providerIndex) => {
    const isWeekendDay = isWeekend(date);
    let applicableCategories = isWeekendDay ? provider.weekendCategories : provider.categories;

    // If it's a weekend and there are no specific weekend categories, fall back to regular categories
    if (isWeekendDay && provider.weekendCategories.length === 0) {
      applicableCategories = provider.categories;
    }

    // if the category is not found in weekend category, e.g. peak,
    // return 0 as we do not want to calculate for weekend days
    let foundWeekend = false
    if (provider.weekendCategories.length > 0) {
      const f = provider.weekendCategories.filter((c) => c.name === category)
      if (f && f.length > 0) {
        foundWeekend = true
      }
    }
    if (isWeekendDay && !foundWeekend) {
      return 0
    }

    for (const cat of applicableCategories) {
      // for non-base provider, just check for date and hour since the category may be different than base provider category
      if (cat.name === category || providerIndex > 0) {
        const categoryStartDate = new Date(cat.startDate);
        const categoryEndDate = new Date(cat.endDate);
        const currentDate = new Date(date);
        const startHour = parseInt(cat.startHour);
        const endHour = parseInt(cat.endHour);

        if (currentDate >= categoryStartDate && currentDate <= categoryEndDate) {
          if (hour >= startHour && hour <= endHour) {
            return parseFloat(cat.rate);
          }
        }
      }
    }

    return 0; // Default rate if no category matches
  };

  // calculate total number of days for each category, take into
  // account for weekend days
  // used to calculate average daily usage
  const findCategoryDays = (category, provider, startDate, endDate) => {
    let foundWeekend = false
    if (provider.weekendCategories.length > 0) {
      foundWeekend = provider.weekendCategories.filter((c) => c.name === category)
    }

    if (foundWeekend && foundWeekend.length > 0) {
      return (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
    } else {
      let daysCount = 0
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        if (!isWeekend(d)) {
          daysCount += 1
        }
      }
      return daysCount
    }
  };

  // calculate total number of hour for each category
  // to calculate average hourly rate
  const findCategoryHour = (date, category, provider) => {
    const isWeekendDay = isWeekend(date);
    let applicableCategories = isWeekendDay ? provider.weekendCategories : provider.categories;

    // If it's a weekend and there are no specific weekend categories, fall back to regular categories
    if (isWeekendDay && provider.weekendCategories.length === 0) {
      applicableCategories = provider.categories;
    }

    let totalHour = 0
    for (const cat of applicableCategories) {
      if (cat.name === category) {
        const categoryStartDate = new Date(cat.startDate);
        const categoryEndDate = new Date(cat.endDate);
        const currentDate = new Date(date);
        const startHour = parseInt(cat.startHour);
        const endHour = parseInt(cat.endHour);
        

        if (currentDate >= categoryStartDate && currentDate <= categoryEndDate) {
          totalHour += (endHour - startHour + 1)
        }
      }
    }

    return totalHour; // Default rate if no category matches
  };

  const calculateCosts = () => {
    // console.log('calculateCosts')
    let tempHourUsageDataList = [];

    return providers.map((provider, index) => {
      let totalCost = 0;

      usageData.forEach(data => {
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);
        const usage = parseFloat(data.usage);
        const category = data.category;

        // Calculate the number of days
        //const days = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
        const days = findCategoryDays(category, provider, startDate, endDate)

        // Calculate daily usage
        const dailyUsage = usage / days;

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
          for (let hour = 0; hour < 24; hour++) {
            const rate = findApplicableRate(
              date.toISOString().split('T')[0],
              hour,
              category,
              provider,
              index
            );

            let hourlyUsage = 0.0
            // For first provider, it should be base so we calculate the hourly usage from user input
            if (index === 0) {
              // Calculate hourly usage and cost
              const categoryTotalHour = findCategoryHour(date.toISOString().split('T')[0], category, provider)
              // const hourlyUsage = dailyUsage / 24;
              hourlyUsage = dailyUsage / categoryTotalHour;
              

              const tempHourUsageData = {
                date: date.toISOString().split('T')[0],
                hour: hour,
                rate: rate,
                hourlyUsage: rate > 0 ? hourlyUsage : 0
              }
              //console.log(tempHourUsageData)
              tempHourUsageDataList.push(tempHourUsageData)
            } else {
              // For other providers, take the hourly usage from base provider
              hourlyUsage = tempHourUsageDataList.filter((usage) => usage.date === date.toISOString().split('T')[0] && usage.hour === hour)[0].hourlyUsage
              //console.log('hourlyUsage', hourlyUsage)
            }            

            totalCost += hourlyUsage * rate;

            // console.log('index', index, 'category', category, 'days', days,  'date', date, 'hour', hour, 'dailyUsage', dailyUsage, 'hourlyUsage', hourlyUsage, 'rate', rate, 'totalCost', totalCost)
          }
        }
      });

      //console.log(tempHourUsageDataList)

      const discountedCost = totalCost * (1 - provider.discount / 100);

      return {
        name: provider.name,
        cost: totalCost.toFixed(2),
        discountedCost: discountedCost.toFixed(2),
      };
    });
  };

  const saveObjects = async (e) => {
    e.preventDefault()

    await saveToFirebase()
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="">
      <Header title='Energy' />
      <Navbar currentPage='Energy' />
      
      <div className="mb-4">
        <PrimaryButton onClick={addProvider}>Add Provider</PrimaryButton>
      </div>

      {providers.map((provider, providerIndex) => (
        <Card key={providerIndex} className="mb-4">
          <div className="flex items-center mb-4">
            <h2 className="text-xl font-bold">Provider {providerIndex + 1}</h2>
            <button onClick={() => deleteProvider(providerIndex)} className={`ml-auto ${colorTheme.secondary_text_color}`}>
              <FaTrashAlt />
            </button>
          </div>

          <div className="mb-4">
            <Inputbox 
              placeholder='Provider Name'
              value={provider.name}
              onChange={(e) => updateProvider(providerIndex, 'name', e.target.value)}   
            />
          </div>

          <div className="mb-4">
            <Inputbox 
              placeholder='Discount (%)'
              type="number"
              value={provider.discount}
              onChange={(e) => updateProvider(providerIndex, 'discount', e.target.value)}   
            />
          </div>

          <h3 className="font-bold mb-4">Categories</h3>
          {provider.categories.map((category, categoryIndex) => (
            <div key={categoryIndex} className={`border rounded p-4 mb-4 ${colorTheme.secondary_border_color}`}>
              <div className="flex items-center mb-2">
                <h4 className="font-medium">Category {categoryIndex + 1}</h4>
                <button onClick={() => deleteCategory(providerIndex, categoryIndex)} className={`ml-auto ${colorTheme.secondary_text_color}`}>
                  <FaTrashAlt />
                </button>
              </div>

              <div className="grid grid-cols-3 xl:grid-cols-6 gap-4">
                <div>
                <Inputbox 
                  placeholder='Name'
                  value={category.name}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'name', e.target.value)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='Start Date (YYYY-MM-DD)'
                  value={category.startDate}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'startDate', e.target.value)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='End Date (YYYY-MM-DD)'
                  value={category.endDate}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'endDate', e.target.value)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='Start Hour (0-23)'
                  type='number'
                  value={category.startHour}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'startHour', e.target.value)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='End Hour (0-23)'
                  type='number'
                  value={category.endHour}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'endHour', e.target.value)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='Rate'
                  type='number'
                  value={category.rate}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'rate', e.target.value)}   
                />
                </div>
              </div>

              
            </div>
          ))}
          <PrimaryButton onClick={() => addCategory(providerIndex)}>Add Category</PrimaryButton>
          <div className="mb-4"></div>

          <h3 className="font-bold mb-4">Weekend Categories</h3>
          {provider.weekendCategories.map((category, categoryIndex) => (
            <div key={categoryIndex} className={`border rounded p-4 mb-4 ${colorTheme.secondary_border_color}`}>
              <div className="flex items-center mb-2">
                <h4 className="">Weekend Category {categoryIndex + 1}</h4>
                <button onClick={() => deleteCategory(providerIndex, categoryIndex, true)} className={`ml-auto ${colorTheme.secondary_text_color}`}>
                  <FaTrashAlt />
                </button>
              </div>

              <div className="grid grid-cols-4 gap-4">
                <div>
                <Inputbox 
                  placeholder='Name'
                  value={category.name}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'name', e.target.value, true)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='Start Hour (0-23)'
                  type='number'
                  value={category.startHour}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'startHour', e.target.value, true)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='End Hour (0-23)'
                  type='number'
                  value={category.endHour}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'endHour', e.target.value, true)}   
                />
                </div>
                <div>
                <Inputbox 
                  placeholder='Rate'
                  type='number'
                  value={category.rate}
                  onChange={(e) => updateCategory(providerIndex, categoryIndex, 'rate', e.target.value, true)}   
                />
                </div>
              </div>


             
            </div>
          ))}
          <PrimaryButton onClick={() => addCategory(providerIndex, true)}>Add Weekend Category</PrimaryButton>
        </Card>
      ))}

    <h2 className="text-xl font-bold mb-2">Usage Data</h2>
      {usageData.map((data, dataIndex) => (
        <Card key={dataIndex} className="mb-4">
          <div className="flex items-center mb-4">
            <div className="font-bold">Usage Data {dataIndex + 1}</div>
            <button onClick={() => deleteUsageData(dataIndex)} className={`ml-auto ${colorTheme.secondary_text_color}`}>
              <FaTrashAlt />
            </button>
          </div>

          <div className="grid grid-cols-4 gap-4">
          <div>
            <Inputbox 
              placeholder='Start Date (YYYY-MM-DD)'
              value={data.startDate}
              onChange={(e) => updateUsageData(dataIndex, 'startDate', e.target.value)}   
            />
          </div>
          <div>
            <Inputbox 
              placeholder='End Date (YYYY-MM-DD)'
              value={data.endDate}
              onChange={(e) => updateUsageData(dataIndex, 'endDate', e.target.value)}   
            />
          </div>

          <div>
            <Inputbox 
              placeholder='Category'
              value={data.category}
              onChange={(e) => updateUsageData(dataIndex, 'category', e.target.value)}   
            />
          </div>

          <div>
            <Inputbox 
              type="number"
              placeholder='Usage (kWh)'
              value={data.usage}
              onChange={(e) => updateUsageData(dataIndex, 'usage', e.target.value)}   
            />
          </div>
          </div>
        </Card>
      ))}
      <PrimaryButton onClick={addUsageData}>Add Usage Data</PrimaryButton>
      <div className="mb-8"></div>

      <div className={`mb-8 ${LargerFont}`}>
        <h2 className="font-bold mb-4">Results</h2>
        {calculateCosts().map((result, index) => (
          <div key={index} className="mb-2">
            <p>{result.name}: ${result.cost} (Discounted: ${result.discountedCost})</p>
          </div>
        ))}
      </div>

      <div>
        <PrimaryButton onClick={saveObjects}>Save Objects</PrimaryButton>      
      </div>
    </div>
  );
};

export default EnergyPage;