import React, { useContext } from 'react'
import UserContext from '../../context/UserContext';
import { SmallerFont } from './Constants';

function WidgetHeader({title}) {
  const {colorTheme} = useContext(UserContext);

  return (
    <>
      <div className={`${SmallerFont} ${colorTheme.secondary_text_color} mb-1`}>{title}</div>
    </>
  )
}

export default WidgetHeader