import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'

function Radiobox({label, name, id, value, checked, onChange, className}) {
  const {colorTheme} = useContext(UserContext)
  return (
    <div className={`${checked ? colorTheme.primary_text_color : colorTheme.secondary_text_color}`}>
      <input 
        type="radio" 
        name={name}
        id={id} 
        checked={checked} 
        value={value}
        onChange={onChange} 
        className={`mr-2 ${colorTheme.accent_color} ${className}`} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Radiobox