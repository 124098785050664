import React, { useEffect, useContext, useState } from 'react'
import UserContext from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../components/shared/PrimaryButton';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import SimpleTable from '../components/shared/SimpleTable';
import Card from '../components/shared/Card'
import { LargerFont } from '../components/shared/Constants';
import { FaPen } from 'react-icons/fa';
import IconLabel from '../components/shared/IconLabel';

function SettingsPage() {
  const navigate = useNavigate();

  const {theme, accountBaseCurrency, transactionBaseCurrency, investmentBaseCurrency, accountMonthlySummaryLimit, transactionMonthlySummaryLimit, getThemeName, colorTheme, accountCutOffDay} = useContext(UserContext);

  return (
    <>
      <Header title='Settings' />
      <Navbar currentPage='Settings' />
      <div className="mb-8"></div>
      <Card className={'lg:w-1/2 mb-4'}>
        <div className='flex items-center'>
        <div className={`${LargerFont}`}>Theme</div>
          <div className="ml-auto flex items-center">
            <div className={`w-4 h-4 mr-2 rounded-full ${colorTheme.primary_bg_color}`}></div>
            <div>{getThemeName(theme)}</div>
          </div>
        </div>
      </Card>
      <Card className={'lg:w-1/2 mb-4'}>
        <div className={`${LargerFont}`}>Accounts</div>
        <div className="mb-4"></div>
        <div className='flex'>
          <div>Base Currency</div>
          <div className="ml-auto">{accountBaseCurrency}</div>         
        </div>
        <div className='flex'>
          <div>Monthly Summary Limit</div>
          <div className="ml-auto">{accountMonthlySummaryLimit}</div>         
        </div>
        <div className='flex'>
          <div>Cut Off Day</div>
          <div className="ml-auto">{accountCutOffDay}</div>         
        </div>
      </Card>
      <Card className={'lg:w-1/2 mb-4'}>
        <div className={`${LargerFont}`}>Transactions</div>
        <div className="mb-4"></div>
        <div className='flex'>
          <div>Base Currency</div>
          <div className="ml-auto">{transactionBaseCurrency}</div>         
        </div>
        <div className='flex'>
          <div>Monthly Summary Limit</div>
          <div className="ml-auto">{transactionMonthlySummaryLimit}</div>         
        </div>
      </Card>
      <Card className={'lg:w-1/2 mb-4'}>
        <div className={`${LargerFont}`}>Investments</div>
        <div className="mb-4"></div>
        <div className='flex'>
          <div>Base Currency</div>
          <div className="ml-auto">{investmentBaseCurrency}</div>         
        </div>
      </Card>
      <div className="mb-4"></div>
      <PrimaryButton onClick={e=> navigate('/settings/edit')}>
        <IconLabel
          left={<FaPen />}
          right='Edit'
        />
      </PrimaryButton>
      
    </>
  )
}

export default SettingsPage