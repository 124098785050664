import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import UserContext from '../context/UserContext';
import Inputbox from '../components/shared/Inputbox';
import PrimaryButton from '../components/shared/PrimaryButton';
import SecondaryButton from '../components/shared/SecondaryButton';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import Radiobox from '../components/shared/Radiobox';
import IconLabel from '../components/shared/IconLabel';
import { FaCheck } from 'react-icons/fa';


function SettingsEditPage() {
  const navigate = useNavigate();

  const {user, theme, accountBaseCurrency, transactionBaseCurrency, investmentBaseCurrency, accountMonthlySummaryLimit, transactionMonthlySummaryLimit, getThemeName, accountCutOffDay} = useContext(UserContext);
  const [formData, setFormData] = useState({
    theme: '',
    accountBaseCurrency: '',
    transactionBaseCurrency: '',
    investmentBaseCurrency: '',
    accountMonthlySummaryLimit: '',
    transactionMonthlySummaryLimit: '',
    accountCutOffDay: ''
  })

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      theme: theme,
      accountBaseCurrency: accountBaseCurrency,
      transactionBaseCurrency: transactionBaseCurrency,
      investmentBaseCurrency: investmentBaseCurrency,
      accountMonthlySummaryLimit: accountMonthlySummaryLimit,
      transactionMonthlySummaryLimit: transactionMonthlySummaryLimit,
      accountCutOffDay: accountCutOffDay
    }))
  }, [theme, accountBaseCurrency, transactionBaseCurrency, investmentBaseCurrency, accountMonthlySummaryLimit, transactionMonthlySummaryLimit, accountCutOffDay])

  const onMutate = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  

  const onSave = (e) => {
    e.preventDefault()
    user.update({
      unsafeMetadata: {
        theme: formData.theme,
        accountBaseCurrency: formData.accountBaseCurrency,
        transactionBaseCurrency: formData.transactionBaseCurrency,
        investmentBaseCurrency: formData.investmentBaseCurrency,
        accountMonthlySummaryLimit: formData.accountMonthlySummaryLimit,
        transactionMonthlySummaryLimit: formData.transactionMonthlySummaryLimit,
        accountCutOffDay: formData.accountCutOffDay
      }
    })

    toast.success('Settings Update is Successful')
    navigate('/settings')
  }


  return (
    <>
      <Header title='Edit Settings' />
      <Navbar currentPage='Settings' />

      <div>Theme</div>
      <div className="flex flex-col md:items-center md:flex-row mb-4">
        {['light_black', 'light_orange', 'light_teal', 'light_pink', 'dark_yellow'].map((item, index) => {
          return (
            <>
            <div key={index}>
              <Radiobox 
                label={getThemeName(item)}
                name={item}
                id={item}
                checked={formData.theme === item} 
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    theme: item
                  }))
                }}
              />
            </div>
            <div className="mr-4"></div>
          </>)
        })}
      </div>
      
       

      <Inputbox name='accountBaseCurrency' id='accountBaseCurrency' placeholder='Account Base Currency' value={formData.accountBaseCurrency} onChange={onMutate}/>

      <Inputbox name='accountMonthlySummaryLimit' id='accountMonthlySummaryLimit' placeholder='Account Monthly Summary Limit' value={formData.accountMonthlySummaryLimit} onChange={onMutate}/>
      
      <Inputbox name='accountCutOffDay' id='accountCutOffDay' placeholder='Account Cut Off Day' value={formData.accountCutOffDay} onChange={onMutate} annotation={'Next month day as the cut off day of last month. Accepts exact Day, e.g. 10, or "End"'}/>

      <Inputbox name='transactionBaseCurrency' id='transactionBaseCurrency' placeholder='Transaction Base Currency' value={formData.transactionBaseCurrency} onChange={onMutate}/>
      <Inputbox name='transactionMonthlySummaryLimit' id='transactionMonthlySummaryLimit' placeholder='Transaction Monthly Summary Limit' value={formData.transactionMonthlySummaryLimit} onChange={onMutate}/>      

      <Inputbox name='investmentBaseCurrency' id='investmentBaseCurrency' placeholder='Investment Base Currency' value={formData.investmentBaseCurrency} onChange={onMutate}/>

      <div className="flex">
        <PrimaryButton onClick={onSave}>
          <IconLabel
            left={<FaCheck />}
            right='Save'
          />
        </PrimaryButton>
        <SecondaryButton className='ml-auto' onClick={e => navigate('/settings')}>Cancel</SecondaryButton>
      </div>
    </>
  )
}

export default SettingsEditPage