import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../../context/UserContext'
import { SmallestFont } from './Constants'

function Inputbox({type='text', name, id, placeholder, value, className, onChange, annotation}) {
  const {colorTheme} = useContext(UserContext)
  const [input, setInput] = useState(value)

  useEffect(() => {
    setInput(value)
  }, [value])

  return (
    <>
    <div>{placeholder}</div>
    <div className={`${className} mb-4`}>
        <input 
          type={type} 
          name={name} 
          id={id}
          value={input}
          className={`${colorTheme.default_bg_color} border-2 ${colorTheme.secondary_border_color} focus:outline-none focus:${colorTheme.primary_border_color} w-full px-2 py-1 rounded mt-1`} 
          onChange={(e) => {
            setInput(e.target.value)
            onChange(e)
          }}
        />
        {annotation && (
          <div className={`${SmallestFont} ${colorTheme.secondary_text_color} mt-1`}>{annotation}</div>
        )}
    </div>
    </>
  )
}

export default Inputbox