import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'

function Checkbox({name, id, value, onClick, onChange, checked, className, label}) {
  const {colorTheme} = useContext(UserContext)

  return (
    <div className={`${className} ${checked ? colorTheme.primary_text_color : colorTheme.secondary_text_color}`}>
      <input type="checkbox" name={name} id={id} value={value} onClick={onClick} onChange={onChange} checked={checked} className={`mr-2 ${colorTheme.accent_color}`} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Checkbox