import React, { useContext } from 'react'
import {Link} from 'react-router-dom'
import Card from '../shared/Card'
import AccountTypeLabel from './AccountTypeLabel'
import { FaPen } from 'react-icons/fa6'
import CurrencyLabel from '../shared/CurrencyLabel'
import HighlightLabel from '../shared/HighlightLabel'
import UserContext from '../../context/UserContext'
import { LargerFont, LargestFont, SmallerFont, SmallestFont } from '../shared/Constants'
import IconLink from '../shared/IconLink'
import RolePermissionContainer from '../shared/RolePermissionContainer'

function AccountCard({account, latestSnapshot, className}) {
  const {accountBaseCurrency, getCountryColor} = useContext(UserContext)
  const totalAmount = latestSnapshot?.total_amount ?? 0
  const pendingAmount = latestSnapshot?.pending_amount ?? 0
  const netAmount = Math.round((totalAmount - pendingAmount) * 100) / 100

  return (
    <Card className={`${className}`}>
      <div className="flex items-center mb-1">
        <div className={`${SmallerFont} ${getCountryColor(account.country)}`}>{account.country}</div>  
        {!account.is_active && (
          <HighlightLabel
            theme='gray' 
            className={`ml-2 ${SmallestFont}`}>
              Inactive
          </HighlightLabel>
        )}
      </div>
      
      
      <div className="flex items-center mb-6">
        <div className={`${LargerFont}`}>{account.name} ({account.currency})</div>
        <div className='ml-auto'>
          <CurrencyLabel 
            className={LargerFont} 
            currency={account.currency} 
            value={netAmount} 
            maximumFractionDigits={2}
            showBaseCurrency={true}
            baseCurrency={accountBaseCurrency}
          />
        </div>        
      </div>

      <div className="flex items-center flex-wrap">
        <AccountTypeLabel enabled={account.is_cash} value='Cash' />
        <AccountTypeLabel enabled={account.is_investment} value='Investment' />
        <AccountTypeLabel enabled={account.is_income} value='Income' />
        <AccountTypeLabel enabled={account.is_expense} value='Expense' />

        <RolePermissionContainer permission={'edit-account'}>
          <IconLink 
            url={`/accounts/edit/${account.id}`} 
            left={<FaPen />}
            right='Edit'
            className={`ml-auto ${SmallerFont}`}
          />
        </RolePermissionContainer>
      </div>
      
    </Card>
  )
}

export default AccountCard