import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import UserContext from '../../context/UserContext';

function IconLink({url, left, right, className}) {
  const {colorTheme} = useContext(UserContext);
  return (
    <>
      <Link to={url} className={`${colorTheme.secondary_text_color} hover:${colorTheme.primary_text_color} ${className}`}>
        <div className='flex items-center'>
          <div className='mr-1.5'>{left}</div>
          <div>{right}</div>
        </div>
      </Link>
    </>
  )
}

export default IconLink