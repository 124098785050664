import React, { useContext, useEffect, useState } from 'react'
import Card from '../shared/Card'
import UserContext from '../../context/UserContext';
import AccountContext from '../../context/AccountContext';
import CurrencyContext from '../../context/CurrencyContext';
import moment from 'moment'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import ResponsiveTable from '../shared/ResponsiveTable';
import WidgetHeader from '../shared/WidgetHeader';
import CurrencyLabel from '../shared/CurrencyLabel';
import PercentageLabel from '../shared/PercentageLabel';
import DataDependencyContext from '../../context/DataDependencyContext';

function AccountCurrencyBreakdownWidget() {
  const {accountBaseCurrency, colorTheme} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {accountList, getLatestSnapshot} = useContext(AccountContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [chartData, setChartData] = useState([])
  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // TODO Listen to account deletion and currency change from supabase once it is supported
    if (isQuickDataLoaded) {
      let tempTotalConvertedList = []
      let tempTotalBaseList = []
      let tempPercentList = []
      let tempChartDataList = []

      let tempConvertedTotal = 0.0

      let thisMonthDate = moment().endOf('month').format("YYYY-MM-DD");

      // Get all country from accounts and remove duplicate
      let tempSupportedCurrcncyList = [...new Set(accountList.map((a) => a.currency))]
      tempSupportedCurrcncyList.sort()

      tempSupportedCurrcncyList.forEach((currency) => {
        let {baseTotal, convertedTotal} = calculateTotal(thisMonthDate, currency);
        tempTotalBaseList.push(baseTotal)
        tempTotalConvertedList.push(convertedTotal)

        // Calculate total for percentage calculation
        tempConvertedTotal += convertedTotal
      });

      // Calculate percentage
      tempSupportedCurrcncyList.forEach((currency, index) => {
        let percent = tempTotalConvertedList[index] / tempConvertedTotal

        tempPercentList.push(percent)

        if (tempTotalBaseList[index] > 0.0) {
          tempChartDataList.push({
            name: currency,
            value: percent
          })
        }
      });

      setChartData(tempChartDataList)     
      
      setTableHeaderList(['Currency', 'Local Amount', `Amount in ${accountBaseCurrency}`, 'Percentage'])
      let tempTableData = []
      tempSupportedCurrcncyList.forEach((currency, index) => {
        let tempRow = [
          currency, 
          <CurrencyLabel
            value={tempTotalBaseList[index]}
            maximumFractionDigits={0}
            currency={currency}
            theme='default'
          />,
          <CurrencyLabel
            value={tempTotalConvertedList[index]}
            maximumFractionDigits={0}
            currency={accountBaseCurrency}
            theme='default'
          />,
          <PercentageLabel
            value={tempPercentList[index]}
            maximumFractionDigits={0}
          />
        ]

        tempTableData.push(tempRow)
      })
      setTableDataList(tempTableData)

      setIsLoading(false)
    }    
  }, [isQuickDataLoaded, accountList])

  const calculateTotal = (asOfDate, currency) => {
    let baseTotal = 0, convertedTotal = 0;
    accountList.forEach((account) => {
      if (account.currency === currency) {
        const latestSnapshot = getLatestSnapshot(account.id, asOfDate)
        if (latestSnapshot !== null) {
          let tempTotal = latestSnapshot.total_amount - latestSnapshot.pending_amount;
          let convertedValue = calculateCurrency(accountBaseCurrency, account.currency, asOfDate, tempTotal);
          convertedTotal += convertedValue

          baseTotal += tempTotal
        }
      }
    })
    
    return {baseTotal, convertedTotal};
  }

  // Render pie chart label text in middle of each pie
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  if (isLoading) {
    return (
      <>
        <Card className={`mb-8`}>
          <WidgetHeader title='Currency Breakdown' />
          <div className="text-xl">Loading ...</div>
        </Card>
      </>
    )
  }

  return (
    <>
    <Card className={`mb-8`}>
        <WidgetHeader title='Currency Breakdown' />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
          <div className=''>
            <ResponsiveTable headerList={tableHeaderList} dataTable={tableDataList} />
          </div>
          <div className=''>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart width={450} height={450}>
                <Pie data={chartData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} label={renderCustomizedLabel} labelLine={false}>
                {
                  chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorTheme.bar_chart_color_list[index]}/>
                  ))
                }
                </Pie>
                <Legend verticalAlign="bottom" />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Card>

      
    </>
    
  )
}

export default AccountCurrencyBreakdownWidget