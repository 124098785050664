import React, { useContext } from 'react'
import { createContext, useState, useEffect } from 'react'
import UserContext from './UserContext'
import SupabaseContext from './SupabaseContext'

const ActorContext = createContext()

export const ActorProvider = ({children}) => {
  const {supabaseClient} = useContext(SupabaseContext)
  const [actorList, setActorList] = useState([])
  const {userId} = useContext(UserContext);
  const [isActorLoaded, setIsActorLoaded] = useState(false)

  const fetchActor = async () => {
      const { data, error } = await (await supabaseClient())
        .from('view_movie_actor')
        .select()
        .eq('user_id', userId)
        
      data.sort(_sort)
      setActorList(data)
      setIsActorLoaded(true)
  }

  useEffect(() => {
    if (userId) {
      fetchActor()    
    }    
  }, [userId])

  const _loadSingleFromDB = async (actorId) => {
    const { data, error } = await (await supabaseClient())
      .from('movie_actor')
      .select()
      .eq('user_id', userId)
      .eq('id', actorId)
    
    return data[0]
  }

  const findActor = (id) => {
    const result = actorList.filter((a) => a.id === id)
    if (result && result.length > 0) {
      return result[0]
    }
    return null
  }

  const _hasNumberId = (actor) => {
    return actor.number_id !== null && actor.number_id !== ''
  }

  const _sort = (a, b) => {
    if (_hasNumberId(a) && _hasNumberId(b)) {
      return a.number_id.localeCompare(b.number_id)
    }
    if (_hasNumberId(a) && !_hasNumberId(b)) {
      return -1
    }
    if (!_hasNumberId(a) && _hasNumberId(b)) {
      return 1
    }
    if (!_hasNumberId(a) && !_hasNumberId(b)) {
      return a.jav_id.localeCompare(b.jav_id)
    }
    return 0
  }
  
  const isActorExists = (javId) => {
    const result = actorList.filter((a) => a.jav_id === javId)
    
    if (result && result.length > 0) {
      return true
    }
    return false
  }

  

  const filterAccount = (searchText, showHidden) => {
    /*let tempList = [...accountList]
    if (searchText !== '') {
      tempList = tempList.filter((item) => item.name.toLowerCase() === searchText.toLowerCase() || item.institution.toLowerCase() === searchText.toLowerCase() || item.currency.toLowerCase() === searchText.toLowerCase())
    }
    if (!showHidden) {
      tempList = tempList.filter((t) => !(t.is_hidden))
    }

    tempList.sort(_sort)
    return tempList
    */
  }
  
  const _addToDb = async (newActor) => {
    const { error } = await (await supabaseClient())
      .from('movie_actor')
      .insert(newActor)

    if (error) {
      return false
    } 
    return true
  }

  const addActor = async (newActor) => {
    const successAdd = await _addToDb(newActor)

    if (successAdd) {
      const newT = await _loadSingleFromDB(newActor.id)
      let tempList = [...actorList, newT]
      tempList.sort(_sort)        
      setActorList(tempList)

      return true
    }
    
    return false
  }

  const _updateToDb = async (newActor) => {
    const { error } = await (await supabaseClient())
      .from('movie_actor')
      .update(newActor)
      .eq('id', newActor.id)

    if (error) {
      return false
    } 
    return true
  }


  const updateActor = async (newActor) => {
    const successUpdate = await _updateToDb(newActor)

    if (successUpdate) {
      const newT = await _loadSingleFromDB(newActor.id)
      let tempList = [...actorList.filter(a => a.id !== newActor.id), newT]
      tempList.sort(_sort)        
      setActorList(tempList)

      return true
    }
    
    return false
  }

  const _deleteFromDb = async (id) => {
    const { error } = await (await supabaseClient())
        .from('movie_actor')
        .delete()
        .eq('id', id)
    
    if (error) {
      return false
    }
    return true
  }

  const deleteActor = async (id) => {
    // Delete database
    const successDelete = await _deleteFromDb(id)

    if (successDelete) {
      // Delete cache
      setActorList(actorList.filter((t) => t.id !== id))
      return true
    } else {
      return false
    }
  }

  const decodeCareerStatus = (cs) => {
    switch (cs) {
      case 'AT':
        return 'Active'
      case 'RT':
        return 'Retired'
      case 'IA':
          return 'Inactive'
      default:
        break
    }
    return ''
  }

  const decodeDownloadStatus = (ds) => {
    switch (ds) {
      case 'DA':
        return 'Done All'
      case 'DR':
        return 'Done Rare'
      case 'ND':
        return 'Not Done'
      default:
        break
    }
    return ''
  }

  return <ActorContext.Provider 
    value={{
      isActorLoaded,
      actorList,
      findActor,
      isActorExists,
      addActor,
      updateActor,
      deleteActor,
      decodeCareerStatus,
      decodeDownloadStatus
    }}>
      {children}
    </ActorContext.Provider>
}

export default ActorContext