import React from 'react'
import { useState, useEffect, useContext } from 'react'
import Header from '../components/shared/Header'
import OrderContext from '../context/OrderContext'
import Navbar from '../components/shared/Navbar'
import { FaPlus, FaEye } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../components/shared/PrimaryButton'
import Inputbox from '../components/shared/Inputbox'
import OrderCard from '../components/investments/OrderCard'
import Fuse from 'fuse.js'
import InvestmentExchangeSummaryWidget from '../components/investments/InvestmentExchangeSummaryWidget'
import IconLabel from '../components/shared/IconLabel'
import RolePermissionContainer from '../components/shared/RolePermissionContainer'
import DataDependencyContext from '../context/DataDependencyContext'


function InvestmentsPage() {
  const navigate = useNavigate();

  const {orderList} = useContext(OrderContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [searchedOrderList, setSearchedOrderList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchWord, setSearchWord] = useState('\'active') // active order only by default

  useEffect(() => {
    if (isQuickDataLoaded) {
      let localOrderList = [...orderList]
      
      if (searchWord !== '') {
        const fuseOptions = {
          // isCaseSensitive: false,
          // includeScore: false,
          // shouldSort: true,
          // includeMatches: false,
          // findAllMatches: false,
          // minMatchCharLength: 1,
          // location: 0,
          // threshold: 0.6,
          // distance: 100,
          // useExtendedSearch: false,
          // ignoreLocation: false,
          // ignoreFieldNorm: false,
          // fieldNormWeight: 1,
          includeMatches: true,
          minMatchCharLength: 1,
          threshold: 0.1,
          useExtendedSearch: true,
          keys: [
            "stock.name",
            "stock.code",
            "stock.exchange",
            "note",
            "attribute"
          ]
        };
        
        const fuse = new Fuse(localOrderList, fuseOptions);
  
        const searchResult = fuse.search(searchWord)
        localOrderList = searchResult.map(r => r.item)
      }
  
      setSearchedOrderList(localOrderList)

    }
    setIsLoading(false)
  }, [isQuickDataLoaded, orderList, searchWord])
  

  if (isLoading) {
    return (
      <>
        <Header title='Investments' />
        <Navbar currentPage='Investments' />
        <div className="text-xl">Loading ...</div>
      </>
    )
  }

  return (
    <>
      <Header title='Investments' />
      <Navbar currentPage='Investments' />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
        <InvestmentExchangeSummaryWidget 
          exchange={'ASX'} 
          currency={'AUD'}
          orderList={searchedOrderList.filter((order) => order.stock.exchange === 'ASX')}
        />
        <InvestmentExchangeSummaryWidget 
          exchange={'HKEX'} 
          currency={'HKD'}
          orderList={searchedOrderList.filter((order) => order.stock.exchange === 'HKEX')}
        />

        <InvestmentExchangeSummaryWidget 
          exchange={'NYSE'} 
          currency={'USD'}
          orderList={searchedOrderList.filter((order) => order.stock.exchange === 'NYSE')}
        />
      </div>
      


      <div className="flex items-center mb-8">
        <RolePermissionContainer permission={'create-order'}>
          <PrimaryButton onClick={e => navigate("/investments/add-order")} className={`mr-4`}>
            <IconLabel
              left={<FaPlus />}
              right='Order'
            />
          </PrimaryButton>    
        </RolePermissionContainer>
        <PrimaryButton onClick={e => navigate("/investments/analytics")}>
          <IconLabel
            left={<FaEye />}
            right='Analytics'
          />
        </PrimaryButton>    
      </div>        

      <div className="mb-4">
        <Inputbox 
          name='searchWord'
          id='searchWord'
          placeholder='Search'
          value={searchWord}
          onChange={(e) => { 
            setSearchWord(e.target.value)
          }} 
          annotation="Advanced Syntax: (1) =exact, (2) 'include, (3) ^start, (4) .end$"
        />
      </div>
      
      <div className="mb-2">Showing {searchedOrderList.length} Orders</div>

      {searchedOrderList && searchedOrderList.map((order, index) => (
        <OrderCard key={index} order={order} />
      ))}

    </>

  )
}

export default InvestmentsPage
