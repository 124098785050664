import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'
import CurrencyLabel from '../shared/CurrencyLabel'
import PercentageLabel from '../shared/PercentageLabel'

function ColorNumberText({className, text, value, currency, percentage, isColoured = false, dp = 2}) {
  const {getValueColor} = useContext(UserContext)

  const numberComponent = (value, currency) => {
    if (isNaN(value) || value === 0.0) {
      return 0
    }
    if (currency && currency !== '') {
      return <CurrencyLabel 
        value={value}
        currency={currency}
        maximumFractionDigits={dp}
        theme={isColoured ? 'dynamic' : 'default'}
      />
    } else {
      return Math.round(value * Math.pow(10, dp)) / Math.pow(10, dp)
    }    
  }

  const showPercentage = percentage > 0.0 || percentage < 0.0

  return (
    <>
      <div className={`${className} ${isColoured ? getValueColor(value) : ''} flex items-center`}>
        <div className='mr-2'>{(text && text !== '') ? text + ': ' : ''}</div>
        <div className='mr-2'>{numberComponent(value, currency)}</div>
        {showPercentage && (
        <>
          <div>(</div>
          <PercentageLabel
            value={percentage}
            maximumFractionDigits={dp}
          ></PercentageLabel>
          <div>)</div>
        </>
        )}
      </div>
    </>
  )
}

export default ColorNumberText