import React, { useContext, useEffect, useState } from 'react'
import Card from '../shared/Card'
import UserContext from '../../context/UserContext';
import AccountContext from '../../context/AccountContext';
import CurrencyContext from '../../context/CurrencyContext';
import moment from 'moment'
import { FaAnglesUp, FaAnglesDown, FaShareFromSquare } from "react-icons/fa6";
import CurrencyLabel from '../shared/CurrencyLabel';
import WidgetHeader from '../shared/WidgetHeader';
import { LargestFont, SmallerFont } from '../shared/Constants';
import IconLink from '../shared/IconLink';
import IconLabel from '../shared/IconLabel';
import PercentageLabel from '../shared/PercentageLabel';
import DataDependencyContext from '../../context/DataDependencyContext';

function AccountTotalCashWidget() {
  const {accountBaseCurrency, getValueColor, calculateAccountCutOffDate} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {accountList, getLatestSnapshot} = useContext(AccountContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [total, setTotal] = useState(0);
  const [diffValue, setDiffValue] = useState(0);
  const [diffPercent, setDiffPercent] = useState(0);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // TODO Listen to account deletion and currency change from supabase once it is supported
    if (isQuickDataLoaded) {
      let thisMonthDate = moment().endOf('month').format("YYYY-MM-DD");
      let lastMonthDate = moment().endOf('month').subtract(1, 'months').format("YYYY-MM-DD");
      let lastMonthCutOffDate = calculateAccountCutOffDate(lastMonthDate)

      let thisMonthTotal = calculateTotal(thisMonthDate);
      setTotal(thisMonthTotal)
      let lastMonthTotal = calculateTotal(lastMonthCutOffDate);

      setDiffValue(thisMonthTotal - lastMonthTotal)
      setDiffPercent((thisMonthTotal - lastMonthTotal) / lastMonthTotal)
      setIsLoading(false)      
    }    
  }, [isQuickDataLoaded, accountList])

  const calculateTotal = (asOfDate) => {
    let tempTotal = 0;
    accountList.forEach((account) => {
      const latestSnapshot = getLatestSnapshot(account.id, asOfDate)
      if (latestSnapshot !== null) {
        let convertedValue = calculateCurrency(accountBaseCurrency, account.currency, asOfDate, latestSnapshot.total_amount - latestSnapshot.pending_amount);
        tempTotal += convertedValue
      }
    })
    return tempTotal;
  }

  if (isLoading) {
    return (
      <Card className={`mb-8`}>
        <WidgetHeader title='Total Cash' />
        <div className="text-xl">Loading ...</div>
      </Card>
    )
  }

  return (
    <>
      <Card className={`mb-8`}>
        <WidgetHeader title='Total Cash' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={accountBaseCurrency} 
          value={total} 
          maximumFractionDigits={2}
        />

        <IconLabel
          className={`mb-4 ${SmallerFont} ${getValueColor(diffValue)}`}
          left={diffValue > 0.0 ? <FaAnglesUp /> : <FaAnglesDown />}
          right={
            <div className='flex items-center'>
              <CurrencyLabel 
                currency={accountBaseCurrency} 
                value={diffValue} 
                maximumFractionDigits={2}
                className={`mr-2`}
              />
              (<PercentageLabel
                value={diffPercent}
                maximumFractionDigits={2}
              />)
            </div>            
          }
        />
        
        <IconLink
          url='/accounts/analytics'
          right='View Analytics'
          left={<FaShareFromSquare />}
          className={SmallerFont}
        />
        
      </Card>
    </>
  )
}

export default AccountTotalCashWidget