import React from 'react'
import { createContext, useState, useEffect } from 'react'
import { useUser } from "@clerk/clerk-react";
import moment from 'moment';


const UserContext = createContext()

export const UserProvider = ({children}) => {
  const { isSignedIn, user, isLoaded } = useUser();
  const [isUserLoaded, setIsUserLoaded] = useState(false)
  const [role, setRole] = useState('')
  const [theme, setTheme] = useState('')
  const [userName, setUserName] = useState('')
  const [userId, setUserId] = useState('')
  const [accountBaseCurrency, setAccountBaseCurrency] = useState("AUD")
  const [transactionBaseCurrency, setTransactionBaseCurrency] = useState("AUD")
  const [investmentBaseCurrency, setInvestmentBaseCurrency] = useState("AUD")
  const [accountMonthlySummaryLimit, setAccountMonthlySummaryLimit] = useState(12)
  const [transactionMonthlySummaryLimit, setTransactionMonthlySummaryLimit] = useState(12)
  const [colorTheme, setColorTheme] = useState({})
  const [accountCutOffDay, setAccountCutOffDay] = useState('End')

  useEffect(() => {
    if (isSignedIn && isLoaded && user) {
      setUserName(user.fullName)
      setUserId(user.id)
      setRole(user.publicMetadata.role)
      setTheme(user.unsafeMetadata.theme)
      setAccountBaseCurrency(user.unsafeMetadata.accountBaseCurrency)
      setTransactionBaseCurrency(user.unsafeMetadata.transactionBaseCurrency)
      setInvestmentBaseCurrency(user.unsafeMetadata.investmentBaseCurrency)
      setAccountMonthlySummaryLimit(user.unsafeMetadata.accountMonthlySummaryLimit)
      setTransactionMonthlySummaryLimit(user.unsafeMetadata.transactionMonthlySummaryLimit)
      setAccountCutOffDay(user.unsafeMetadata.accountCutOffDay)

      let tempColorTheme = {
        bar_chart_color_list: ["#ad4012", "#289e8e", "#b3a723","#c28c1d","#d17016", "#890f0d", "#610303"],

        // red
        //line_chart_color: '#DC2626',
        //line_chart_grid_color: '#FECACA'
      }
      // light / dark theme specific
      if (user.unsafeMetadata.theme.startsWith('light_')) {
        tempColorTheme = {
          ...tempColorTheme,

          positive_text_color: 'text-green-600',
          negative_text_color: 'text-red-600',
          zero_text_color: 'text-gray-500',

          toast_bg_color_hex: '#FFF', // card_bg_color
          toast_text_color_hex: '#000',
          toast_error_text_color_hex: '#DC2626', // red color

          default_bg_color_name: 'white',
          default_bg_color: 'bg-white',
          default_text_color: 'text-black',
          default_border_color: 'border-white',

          secondary_text_color: 'text-gray-500',
          secondary_bg_color: 'bg-gray-100',
          secondary_border_color: 'border-gray-200',
          secondary_divide_color: 'divide-gray-200',

          card_bg_color: 'bg-white',

          // specific color
          black_text_color: 'text-black',
          red_text_color: 'text-red-600',
          blue_text_color: 'text-blue-600',
          green_text_color: 'text-green-600',
          purple_text_color: 'text-purple-600',

          black_bg_color: 'bg-black',
          red_bg_color: 'bg-red-50',
          blue_bg_color: 'bg-blue-50',
          green_bg_color: 'bg-green-50',
          purple_bg_color: 'bg-purple-50',

          red_border_color: 'border-red-600',
          blue_border_color: 'border-blue-600',
          green_border_color: 'border-green-600',

          line_chart_color: '#666666',
          line_chart_grid_color: '#D1D5DB',
          bar_chart_color: '#666666',
          bar_chart_grid_color: '#D1D5DB',
        }

      } else if (user.unsafeMetadata.theme.startsWith('dark_')) {
        tempColorTheme = {
          ...tempColorTheme,

          positive_text_color: 'text-green-500',
          negative_text_color: 'text-red-500',
          zero_text_color: 'text-gray-400',

          toast_bg_color_hex: '#111827', // card_bg_color
          toast_text_color_hex: '#F3F4F6',
          toast_error_text_color_hex: '#FCA5A5', // red color

          default_bg_color_name: 'black',
          default_bg_color: 'bg-black',
          default_text_color: 'text-gray-100',
          default_border_color: 'border-black',

          secondary_text_color: 'text-gray-400',
          secondary_bg_color: 'bg-gray-800',
          secondary_border_color: 'border-gray-700',
          secondary_divide_color: 'divide-gray-700',

          card_bg_color: 'bg-gray-900',

          // specific color
          black_text_color: 'text-black',
          red_text_color: 'text-red-300',
          blue_text_color: 'text-blue-300',
          green_text_color: 'text-green-300',
          purple_text_color: 'text-purple-300',

          black_bg_color: 'bg-black',
          red_bg_color: 'bg-red-900',
          blue_bg_color: 'bg-blue-900',
          green_bg_color: 'bg-green-900',
          purple_bg_color: 'bg-purple-900',

          red_border_color: 'border-red-400',
          blue_border_color: 'border-blue-400',
          green_border_color: 'border-green-400',

          line_chart_color: '#AAAAAA',
          line_chart_grid_color: '#4B5863',
          bar_chart_color: '#AAAAAA',
          bar_chart_grid_color: '#4B5863',
        }
      }
      if (user.unsafeMetadata.theme === 'light_black') {
        tempColorTheme = {
          ...tempColorTheme,

          primary_text_color: 'text-black',
          primary_bg_color: 'bg-black',
          primary_bg_hover_color: 'bg-gray-700',
          primary_border_color: 'border-black',

          inverted_text_color: 'text-white', // use with primary_bg_color

          accent_color: 'accent-black',

          toast_success_text_color_hex: '#000', // primary
        }
      } else if (user.unsafeMetadata.theme === 'light_orange') {
        tempColorTheme = {
          ...tempColorTheme,

          primary_text_color: 'text-orange-500',
          primary_bg_color: 'bg-orange-500',
          primary_bg_hover_color: 'bg-orange-400',
          primary_border_color: 'border-orange-500',          

          inverted_text_color: 'text-white',

          accent_color: 'accent-orange-500',

          toast_success_text_color_hex: '#F97316', // primary
        }
      } else if (user.unsafeMetadata.theme === 'light_teal') {
        tempColorTheme = {
          ...tempColorTheme,

          primary_text_color: 'text-teal-500',
          primary_bg_color: 'bg-teal-500',
          primary_bg_hover_color: 'bg-teal-400',
          primary_border_color: 'border-teal-500',          

          inverted_text_color: 'text-white',

          accent_color: 'accent-teal-500',

          toast_success_text_color_hex: '#14B8A6', // primary
        }
      } else if (user.unsafeMetadata.theme === 'light_pink') {
        tempColorTheme = {
          ...tempColorTheme,

          primary_text_color: 'text-pink-500',
          primary_bg_color: 'bg-pink-500',
          primary_bg_hover_color: 'bg-pink-400',
          primary_border_color: 'border-pink-500',          

          inverted_text_color: 'text-white',

          accent_color: 'accent-pink-500',

          toast_success_text_color_hex: '#EC4899', // primary
        }
      } else if (user.unsafeMetadata.theme === 'dark_yellow') {
        tempColorTheme = {
          ...tempColorTheme,

          primary_text_color: 'text-yellow-500',
          primary_bg_color: 'bg-yellow-500',
          primary_bg_hover_color: 'bg-yellow-600',
          primary_border_color: 'border-yellow-500',          

          inverted_text_color: 'text-black',

          accent_color: 'accent-yellow-500',

          toast_success_text_color_hex: '#EAB308', // primary
        }
      }
      setColorTheme(tempColorTheme)

      setIsUserLoaded(true)
    }    
  }, [isSignedIn, isLoaded, user])

  const getValueColor = (value) => {
    if (value > 0.0) {
      return colorTheme.positive_text_color
    } else if (value < 0.0) {
      return colorTheme.negative_text_color
    } else {
      return colorTheme.zero_text_color
    }
  } 

  const getCountryColor = (country) => {
    switch (country) {
      case 'Hong Kong': 
        return colorTheme.red_text_color
      case 'United Kingdom': 
        return colorTheme.green_text_color
      case 'Australia': 
        return colorTheme.blue_text_color
      default: 
        break
    }
    return colorTheme.primary_text_color
  }

  const getExchangeColor = (exchange, type) => {
    if (type === 'border') {
      let borderColor = colorTheme.secondary_border_color
      if (exchange === 'ASX') {
        borderColor = colorTheme.blue_border_color
      } else if (exchange === 'HKEX') {
        borderColor = colorTheme.red_border_color
      } else if (exchange === 'NYSE') {
        borderColor = colorTheme.green_border_color
      } 
      return borderColor
    } else if (type === 'text') {
      let textColor = colorTheme.secondary_text_color
      if (exchange === 'ASX') {
        textColor = colorTheme.blue_text_color
      } else if (exchange === 'HKEX') {
        textColor = colorTheme.red_text_color
      } else if (exchange === 'NYSE') {
        textColor = colorTheme.green_text_color
      } 
      return textColor
    }
  }
  
  const getThemeName = (theme) => {
    if (theme === 'light_black') {
      return 'Original Black'
    } else if (theme === 'light_orange') {
      return 'Fizzy Orange'
    } else if (theme === 'light_teal') {
      return 'Breezing Sea'
    } else if (theme === 'light_pink') {
      return 'Sparkling Rose'
    } else if (theme === 'dark_yellow') {
      return 'Glowing Dark'
    }
    return ''
  }

  const calculateAccountCutOffDate = (monthEnd) => {
    if (accountCutOffDay === 'End') {
      return monthEnd
    } else {
      const value = moment(monthEnd, "YYYY-MM-DD").add(parseInt(accountCutOffDay), 'days').format("YYYY-MM-DD");  
      return value
    }
  }

  return <UserContext.Provider 
    value={{
      isUserLoaded,
      user,
      role,
      theme,
      userName,
      userId,
      accountBaseCurrency,
      transactionBaseCurrency,
      investmentBaseCurrency,
      accountMonthlySummaryLimit,
      transactionMonthlySummaryLimit,
      colorTheme,
      accountCutOffDay,
      getValueColor,
      getCountryColor,
      getExchangeColor,
      getThemeName,
      calculateAccountCutOffDate
    }}>
      {children}
    </UserContext.Provider>
}

export default UserContext