import React from 'react'
import { SignIn } from "@clerk/clerk-react"

function SignInPage() {
  return (
    <>
      <div className="h-screen flex items-center justify-center h-screen flex items-center">
        <SignIn path="/sign-in" signUpUrl="/sign-up" />
      </div>
    </>
    
  )
}

export default SignInPage