import { useAuth } from "@clerk/clerk-react"
import { Outlet, useNavigate } from "react-router-dom"
import { useContext, useEffect } from "react"
import UserContext from "../context/UserContext"

function ProtectedLayout() {
  const { userId, isLoaded } = useAuth()
  const { isUserLoaded, colorTheme } = useContext(UserContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/sign-in")
    }
  }, [navigate, isLoaded, userId])

  useEffect(() => {
    if (isUserLoaded && !isEmpty(colorTheme)) {
      document.querySelector(':root').style.setProperty('--toastify-color-light', colorTheme.toast_bg_color_hex);
      document.querySelector(':root').style.setProperty('--toastify-text-color-light', colorTheme.toast_text_color_hex);
      document.querySelector(':root').style.setProperty('--toastify-color-success', colorTheme.toast_success_text_color_hex);
    }    
  }, [isUserLoaded, colorTheme])

  const isEmpty = (object) => {
    return Object.keys(object).length === 0 && object.constructor === Object
  }

  if (!isLoaded) return "Loading..."

  return (
    <>
      <div className={`${colorTheme.default_bg_color} ${colorTheme.default_text_color} px-8 py-8 min-h-screen grid grid-cols-1 xl:grid-cols-7`}>
        <div className='hidden xl:block'></div>
        <div className='xl:col-span-5'>
          <Outlet />
        </div>
        <div className='hidden xl:block'></div>
      </div>
    </>
  )
}

export default ProtectedLayout