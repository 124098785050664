import React, { useContext, useEffect, useState } from 'react'
import TransactionContext from '../../context/TransactionContext'
import UserContext from '../../context/UserContext'
import moment from 'moment'
import Card from '../shared/Card'
import WidgetHeader from '../shared/WidgetHeader'
import CurrencyContext from '../../context/CurrencyContext'
import { LargestFont } from '../shared/Constants'
import CurrencyLabel from '../shared/CurrencyLabel'
import DataDependencyContext from '../../context/DataDependencyContext'

function DashboardTransactionWidget() {
  const {transactionList, createViewModel} = useContext(TransactionContext)
  const {transactionBaseCurrency} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {isFullDataLoaded} = useContext(DataDependencyContext)

  
  const [total, setTotal] = useState(0.0)
  const [income, setIncome] = useState(0.0)
  const [expense, setExpense] = useState(0.0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isFullDataLoaded) {
      if (transactionList.length > 0) {
        let total = 0.0
        let income = 0.0
        let expense = 0.0

        const startDate = moment().startOf('month').format('YYYY-MM-DD')
        //const startDate = moment().add(-1, 'months').format('YYYY-MM-DD')
        const endDate = moment().format('YYYY-MM-DD')
        
        const tempTransactionList = transactionList.filter(t => 
          t.transaction_date >= startDate && t.transaction_date <= endDate && !t.is_prepaid && !t.is_special)

        const viewModelList = tempTransactionList.map((t, index) => {
            const vm = createViewModel(t)
            return vm
        })

        viewModelList.forEach((vm) => {
          
          let baseAmount = calculateCurrency(transactionBaseCurrency, vm.currency, vm.transaction_date, vm.amount)
          if (vm.type === 'Expense') {
            baseAmount = baseAmount * -1
          }
          total += baseAmount
          if (vm.type === 'Income') {
            income += baseAmount
          } else if (vm.type === 'Expense') {
            expense += baseAmount
          }

        });

        setTotal(total)
        setIncome(income)
        setExpense(expense)

        setIsLoading(false)  
      }
    }    
  }, [isFullDataLoaded, transactionList])

  if (isLoading) {
    return (
      <>
      <Card className={``}>
        <WidgetHeader title='Net Transactions' />
        <div className="">Loading ...</div>
      </Card>
      <Card className={``}>
        <WidgetHeader title='Incomes' />
        <div className="">Loading ...</div>
      </Card>
      <Card className={``}>
        <WidgetHeader title='Expenses' />
        <div className="">Loading ...</div>
      </Card>
      </>
    )
  }

  return (
    <>
      <Card className={``}>
        <WidgetHeader title='Net Transactions' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={transactionBaseCurrency} 
          value={total} 
          maximumFractionDigits={0}
          theme='dynamic'
        />

        
      </Card>
      <Card className={``}>
        <WidgetHeader title='Incomes' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={transactionBaseCurrency} 
          value={income} 
          maximumFractionDigits={0}
          theme='primary'
        />
      </Card>
      <Card className={``}>
        <WidgetHeader title='Expenses' />

        <CurrencyLabel 
          className={`${LargestFont}`} 
          currency={transactionBaseCurrency} 
          value={expense} 
          maximumFractionDigits={0}
          theme='primary'
        />
      </Card>
    </>
  )
}

export default DashboardTransactionWidget