import React, { useContext } from 'react'
import CurrencyContext from '../../context/CurrencyContext'
import UserContext from '../../context/UserContext'
import moment from 'moment'
import { SmallerFont } from './Constants'

function CurrencyLabel({
  currency, 
  value, 
  maximumFractionDigits = 2, 
  theme = 'dynamic',
  className, 
  children,
  showBaseCurrency = false,
  baseCurrency,
  currencyDate = moment().format('YYYY-MM-DD')}) {

  const {calculateCurrency} = useContext(CurrencyContext)
  const {colorTheme, getValueColor} = useContext(UserContext)

  // primary theme
  let color = colorTheme.primary_text_color
  if (theme === 'dynamic') {
    color = getValueColor(value)
  } else if (theme === 'gray') {
    color = colorTheme.secondary_text_color
  } else if (theme === 'default') {
    color = colorTheme.default_text_color
  }
  
  return (
    <>
      <div className={`${className} ${color}`}>
        <div>
          {children}
          {new Intl.NumberFormat('zh', 
            { style: 'currency', 
              currency: currency,
              maximumFractionDigits: maximumFractionDigits }
            ).format(value)
          }
        </div>
        {showBaseCurrency && currency !== baseCurrency && (
          <div className={`${SmallerFont} ${colorTheme.secondary_text_color} text-right`}>
            {new Intl.NumberFormat('zh', 
              { style: 'currency', 
                currency: baseCurrency,
                maximumFractionDigits: maximumFractionDigits }
              ).format(calculateCurrency(baseCurrency, currency, currencyDate, value))
            }
          </div>
        )}
      </div>
    </>
  )
}

export default CurrencyLabel