import React, { useContext, useEffect, useState } from 'react'
import Card from '../shared/Card'
import UserContext from '../../context/UserContext';
import CurrencyContext from '../../context/CurrencyContext';
import moment from 'moment'
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Legend, Bar } from 'recharts';
import CurrencyLabel from '../shared/CurrencyLabel';
import TransactionContext from '../../context/TransactionContext';
import WidgetHeader from '../shared/WidgetHeader';
import ResponsiveTable from '../shared/ResponsiveTable';
import DataDependencyContext from '../../context/DataDependencyContext';

function TransactionMonthlySummaryWidget() {
  const {transactionBaseCurrency, transactionMonthlySummaryLimit, colorTheme} = useContext(UserContext);
  const {calculateCurrency} = useContext(CurrencyContext)
  const {transactionList, createViewModel} = useContext(TransactionContext)
  const {isFullDataLoaded} = useContext(DataDependencyContext)

  const [chartData, setChartData] = useState([]);

  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isFullDataLoaded) {
      const numberOfMonth = transactionMonthlySummaryLimit
      let tempMonthList = []
      let tempIncomeList = []
      let tempExpenseList = []
      let tempSpecialList = []
      let tempTotalWithSpecialList = []
      let tempTotalWithoutSpecialList = []
      let tempChartDataList = []

      let thisMonthDate = moment().endOf('month').format("YYYY-MM");

      tempMonthList.push(thisMonthDate);

      for (let i = 1; i < numberOfMonth; i++) {
        let tempDate = moment().endOf('month').subtract(i, 'months').format("YYYY-MM");  
        tempMonthList.push(tempDate)
      }

      // sort month from latest to old
      tempMonthList.sort((a, b) => {return a > b ? -1 : 1})

      tempMonthList.forEach((month) => {
        const {tempIncome, tempExpense, tempSpecial, tempTotalWithSpecial, tempTotalWithoutSpecial} = calculate(month, transactionList);
        tempIncomeList.push(tempIncome)
        tempExpenseList.push(tempExpense)
        tempSpecialList.push(tempSpecial)
        tempTotalWithSpecialList.push(tempTotalWithSpecial)
        tempTotalWithoutSpecialList.push(tempTotalWithoutSpecial)

        tempChartDataList.push({
          name: month,
          Income: Math.round(tempIncome),
          Expense: Math.round(tempExpense),
          Total: Math.round(tempTotalWithoutSpecial)
        })
      });

      setChartData(tempChartDataList)    
      
      setTableHeaderList(['Month', 'Income', 'Expense', 'Special', 'Total', 'Total + Special'])
      let tempTableData = []
      tempMonthList.forEach((month, index) => {
        let tempRow = [
          month.substring(0, 7), 
          <CurrencyLabel 
            currency={transactionBaseCurrency}
            value={tempIncomeList[index]}
            maximumFractionDigits={0}
            theme='default'
          />,
          <CurrencyLabel 
            currency={transactionBaseCurrency}
            value={tempExpenseList[index]}
            maximumFractionDigits={0}
            theme='default'
          />,
          (tempSpecialList[index] !== 0.0 ? 
            <CurrencyLabel 
              currency={transactionBaseCurrency}
              value={tempSpecialList[index]}
              maximumFractionDigits={0}
              theme='default'
            /> : <>-</>),
          <CurrencyLabel 
            currency={transactionBaseCurrency}
            value={tempTotalWithoutSpecialList[index]}
            maximumFractionDigits={0}
          />,
          (tempSpecialList[index] !== 0.0 ? 
            <CurrencyLabel 
              currency={transactionBaseCurrency}
              value={tempTotalWithSpecialList[index]}
              maximumFractionDigits={0}
            /> : <>-</>)
        ]

        tempTableData.push(tempRow)
      })
      setTableDataList(tempTableData)

      setIsLoading(false)
    }    
  }, [isFullDataLoaded, transactionList])

  const calculate = (asOfDate, transactionList) => {
    let tempTotalWithSpecial = 0, tempTotalWithoutSpecial = 0, tempIncome = 0, tempExpense = 0, tempSpecial = 0;

    const tempTransactionList = transactionList.filter(t => t.transaction_date.substring(0, 7) === asOfDate)
  
    tempTransactionList.forEach((transaction) => {
      const vm = createViewModel(transaction)

      if (vm.type === 'Income' && !vm.is_special && !vm.is_prepaid) {
        let convertedValue = calculateCurrency(transactionBaseCurrency, vm.currency, asOfDate, vm.amount);
        tempIncome += convertedValue
        tempTotalWithSpecial += convertedValue
        tempTotalWithoutSpecial += convertedValue
      }

      if (vm.type === 'Expense' && !vm.is_special && !vm.is_prepaid) {
        let convertedValue = calculateCurrency(transactionBaseCurrency, vm.currency, asOfDate, vm.amount);
        tempExpense -= convertedValue
        tempTotalWithSpecial -= convertedValue
        tempTotalWithoutSpecial -= convertedValue
      }

      if (vm.is_special) {
        let convertedValue = calculateCurrency(transactionBaseCurrency, vm.currency, asOfDate, vm.amount);
        if (vm.type === 'Expense') {
          convertedValue = -1 * convertedValue
        }
        tempSpecial += convertedValue
        tempTotalWithSpecial += convertedValue
      }
    })
    return {tempIncome, tempExpense, tempSpecial, tempTotalWithSpecial, tempTotalWithoutSpecial};
  }

  const contentStyle = {
    backgroundColor: colorTheme.default_bg_color_name
  }

  if (isLoading) {
    return (
      <>
        <Card className={`mb-8`}>
          <WidgetHeader title='Monthly Summary' />
          <div className="text-xl">Loading ...</div>
        </Card>
      </>
    )
  }

  return (
    <>
      <Card className={`mb-8`}>
        <WidgetHeader title='Monthly Summary' />

        <div className='mt-4'>
          <div className='mb-8'>
            <ResponsiveTable headerList={tableHeaderList} dataTable={tableDataList} />
          </div>
          <div className='grid grid-cols-1 xl:grid-cols-2'>
            <ResponsiveContainer width="100%" height={50 * transactionMonthlySummaryLimit}>
              <BarChart 
                width={500} 
                height={250} 
                data={chartData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke={`${colorTheme.bar_chart_grid_color}`} />
                <XAxis type='number' stroke={`${colorTheme.bar_chart_color}`} />
                <YAxis dataKey="name" type="category" stroke={`${colorTheme.bar_chart_color}`} />
                <Tooltip contentStyle={contentStyle} />
                <Legend />
                <Bar dataKey="Income" fill="#16A34A" />
                <Bar dataKey="Expense" fill="#DC2626" />
                <Bar dataKey="Total" fill={`${colorTheme.bar_chart_color}`} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Card>
    </>
  )
}

export default TransactionMonthlySummaryWidget