import React from 'react'
import { SignUp } from "@clerk/clerk-react"

function SignUpPage() {
  return (
    <>
      <div className="h-screen flex items-center justify-center h-screen flex items-center">
        <SignUp path="/sign-up" signInUrl="/sign-in" />
      </div>
    </>
    
  )
}

export default SignUpPage