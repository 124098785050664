import React, { useEffect, useContext, useState } from 'react'
import AccountContext from '../context/AccountContext';
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import AccountCardGroup from '../components/accounts/AccountCardGroup';
import Checkbox from '../components/shared/Checkbox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../components/shared/PrimaryButton';
import AccountTotalCashWidget from '../components/accounts/AccountTotalCashWidget';
import RolePermissionContainer from '../components/shared/RolePermissionContainer';
import CurrencyContext from '../context/CurrencyContext';
import DataDependencyContext from '../context/DataDependencyContext';
import IconLabel from '../components/shared/IconLabel';
import { FaPlus } from 'react-icons/fa6';

function AccountsPage() {
  const navigate = useNavigate();

  const {filterAccount} = useContext(AccountContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const [pageParams, setPageParams] = useState({
    active_only: searchParams.get("active_only") || true
  });

  const [isLoading, setIsLoading] = useState(true)

  const [groupedInstitutionList, setGroupedInstitutionList] = useState([])
  const [groupedAccountList, setGroupedAccountList] = useState([])

  useEffect(() => {
    // TODO Listen to account deletion from supabase once it is supported
    if (isQuickDataLoaded) {
      let tempActiveOnly = true
      if (searchParams.get('active_only') === "false") {
        tempActiveOnly = false;
      }
      filter(tempActiveOnly)
      setPageParams((prevState) => ({
        ...prevState,
        active_only: tempActiveOnly
      }))
      setIsLoading(false)      
    }    
  }, [isQuickDataLoaded, searchParams])

  const filter = (localHideInactive) => {
    const tempList = filterAccount(localHideInactive)
    groupAccount(tempList)
  }

  const groupAccount = (localList) => {
    // Get unique institution first
    let localUniqueInstitutionList = []
    if (localList && localList.length > 0) {
      localUniqueInstitutionList = localList.reduce((total, item) => {
        return total.includes(item.institution) ? total : [...total, item.institution]
      }, [])      
    }
    let localGroupedAccountList = []
    localUniqueInstitutionList.forEach((i, index) => {
      let singleAccountList = localList.filter((t) => {
        return t.institution === i
      })
      localGroupedAccountList.push(singleAccountList)
    })

    setGroupedInstitutionList(localUniqueInstitutionList)
    setGroupedAccountList(localGroupedAccountList)
    
  }

  if (isLoading) {
    return (<div>Loading...</div>)
  }

  return (
    <>
      <Header title='Accounts' />
      <Navbar currentPage='Accounts' />

      <AccountTotalCashWidget />

      <div className='flex items-center mb-8'>  
        <RolePermissionContainer permission={'create-account'}>
          <PrimaryButton onClick={e => navigate("/accounts/add")} className={'mr-4'}>
            <IconLabel
              left={<FaPlus />}
              right='Account'
            />
          </PrimaryButton>      
        </RolePermissionContainer>
        <Checkbox
          label='Active Account Only'
          name='activeOnly'
          id='activeOnly'
          checked={pageParams.active_only}
          onChange={(e) => {
            let currentActiveOnly = pageParams.active_only;
            let newActiveOnly = !currentActiveOnly;
            let tempParams = {
              ...pageParams,
              active_only: newActiveOnly
            }
            setPageParams(tempParams)
            setSearchParams(prev => {
              prev.set("active_only", newActiveOnly)
              return prev
            }, {replace: false})
            filter(newActiveOnly)
            
          }}
          className="ml-auto md:ml-0"
        />
        
      </div>


      {groupedInstitutionList.map((item, index) => (
        <AccountCardGroup 
          key={index} 
          institution={item} 
          accountList={groupedAccountList[index]} />
      ))}
    </>
  )
}

export default AccountsPage