import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import Header from '../components/shared/Header'
import Inputbox from '../components/shared/Inputbox'
import PrimaryButton from '../components/shared/PrimaryButton'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import Checkbox from '../components/shared/Checkbox'
import TransactionTagContext from '../context/TransactionTagContext'
import { SmallerFont } from '../components/shared/Constants'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import IconLabel from '../components/shared/IconLabel'
import { FaCheck } from 'react-icons/fa'

function TransactionTagsAddPage() {
  const {addTransactionTag} = useContext(TransactionTagContext)
  const {role, userId, colorTheme} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [formData, setFormData] = useState({
    name: '',
    is_income: false,
    is_expense: false,
    is_highlight: false,
    is_active: true,
    analytics_period_summary: false,
    analytics_monthly_summary: false,
    type: ''
  })

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'create-transaction-tag')) {
        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/transaction-tags')
      }
    }    
  }, [isQuickDataLoaded])

  const onMutate = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  const onSave = (e) => {
    e.preventDefault()
    
    let newId = uuidv4();
    let submitFormData = {
      ...formData,
      id: newId,
      user_id: userId
    }

    addTransactionTag(submitFormData);

    toast.success('Success: Create new transaction tag')
    navigate('/transaction-tags')
  }


  const onToggleCheckbox = (e) => {
    const currentValue = formData[e.target.id];
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: !currentValue
    }))
  }

  if (isLoading) {
    return (<h1>Loading...</h1>)
  }

  return (
    <>
      <Header title="Create Transaction Tag" />
        <Inputbox name='name' id='name' placeholder='Name' value={formData.name} onChange={onMutate}/>
        
        <div className='mb-2'>Tag Type</div>
        <Checkbox
          label='Income Tag'
          name='is_income'
          id='is_income'
          checked={formData.is_income}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Expense Tag'
          name='is_expense'
          id='is_expense'
          checked={formData.is_expense}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Is Active'
          name='is_active'
          id='is_active'
          checked={formData.is_active}
          onChange={onToggleCheckbox}
        />

        <div className="mb-4"></div>
        <div className='mb-2'>Analytics</div>
        <Checkbox
          label='Period Summary'
          name='analytics_period_summary'
          id='analytics_period_summary'
          checked={formData.analytics_period_summary}
          onChange={onToggleCheckbox}
        />
        <div className={`${SmallerFont} ${colorTheme.secondary_text_color}`}>* Shown in Tag Amount Summary in Transaction Analytics page</div>
        <div className="mb-4"></div>
        <Checkbox
          label='Monthly Summary'
          name='analytics_monthly_summary'
          id='analytics_monthly_summary'
          checked={formData.analytics_monthly_summary}
          onChange={onToggleCheckbox}
        />
        <div className={`${SmallerFont} ${colorTheme.secondary_text_color}`}>* Shown in Tag Monthly Summary in Transaction Analytics page</div>
        
        <div className="mb-8"></div>
        <div className="flex items-center">        
          <PrimaryButton onClick={onSave}>
            <IconLabel
              left={<FaCheck />}
              right='Create'
            />
          </PrimaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/transaction-tags")}>Cancel</SecondaryButton>  
        </div>  
    </>
  )
}

export default TransactionTagsAddPage