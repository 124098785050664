import React, { useEffect, useContext, useState } from 'react'
import CurrencyContext from '../context/CurrencyContext';
import moment from 'moment/moment'
import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import ResponsiveTable from '../components/shared/ResponsiveTable';
import UserContext from '../context/UserContext';
import RoleContext from '../context/RoleContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleTable from '../components/shared/SimpleTable';
import DataDependencyContext from '../context/DataDependencyContext';

function CurrencyPage() {
  const {getCurrency} = useContext(CurrencyContext)
  const {role} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [latestCurrency, setLatestCurrency] = useState([])
  const [tableHeaderList, setTableHeaderList] = useState([])
  const [tableDataList, setTableDataList] = useState([]) 
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'view-currency-page')) {
        const today = moment().format('YYYY-MM-DD')
        const ccy = getCurrency(today)
        setLatestCurrency(ccy)

        setTableHeaderList(['Currency', 'Rate (per HKD)'])

        let tempTableData = []
        tempTableData.push(['AUD', ccy["aud"]])
        tempTableData.push(['EUR', ccy["eur"]])
        tempTableData.push(['GBP', ccy["gbp"]])
        tempTableData.push(['USD', ccy["usd"]])
        setTableDataList(tempTableData)

        setIsLoading(false)  
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/dashboard')
      }
    }
  }, [isQuickDataLoaded])

  if (isLoading) {
    return (<>
      <Header title='Currency' />
      <Navbar currentPage='Currency' />

      <div className="mb-8">
        Loading ...
      </div>
    </>)
  }

  return (
    <>
      <Header title='Currency' />
      <Navbar currentPage='Currency' />
      <div className='mb-4'>
        <SimpleTable headerList={tableHeaderList} dataTable={tableDataList} option={{
            size: 'default',
            color: 'default',
            showAlternativeColor: false
        }} />
      </div>
      <div>Last Update Date: {latestCurrency["date"]}</div>
    </>
  )
}

export default CurrencyPage