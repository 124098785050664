import './App.css';

import { RouterProvider, createBrowserRouter } from 'react-router-dom'

// Import the layouts
import RootLayout from './layouts/RootLayout'
import ProtectedLayout from './layouts/ProtectedLayout'

import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import DashboardPage from './pages/DashboardPage';
import IndexPage from './pages/IndexPage';
import SettingsPage from './pages/SettingsPage';
import SettingsEditPage from './pages/SettingsEditPage';
import CurrencyPage from './pages/CurrencyPage';
import AccountsPage from './pages/AccountsPage';
import AccountsEditPage from './pages/AccountsEditPage';
import AccountsAddPage from './pages/AccountsAddPage';
import AccountsAnalyticsPage from './pages/AccountsAnalyticsPage';
import TransactionTagsPage from './pages/TransactionTagsPage';
import TransactionTagsAddPage from './pages/TransactionTagsAddPage';
import TransactionTagsEditPage from './pages/TransactionTagsEditPage';
import TransactionsAddPage from './pages/TransactionsAddPage';
import TransactionsPage from './pages/TransactionsPage';
import TransactionsEditPage from './pages/TransactionsEditPage';
import TransactionsAnalyticsPage from './pages/TransactionsAnalyticsPage';
import InvestmentsPage from './pages/InvestmentsPage';
import InvestmentsAnalyticsPage from './pages/InvestmentsAnalyticsPage';
import InvestmentsAddTradePage from './pages/InvestmentsAddTradePage';
import InvestmentsAddDividendPage from './pages/InvestmentsAddDividendPage';
import InvestmentsAddOrderPage from './pages/InvestmentsAddOrderPage';
import ActorsPage from './pages/ActorsPage';
import MoviesPage from './pages/MoviesPage';
import ActorsAddPage from './pages/ActorsAddPage';
import ActorsEditPage from './pages/ActorsEditPage';
import EnergyPage from './pages/EnergyPage';

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "/", element: <IndexPage /> },
      { path: "/sign-in/*", element: <SignInPage /> },
      { path: "/sign-up/*", element: <SignUpPage /> },
      {
        element: <ProtectedLayout />,
        path: "dashboard",
        children: [
          { path: "/dashboard", element: <DashboardPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "settings",
        children: [
          { path: "/settings", element: <SettingsPage /> },
          { path: "/settings/edit", element: <SettingsEditPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "currency",
        children: [
          { path: "/currency", element: <CurrencyPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "accounts",
        children: [
          { path: "/accounts", element: <AccountsPage /> },
          { path: "/accounts/add", element: <AccountsAddPage /> },
          { path: "/accounts/edit/:id", element: <AccountsEditPage /> },
          { path: "/accounts/analytics", element: <AccountsAnalyticsPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "transaction-tags",
        children: [
          { path: "/transaction-tags", element: <TransactionTagsPage /> },
          { path: "/transaction-tags/add", element: <TransactionTagsAddPage /> },
          { path: "/transaction-tags/edit/:id", element: <TransactionTagsEditPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "transactions",
        children: [
          { path: "/transactions", element: <TransactionsPage /> },
          { path: "/transactions/add", element: <TransactionsAddPage /> },
          { path: "/transactions/edit/:id", element: <TransactionsEditPage /> },
          { path: "/transactions/analytics", element: <TransactionsAnalyticsPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "investments",
        children: [
          { path: "/investments", element: <InvestmentsPage /> },
          { path: "/investments/add-order", element: <InvestmentsAddOrderPage /> },
          { path: "/investments/add-trade/:id", element: <InvestmentsAddTradePage /> },
          { path: "/investments/add-dividend/:id", element: <InvestmentsAddDividendPage /> },
          { path: "/investments/analytics", element: <InvestmentsAnalyticsPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "actors",
        children: [
          { path: "/actors", element: <ActorsPage /> },
          { path: "/actors/add", element: <ActorsAddPage /> },
          { path: "/actors/edit/:id", element: <ActorsEditPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "movies",
        children: [
          { path: "/movies", element: <MoviesPage /> },
        ]
      },
      {
        element: <ProtectedLayout />,
        path: "energy",
        children: [
          { path: "/energy", element: <EnergyPage /> },
        ]
      },
    ]
  }
])

function App() {
  return (
    <>
     <RouterProvider router={router} />
     <ToastContainer />
    </>
  );
}

export default App;