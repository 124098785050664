import React, { useContext, useEffect } from 'react'
import {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaCheck, FaPlus } from "react-icons/fa";
import Fuse from 'fuse.js'
import Header from '../components/shared/Header'
import Navbar from '../components/shared/Navbar'
import ResponsiveTable from '../components/shared/ResponsiveTable'
import Inputbox from '../components/shared/Inputbox'
import ActorContext from '../context/ActorContext';
import PrimaryButton from '../components/shared/PrimaryButton';
import UserContext from '../context/UserContext';
import RoleContext from '../context/RoleContext';
import { toast } from 'react-toastify';
import DataDependencyContext from '../context/DataDependencyContext';
import IconLabel from '../components/shared/IconLabel';

function ActorsPage() {
  const navigate = useNavigate();

  const {actorList, decodeCareerStatus, decodeDownloadStatus} = useContext(ActorContext)
  const {role, colorTheme} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)
  const [searchText, setSearchText] = useState('')
  const [vmList, setVMList] = useState([])

  const [filteredVMList, setFilteredVMList] = useState([])

  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);

  const [tableView, setTableView] = useState('simple');

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'enable-movie-app')) {
        const vmList = actorList.map((actor, index) => {
          let attribute = `${actor.type}, ${decodeDownloadStatus(actor.download_status)}, ${decodeCareerStatus(actor.career_status)}, ${actor.update_frequency}, ${actor.is_body ? 'Body' : ''}, ${actor.is_mature ? 'Mature' : ''}, ${actor.is_pretty ? 'Pretty' : ''}, ${actor.is_classic ? 'Classic' : ''}, ${actor.is_west ? 'West' : ''}, Version${actor.version}`
          
          const vm = {
            ...actor,
            attribute: attribute
          }

          return vm
        })     

        setVMList(vmList)

        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/dashboard')
      }
    }
  }, [isQuickDataLoaded, actorList])

  useEffect(() => {
    if (vmList && vmList.length > 0) {
      if (searchText === '') {
        const tempList = vmList.filter((vm) => vm.type !== 'Hidden')
        setFilteredVMList(tempList)
      } else {
        const fuseOptions = {
          // isCaseSensitive: false,
          // includeScore: false,
          // shouldSort: true,
          // includeMatches: false,
          // findAllMatches: false,
          // minMatchCharLength: 1,
          // location: 0,
          // threshold: 0.6,
          // distance: 100,
          // useExtendedSearch: false,
          // ignoreLocation: false,
          // ignoreFieldNorm: false,
          // fieldNormWeight: 1,
          minMatchCharLength: 2,
          threshold: 0.1,
          useExtendedSearch: true,
          keys: [
            {
              name: 'number_id',
              weight: 10
            },
            {
              name: 'jav_id',
              weight: 5
            },
            'name_ch',
            'name_en',
            {
              name: 'attribute',
              weight: 20
            },
            'last_review_month'
          ]
        };
        const fuse = new Fuse(vmList, fuseOptions);        
        const searchedVMList = fuse.search(searchText).map((result) => result.item)        
        setFilteredVMList(searchedVMList)
      }
    }
  }, [searchText, vmList])

  useEffect(() => {
    if (filteredVMList) {
      let tempHeaderList = []
      if (tableView === 'simple') {
        tempHeaderList = [
          '#ID',
          'Name Ch',
          //'Name En',
          //'Type',
          'Career',
          'Download',
          //'Count',
          'Frequency',
          //'Body',
          //'Pretty',
          //'Mature',
          //'Classic',
          //'West',
          'Jav ID',
          'MissAV',
          'Last Review Date',
          'Last Review Movie',
          //'Note',
          'Version',
          'Action'
        ]
      } else {
        tempHeaderList = [
          '#ID',
          'Name Ch',
          'Name En',
          'Type',
          'Career',
          'Download',
          'Count',
          'Frequency',
          'Body',
          'Pretty',
          'Mature',
          'Classic',
          'West',
          'Jav ID',
          'MissAV',
          'Last Review Date',
          'Last Review Movie',
          'Note',
          'Version',
          'Action'
        ]
      }
        
      

      let tempDataTable = []
      if (filteredVMList.length > 0) {
        if (tableView === 'simple') {
          tempDataTable = filteredVMList.map((vm) => {
            return [
              vm.number_id,
              vm.name_ch,
              //vm.name_en,
              //vm.type,
              decodeCareerStatus(vm.career_status),
              decodeDownloadStatus(vm.download_status),
              //vm.movie_count,
              vm.update_frequency,
              //vm.is_body ? <FaCheck /> : <></>,
              //vm.is_pretty ? <FaCheck /> : <></>,
              //vm.is_mature ? <FaCheck /> : <></>,
              //vm.is_classic ? <FaCheck /> : <></>,
              //vm.is_west ? <FaCheck /> : <></>,
              (vm.jav_id && vm.jav_id !== '') ? <Link className={`${colorTheme.primary_text_color}`} to={`http://www.javlibrary.com/en/vl_star.php?s=${vm.jav_id}`}>{vm.jav_id}</Link> : <></>,
              (vm.missav_url && vm.missav_url !== '') ? parseMultipleURLs(vm.missav_url).map((url, index) => {
                return (<Link className={`${colorTheme.primary_text_color} mr-2`} to={url}>MissAV {index + 1}</Link>)})
                : <></>,
              vm.last_review_month,
              vm.last_review_movie + " (" + vm.last_review_movie_release_month + ")",
              //vm.note,
              vm.version,
              <Link className={`${colorTheme.primary_text_color}`} to={`/actors/edit/${vm.id}`}>Edit</Link> 
            ]
          })
        } else {
          tempDataTable = filteredVMList.map((vm) => {
            return [
              vm.number_id,
              vm.name_ch,
              vm.name_en,
              vm.type,
              decodeCareerStatus(vm.career_status),
              decodeDownloadStatus(vm.download_status),
              vm.movie_count,
              vm.update_frequency,
              vm.is_body ? <FaCheck /> : <></>,
              vm.is_pretty ? <FaCheck /> : <></>,
              vm.is_mature ? <FaCheck /> : <></>,
              vm.is_classic ? <FaCheck /> : <></>,
              vm.is_west ? <FaCheck /> : <></>,
              (vm.jav_id && vm.jav_id !== '') ? <Link className={`${colorTheme.primary_text_color}`} to={`http://www.javlibrary.com/en/vl_star.php?s=${vm.jav_id}`}>{vm.jav_id}</Link> : <></>,
              (vm.missav_url && vm.missav_url !== '') ? parseMultipleURLs(vm.missav_url).map((url, index) => {
                return (<Link className={`${colorTheme.primary_text_color} mr-2`} to={url}>MissAV {index > 0 ? index : ''}</Link>)})
                : <></>,
              vm.last_review_month,
              vm.last_review_movie + " (" + vm.last_review_movie_release_month + ")",
              vm.note,
              vm.version,
              <Link className={`${colorTheme.primary_text_color}`} to={`/actors/edit/${vm.id}`}>Edit</Link> 
            ]
          })
        }
        
      }

      setTableHeaderList(tempHeaderList)
      setTableDataList(tempDataTable)
    }
  }, [filteredVMList, tableView])

  const parseMultipleURLs = (urls) => {
    if (!urls || urls === '') {
      return ''
    } else if (urls.includes('|')) {
      return urls.split('|').map(url => url.trim())
    } else {
      return [urls]
    }
  }

  if (isLoading) {
    return (<>
      <Header title='Actors' />
      <Navbar currentPage='Actors' />

      <div className="mb-8">
        Loading ...
      </div>
    </>)
  }

  return (
    <>
      <Header title='Actors' />
      <Navbar currentPage='Actors' />

      <PrimaryButton onClick={e => navigate("/actors/add")}>
        <IconLabel
          left={<FaPlus />}
          right='Actor'
        />
      </PrimaryButton>      
      <div className="mb-8"></div>
      

      <div className="mb-8">
        <Inputbox 
          name="searchText" 
          id="searchText" 
          placeholder='Search' 
          value={searchText} 
          onChange={(e) => setSearchText(e.target.value)} 
          className='mb-4' 
          annotation="Advanced Syntax: (1) =exact, (2) 'include, (3) ^start, (4) .end$"
        />
      </div>

      <div className="flex items-center mb-2">
        <div className=''>Showing: {filteredVMList.length} actor(s)</div>
        <div className='hidden xl:block ml-auto'>Table View</div>
        <div className={`ml-auto xl:ml-4 ${colorTheme.secondary_bg_color} p-1 flex rounded text-sm`}>
          <div></div>
          <button className={`px-4 py-1 ${tableView === 'simple' ? `rounded ${colorTheme.primary_text_color} ${colorTheme.default_bg_color}` : colorTheme.secondary_text_color}`} value='simple' 
            onClick={(e) => {
              e.preventDefault()
              setTableView(e.target.value)
            }}
          >Simple</button>
          <button className={`px-4 py-1 ${tableView === 'details' ? `rounded ${colorTheme.primary_text_color} ${colorTheme.default_bg_color}` : colorTheme.secondary_text_color}`} value='details' 
            onClick={(e) => {
              e.preventDefault()
              setTableView(e.target.value)
            }}>Details</button>
        </div>
      </div>

      <ResponsiveTable headerList={tableHeaderList} dataTable={tableDataList} />
      
    </>    
  )
}

export default ActorsPage