import React, { useContext } from 'react'
import moment from 'moment/moment'
import AccountContext from '../../context/AccountContext';
import AccountCard from './AccountCard'
import { LargerFont } from '../shared/Constants';

function AccountCardGroup({ institution, accountList }) {
  const { getLatestSnapshot } = useContext(AccountContext);

  return (
    <div className='mb-8'>
      <div className={`${LargerFont} mb-2 font-bold`}>{institution}</div>
      <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4'>
      {accountList && accountList.map((item, index) => (
        <AccountCard 
          key={index}
          account={item} 
          latestSnapshot={getLatestSnapshot(item.id, moment().format('YYYY-MM-DD'))} />
      ))}
      </div>
    </div>
  )
}

export default AccountCardGroup