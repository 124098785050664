import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../context/UserContext'
import RoleContext from '../../context/RoleContext'

function RolePermissionContainer({permission, children}) {
  const {isUserLoaded, role} = useContext(UserContext)
  const {isRoleLoaded, checkPermission} = useContext(RoleContext)
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    if (isUserLoaded && isRoleLoaded && checkPermission(role, permission)) {
      setIsEnabled(true)
    }
  }, [isUserLoaded, isRoleLoaded])

  return (
    <>
    {isEnabled && (
      <>{children}</>
    )}
    </>
  )
}

export default RolePermissionContainer