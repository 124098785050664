import React, { useEffect, useContext } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import OrderContext from '../../context/OrderContext'
import Card from '../shared/Card'
import Inputbox from '../shared/Inputbox'
import PrimaryButton from '../shared/PrimaryButton'
import SecondaryButton from '../shared/SecondaryButton'
import ColorNumberText from './ColorNumberText'
import { useNavigate } from 'react-router-dom'
import { FaPen } from 'react-icons/fa6'
import { FaPlus, FaEye, FaTrashAlt } from 'react-icons/fa'
import { evaluate } from 'mathjs'
import ResponsiveTable from '../shared/ResponsiveTable'
import ToggleButton from '../shared/ToggleButton'
import UserContext from '../../context/UserContext'
import { LargerFont, LargestFont, SmallerFont, SmallestFont } from '../shared/Constants'
import IconLabel from '../shared/IconLabel'
import CurrencyLabel from '../shared/CurrencyLabel'
import RolePermissionContainer from '../shared/RolePermissionContainer'
import RoleContext from '../../context/RoleContext'


function OrderCard({order}) {
  const navigate = useNavigate();

  const {role, colorTheme, getExchangeColor} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {updateMarketPrice, updateNote, deleteTrade, deleteDividend} = useContext(OrderContext)
  
  const [isEditMarketPrice, setIsEditMarketPrice] = useState(false)
  const [isEditNote, setIsEditNote] = useState(false)
  const [marketPrice, setMarketPrice] = useState(order.stock.market_price ?? 0.0)
  const [note, setNote] = useState(order.note ?? '')
  const [showTrade, setShowTrade] = useState(false)
  const [showDividend, setShowDividend] = useState(false)
  const {
    analytics, 
    dividend, 
    id, 
    non_matching_trade,
    stock,
    trade,
    currency
   } = order

  const tradeTableHeaderList = ['Date', '', 'Position', 'Price', 'Realized PnL', 'Commission', 'Strategy', 'Note', '']
  let tradeTableDataList = []
  if (trade && trade.length > 0) {

    trade.forEach((item, index) => {
      let newRow = [
        item.trade_date, 
        item.buy_sell,
        item.position, 
        item.price, 
        item.buy_sell === 'Sell' ? 
        <CurrencyLabel 
          value={item.analytics.realized_pnl}
          currency={currency}
          maximumFractionDigits={0}
        /> : <></>,
        <CurrencyLabel 
          value={item.commission}
          currency={currency}
          maximumFractionDigits={0}
          theme={'gray'}
        />,
        item.strategy,
        item.note,
        checkPermission(role, 'delete-trade') ? 
          <button onClick={(e) => onDeleteTrade(e, item.id)}>
            <IconLabel
              left={<FaTrashAlt />}
              right='Delete'
            />   
          </button> : 
          <></>
      ]
      tradeTableDataList.push(newRow)
    })
  }

  const dividendTableHeaderList = ['Date', 'Position', 'Amount@Share', 'Payment', 'Commission', '']
  let dividendTableDataList = []
  if (dividend && dividend.length > 0) {

    dividend.forEach((item, index) => {
      let newRow = [
        item.payment_date,
        item.position,
        item.amount_per_share,
        <CurrencyLabel 
          value={item.position * item.amount_per_share - item.commission}
          currency={currency}
          maximumFractionDigits={0}
          theme={'gray'}
        />,
        <CurrencyLabel 
          value={item.commission}
          currency={currency}
          maximumFractionDigits={0}
          theme={'gray'}
        />,
        checkPermission(role, 'delete-dividend') ? 
          <button onClick={(e) => onDeleteDividend(e, item.id)}>
            <IconLabel
              left={<FaTrashAlt />}
              right='Delete'
            />   
          </button> : 
          <></>
      ]
      dividendTableDataList.push(newRow)
    })
  }

  const onDeleteTrade = async (e, tradeId) => {
    e.preventDefault()

    if (window.confirm('Are you sure to delete?')) {
      const result = deleteTrade(id, tradeId)
      if (result) {
        toast.success('SUCCESS: Delete Trade')
      } else {
        toast.success('ERROR: Cannot Delete Trade')
      }
      
    }
  }

  const onDeleteDividend = async (e, dividendId) => {
    e.preventDefault()

    if (window.confirm('Are you sure to delete?')) {
      const result = deleteDividend(id, dividendId)
      if (result) {
        toast.success('SUCCESS: Delete Dividend')
      } else {
        toast.success('ERROR: Cannot Delete Dividend')
      }
    }
  }

  const saveNote = async () => {
    const result = await updateNote(note, id)
    if (result) {
      toast.success('SUCCESS: Save Note')
    } else {
      toast.error('ERROR: Cannot Save Note')
    }
    
  }

  return (
    <Card className='mb-8'>
      <div className={`border-l-4 ${analytics.is_active ? getExchangeColor(stock.exchange, 'border') : colorTheme.secondary_border_color} pl-3`}>
        <div className='flex flex-col md:flex-row'>
          <div className="flex items-end mb-4">
            <div className={`${LargestFont} mr-4 ${analytics.is_active ? getExchangeColor(stock.exchange, 'text') : colorTheme.secondary_text_color}`}>{stock.name}</div>
            <div className={`${colorTheme.secondary_text_color} ${LargerFont} ml-auto md:ml-0`}>{stock.code}.{stock.exchange}</div>
          </div>
          <div className="flex items-center mb-4 ml-auto">
            {analytics.is_active && checkPermission(role, 'create-trade') && (
              <PrimaryButton className={``} onClick={e => navigate(`/investments/add-trade/${order.id}`)}>
                <IconLabel
                  left={<FaPlus />}
                  right='Trade'
                />
              </PrimaryButton>    
            )}            
            <RolePermissionContainer permission={'create-dividend'}>
              <PrimaryButton className={`ml-2`} onClick={e => navigate(`/investments/add-dividend/${order.id}`)}>
                <IconLabel
                  left={<FaPlus />}
                  right='Dividend'
                />
              </PrimaryButton>    
            </RolePermissionContainer>
          </div>
          
          
        </div>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-8'>
          <div>
            <div className={`border-b ${colorTheme.secondary_border_color} pb-1 mb-1 font-bold`}>PnL</div>
            <ColorNumberText isColoured={true} text='Realized' value={analytics.realized_pnl} percentage={analytics.realized_yield} currency={currency} dp={0} />
            <ColorNumberText isColoured={true} text='Unrealized' value={analytics.unrealized_pnl} percentage={analytics.unrealized_yield} currency={currency} dp={0} />
            <ColorNumberText isColoured={false} text='B/E Price' value={analytics.breakeven_price} />
          </div>

          <div>
            <div className={`border-b ${colorTheme.secondary_border_color} pb-1 mb-1 font-bold`}>Dividend</div>
            <ColorNumberText isColoured={false} text='Total' value={analytics.total_dividend} currency={currency} dp={0} />
            <ColorNumberText isColoured={true} text='P/L' value={analytics.realized_pnl_with_dividend}  percentage={analytics.realized_yield_with_dividend} currency={currency} dp={0} />
            <ColorNumberText isColoured={false} text='B/E Price' value={analytics.breakeven_price_with_dividend} />
          </div>

          <div>
            <div className={`border-b ${colorTheme.secondary_border_color} pb-1 mb-1 font-bold`}>Market</div>
            {stock.market_price && (
              <ColorNumberText isColoured={false} text='Market Value' value={stock.market_price * analytics.active_position} currency={currency} dp={0} />
            )}
            {stock.market_price && (
              <ColorNumberText isColoured={false} text='Market Value' value={stock.market_price * analytics.active_position} currency={currency} dp={0} />
            )}
            {stock.market_price && (
              <ColorNumberText isColoured={false} text='Price' value={stock.market_price} />
            )}
            {stock.market_price && (
              <div className={`${SmallerFont} ${colorTheme.secondary_text_color}`}>Updated: {stock.market_price_date}</div>
            )}
          </div>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
          <div>
            <div className={`border-b ${colorTheme.secondary_border_color} pb-1 mb-1 font-bold`}>Price</div>
            <ColorNumberText isColoured={false} text='Buy' value={analytics.average_buy_price} />
            <ColorNumberText isColoured={false} text='Sell' value={analytics.average_sell_price} />
          </div>

          <div>
            <div className={`border-b ${colorTheme.secondary_border_color} pb-1 mb-1 font-bold`}>Position</div>
            <ColorNumberText isColoured={false} text='Buy' value={analytics.total_buy_position} />
            <ColorNumberText isColoured={false} text='Sell' value={analytics.total_sell_position} />
            <ColorNumberText isColoured={false} text='Active' value={analytics.active_position} />
          </div>

          <div>
            <div className={`border-b ${colorTheme.secondary_border_color} pb-1 mb-1 font-bold`}>Commission</div>
            <ColorNumberText isColoured={false} text='Trade' value={analytics.total_trade_commission} dp={0} />
            <ColorNumberText isColoured={false} text='Dividend' value={analytics.total_dividend_commission} dp={0} />
            <ColorNumberText isColoured={false} text='Total' value={analytics.total_commission} dp={0} />
          </div>
        </div>

        {order.note && order.note !== '' && (
          <div className={`py-1 px-2 border-l-4 ${colorTheme.secondary_border_color} ${colorTheme.secondary_bg_color} mb-4`}>{order.note}</div>
        )}

        <div className='flex flex-col md:flex-row'>
          <div className="flex items-center">
            {trade && trade.length > 0 && 
              <ToggleButton 
                id='show_hide_trade' 
                label={
                  <IconLabel
                    left={<FaEye />}
                    right='Trades'
                  />
                }
                state={showTrade} 
                onClick={(e) => {
                  e.preventDefault()
                  setShowTrade((prevState) => !prevState)
                }}              
              />}

            {dividend && dividend.length > 0 && 
              <ToggleButton 
                id='show_hide_dividend' 
                label={
                  <IconLabel
                    left={<FaEye />}
                    right='Dividends'
                  />
                }
                state={showDividend} 
                onClick={(e) => {
                  e.preventDefault()
                  setShowDividend((prevState) => !prevState)
                }}              
              />}
          </div>
          
          <div className="flex items-center">
            <RolePermissionContainer permission={'update-market-price'}>
              <ToggleButton 
                id='show_hide_edit_market_price' 
                label={
                  <IconLabel
                    left={<FaPen />}
                    right='Price'
                  />
                }
                state={isEditMarketPrice} 
                onClick={(e) => {
                  e.preventDefault()
                  setIsEditMarketPrice((prevState) => !prevState)
                }}              
              />
            </RolePermissionContainer>
            <RolePermissionContainer permission={'update-order-note'}>
              <ToggleButton 
                id='show_hide_edit_note' 
                label={
                  <IconLabel
                    left={<FaPen />}
                    right='Note'
                  />
                }
                state={isEditNote} 
                onClick={(e) => {
                  e.preventDefault()
                  setIsEditNote((prevState) => !prevState)
                }}              
              />
            </RolePermissionContainer>
          </div>          
        </div>

        {showTrade && (
          <>
            <div className='font-bold mb-2 mt-4'>Trades</div>
            <ResponsiveTable headerList={tradeTableHeaderList} dataTable={tradeTableDataList} 
              option={{
                size: 'small',
                color: 'gray'
              }} 
            />
            <div className="mb-4"></div>
          </>          
        )}

        {showDividend && dividend && dividend.length > 0 && (
          <>
            <div className='font-bold mb-2 mt-4'>Dividends</div>
            <ResponsiveTable headerList={dividendTableHeaderList} dataTable={dividendTableDataList} 
              option={{
                size: 'small',
                color: 'gray'
              }} 
            />
            <div className="mb-4"></div>
          </>          
        )}

        {isEditMarketPrice && (
          <form className={`flex flex-col md:flex-row items-end rounded px-3 pt-2 mt-4 ${colorTheme.secondary_bg_color}`}>
            <div className='w-full'>
              <Inputbox 
                name='market-price'
                id='market-price'
                placeholder='Market Price'
                className={`md:mr-4`}
                value={marketPrice} 
                onChange={(e) => {
                  setMarketPrice(e.target.value)
                }}
              />
            </div>
            
            <div className='flex items-center'>
              <PrimaryButton className='mb-4' onClick={async (e) => {
                e.preventDefault()
                setIsEditMarketPrice(false)
                let newValue = Math.round(evaluate(marketPrice.toString()) * 100) / 100
                // Save to DB
                // Update state
                const result = await updateMarketPrice(newValue, stock.id, id)
                if (result) {
                  toast.success('SUCCESS: Update Market Price')
                } else {
                  toast.error('ERROR: Cannot Update Market Price')
                }
              }}>Update</PrimaryButton>
              <SecondaryButton className='mb-4 ml-2' onClick={(e) => {
                e.preventDefault()
                setIsEditMarketPrice(false)
              }}>Cancel</SecondaryButton>          
            </div>
            
          </form>
        )}

        {isEditNote && (
          <form className={`flex flex-col md:flex-row items-end rounded px-3 pt-2 mt-4 ${colorTheme.secondary_bg_color}`}>
            <div className='w-full'>
              <Inputbox 
                name='note'
                id='note'
                placeholder='Note'
                className={`md:mr-4`}
                value={note} 
                onChange={(e) => {
                  setNote(e.target.value)
                }}
              />
            </div>
            
            <div className='flex items-center'>
              <PrimaryButton className='mb-4' onClick={async (e) => {
                e.preventDefault()
                setIsEditNote(false)
                // Save to DB
                // Update state
                //await updateMarketPrice(marketPrice, stock.id, order.id)
                await saveNote()
              }}>Update</PrimaryButton>
              <SecondaryButton className='mb-4 ml-2' onClick={(e) => {
                e.preventDefault()
                setIsEditNote(false)
              }}>Cancel</SecondaryButton>        
            </div>  
          </form>
        )}
        

        <RolePermissionContainer permission={'view-order-id'}>
          <div className={`mt-4 ${SmallestFont} ${colorTheme.secondary_text_color}`}>{order.id}</div>
        </RolePermissionContainer>
      </div>
    </Card>
  )
}

export default OrderCard
