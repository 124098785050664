import React from 'react'
import {useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import ActorContext from '../context/ActorContext'
import UserContext from '../context/UserContext'
import Inputbox from '../components/shared/Inputbox'
import ToggleButton from '../components/shared/ToggleButton'
import PrimaryButton from '../components/shared/PrimaryButton'
import SecondaryButton from '../components/shared/SecondaryButton'
import Header from '../components/shared/Header'
import Navbar from '../components/shared/Navbar'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import IconLabel from '../components/shared/IconLabel'
import { FaCheck } from 'react-icons/fa'


function ActorsAddPage() {
  const {isActorExists, addActor, decodeCareerStatus, decodeDownloadStatus} = useContext(ActorContext)
  const {role} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'enable-movie-app')) {
        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/dashboard')
      }
    }
  }, [isQuickDataLoaded])

  const [formData, setFormData] = useState({
    id: '',
    number_id: '',
    name_ch: '',
    name_en: '',
    version: 1,
    type: 'Normal',
    note: '',
    last_review_month: '',
    last_review_movie: '',
    last_review_movie_release_month: '',
    jav_id: '',
    is_west: false,
    is_pretty: false,
    is_mature: false,
    is_classic: false,
    is_body: false,
    image_url: '',
    download_status: '',
    career_status: '',
    update_frequency: '',
    missav_url: ''
  })

  const navigate = useNavigate()

  const {id,
    number_id,
    name_ch,
    name_en,
    version,
    type,
    note,
    last_review_movie,
    last_review_movie_release_month,
    last_review_month,
    jav_id,
    is_west,
    is_pretty,
    is_mature,
    is_classic,
    is_body,
    image_url,
    download_status,
    career_status,
    update_frequency,
    missav_url} = formData

  const onMutate = (e) => {
    let newValue = e.target.value
    let targetId = e.target.id

    if (targetId === 'download_status' || targetId === 'career_status' || targetId === 'update_frequency') {
      if (download_status === e.target.value) {
        newValue = ''
      }
    }

    // Check for boolean
    if (e.target.value === 'true') {
      newValue = true
    }
    if (e.target.value === 'false') {
      newValue = false
    }

    
    setFormData((prevState) => ({
      ...prevState,
      [targetId]: newValue
    }))
  }

  const onSave = async (e) => {
    e.preventDefault()

    let formDataCopy = {
      ...formData
    }

    if (isActorExists(formDataCopy.jav_id)) {
      toast.error('Duplicate Jav ID: ' + formDataCopy.jav_id)
      formDataCopy.jav_id = ''
      setFormData(formDataCopy)
      return
    }

    if (formDataCopy.type === '') {
      toast.error('Missing actor type')
      return
    }

    formDataCopy.id = uuidv4()

    const result = await addActor(formDataCopy)
   
    if (result) {
      toast.success('Save successful')
      if (type === 'Hidden') {
        setFormData({
          ...formData,
          javId: ''
        })
      } else {
        navigate('/actors')
      }      
    } else {
      toast.error('ERROR: Cannot add actor')
    }
  }

  const toggleAttribute = (attribute) => {
    setFormData((prevState) => ({
      ...prevState,
      [attribute]: !prevState[attribute]
    }))
  }

  if (isLoading) {
    return (<>
      <Header title='Actors' />
      <Navbar currentPage='Actors' />

      <div className="mb-8">
        Loading ...
      </div>
    </>)
  }
  
  return (
    
    <div>
      <Header title='New Actors' />
      <Navbar currentPage='Actors' />

      <form>
        <Inputbox
          name="jav_id"
          id="jav_id"
          placeholder='Jav ID'
          value={jav_id}
          onChange={onMutate}
          className='mb-4'
        />
        <Inputbox  
          type='number'
          name="version"
          id="version"
          placeholder='Version'
          value={version}
          onChange={onMutate}
          className='mb-4'
        />

        <div className='mb-2'>Type</div>
        <div className="flex mb-4">
          {
            ['Normal', 'Highlight', 'Hidden'].map((item, index) => (
              <ToggleButton id='type' value={item} label={item} onClick={onMutate} state={item === type} />
            ))
          }
        </div>

        <Inputbox  
          name="number_id"
          id="number_id"
          placeholder='Number ID, e.g. 015'
          value={number_id}
          onChange={onMutate}
          className='mb-4'
        />

        <Inputbox  
          name="name_en"
          id="name_en"
          placeholder='Name En'
          value={name_en}
          onChange={onMutate}
          className='mb-4'
        />

        <Inputbox  
          name="name_ch"
          id="name_ch"
          placeholder='Name Ch'
          value={name_ch}
          onChange={onMutate}
          className='mb-4'
        />

        <Inputbox  
          name="last_review_month"
          id="last_review_month"
          placeholder='Last Review Month (YYYY-MM)'
          value={last_review_month}
          onChange={onMutate}
          className='mb-4'
        />

        <Inputbox  
          name="last_review_movie"
          id="last_review_movie"
          placeholder='Last Review Movie'
          value={last_review_movie}
          onChange={onMutate}
          className='mb-4'
        />

        <Inputbox  
          name="last_review_movie_release_month"
          id="last_review_movie_release_month"
          placeholder='Last Review Movie Release Month (YYYY-MM)'
          value={last_review_movie_release_month}
          onChange={onMutate}
          className='mb-4'
        />

        <Inputbox  
          name="missav_url"
          id="missav_url"
          placeholder='MissAV URL'
          value={missav_url}
          onChange={onMutate}
          className='mb-4'
          annotation={`Support multiple URLs by using pipe '|' separator`}
        />

        {/* Download Status */}
        <div className='mb-1'>Download Status</div>
        <div className='flex items-center mb-4'>
        {['DA', 'DR', 'ND'].map((item, index) => (
            <ToggleButton id='download_status' value={item} label={decodeDownloadStatus(item)} onClick={onMutate} state={item === download_status} />
          ))          
        }
        </div>

        {/* Career Status */}
        <div className='mb-1'>Career Status</div>
        <div className='flex items-center mb-4'>
          {['AT', 'RT', 'IA'].map((item, index) => (
            <ToggleButton id='career_status' value={item} label={decodeCareerStatus(item)} onClick={onMutate} state={item === career_status} />
            ))
          }
        </div>

        {/* Update Frequency */}
        <div className='mb-1'>Update Frequency</div>
        <div className='flex items-center flex-wrap mb-2'>
        {['Monthly', 'Quarterly', 'Random', 'N/A'].map((item, index) => (
            <ToggleButton id='update_frequency' value={item} label={item} onClick={onMutate} state={item === update_frequency} className={`mb-2`} />
            ))
          }
        </div>

        {/* Attribute Selector */}
        <div className=''>Attribute</div>
        <div className='flex items-center flex-wrap mb-2'>
          <ToggleButton id='is_body' value={is_body} label='Body' onClick={() => toggleAttribute('is_body')} state={is_body} className={`mb-2`} />

          <ToggleButton id='is_mature' value={is_mature} label='Mature' onClick={() => toggleAttribute('is_mature')} state={is_mature} className={`mb-2`} />

          <ToggleButton id='is_pretty' value={is_pretty} label='Pretty' onClick={() => toggleAttribute('is_pretty')} state={is_pretty} className={`mb-2`} />

          <ToggleButton id='is_classic' value={is_classic} label='Classic' onClick={() => toggleAttribute('is_classic')} state={is_classic} className={`mb-2`} />

          <ToggleButton id='is_west' value={is_west} label='West' onClick={() => toggleAttribute('is_west')} state={is_west} className={`mb-2`} />
        </div>

        <Inputbox  
          name="note"
          id="note"
          placeholder='Note'
          value={note}
          onChange={onMutate}
          className='mb-8'
        />

        <div className="flex items-center mb-2">
          <PrimaryButton onClick={onSave}>
            <IconLabel
              left={<FaCheck />}
              right='Create'
            />
          </PrimaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/actors")}>Cancel</SecondaryButton>  
        </div>
        

      </form>
    </div>
  )
}

export default ActorsAddPage