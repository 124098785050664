import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'

function PrimaryButton({children, onClick, className}) {
  const {colorTheme} = useContext(UserContext)

  return (
    <button className={`${colorTheme.primary_bg_color} ${colorTheme.inverted_text_color} hover:${colorTheme.primary_bg_hover_color} px-3 py-1 rounded shadow ${className}`} onClick={onClick}>{children}</button>
  )
}

export default PrimaryButton