import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/shared/Header'
import Navbar from '../components/shared/Navbar'
import AddMovie from '../components/movies/AddMovie'
import BulkIgnore from '../components/movies/BulkIgnore'
import UserContext from '../context/UserContext'
import RoleContext from '../context/RoleContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DataDependencyContext from '../context/DataDependencyContext'

function MoviesPage() {
  const {role} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  // Load states from Firebase when component mounts
  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'enable-movie-app')) {
        setIsLoading(false)  
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/dashboard')
      }
    }    
  }, [isQuickDataLoaded]);

  if (isLoading) {
    return (<>
      <Header title='Movies' />
      <Navbar currentPage='Movies' />

      <div className="mb-8">
        Loading ...
      </div>
    </>)
  }

  return (
    <>
      <Header title='Movies' />
      <Navbar currentPage='Movies' />

      <div className="mb-8">
        <AddMovie />
      </div>

      <div className="mb-8">
        <BulkIgnore />
      </div>
      
    </>  
  )
}

export default MoviesPage