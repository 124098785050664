import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'

function SecondaryButton({children, className, onClick}) {
  const {colorTheme} = useContext(UserContext)
  return (
    <button className={`px-3 py-1 rounded hover:${colorTheme.secondary_bg_color} ${colorTheme.secondary_text_color} ${className}`} onClick={onClick}>{children}</button>
  )
}

export default SecondaryButton