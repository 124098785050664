import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { evaluate } from 'mathjs'
import Header from '../components/shared/Header'
import Checkbox from '../components/shared/Checkbox'
import Inputbox from '../components/shared/Inputbox'
import Radiobox from '../components/shared/Radiobox'
import PrimaryButton from '../components/shared/PrimaryButton'
import TransactionContext from '../context/TransactionContext'
import TransactionTagContext from '../context/TransactionTagContext'
import AccountContext from '../context/AccountContext'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import { FaCheck, FaTrashAlt } from "react-icons/fa";
import { SmallerFont } from '../components/shared/Constants'
import IconLabel from '../components/shared/IconLabel'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'

function TransactionsEditPage() {
  const {role, userId, colorTheme} = useContext(UserContext);
  const {checkPermission} = useContext(RoleContext)
  const {findTransaction, updateTransaction, deleteTransaction} = useContext(TransactionContext)
  const {accountList, getAccountName} = useContext(AccountContext)
  const {transactionTagList} = useContext(TransactionTagContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)

  const [localTransaction, setLocalTransaction] = useState({})
  const [localTagList, setLocalTagList] = useState([])
  const [localAccountList, setLocalAccountList] = useState([])

  const [formAmount, setFormAmount] = useState('')
  const [formDate, setFormDate] = useState(moment().format('YYYY-MM-DD'))
  const [formType, setFormType] = useState('Expense')
  const [formIsSpecial, setFormIsSpecial] = useState(false)
  const [formIsPrepaid, setFormIsPrepaid] = useState(false)
  const [formAccountId, setFormAccountId] = useState('')
  const [formTagList, setFormTagList] = useState([])
  const [formNote, setFormNote] = useState('')

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'edit-transaction')) {
        const transaction = findTransaction(params.id)
        setLocalTransaction(transaction)

        setFormAmount(transaction.amount)
        setFormDate(transaction.transaction_date)
        setFormType(transaction.type)
        setFormIsSpecial(transaction.is_special)
        setFormIsPrepaid(transaction.is_prepaid)
        setFormNote(transaction.note)

        setFormAccountId(transaction.account_v2.id)

        if (transaction.transaction_r_transactionTag_v2 && transaction.transaction_r_transactionTag_v2.length > 0) {
          let tempTagIdList = transaction.transaction_r_transactionTag_v2.map((tag) => tag.transactionTag_v2.id)
          setFormTagList(tempTagIdList)     
        } else {
          setFormTagList([])
        }
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/transactions')
      }
    }
  }, [isQuickDataLoaded])

  useEffect(() => {
    if (isQuickDataLoaded && !isEmpty(localTransaction)) {
      if (formType === 'Income') {
        setLocalAccountList(accountList.filter((a) => a.is_income))
      } else if (formType === 'Expense') {
        setLocalAccountList(accountList.filter((a) => a.is_expense))
      }
    }
  }, [isQuickDataLoaded, localTransaction, accountList, formType])

  useEffect(() => {
    if (isQuickDataLoaded && !isEmpty(localTransaction)) {
      if (formType === 'Income') {
        setLocalTagList(transactionTagList.filter((a) => a.is_income && a.is_active))
      } else if (formType === 'Expense') {
        setLocalTagList(transactionTagList.filter((a) => a.is_expense && a.is_active))
      }   
    }
  }, [isQuickDataLoaded, localTransaction, transactionTagList, formType])

  useEffect(() => {
    if (isQuickDataLoaded && !isEmpty(localTransaction)) {
      setIsLoading(false)
    }    
  }, [isQuickDataLoaded, localTransaction])

  const updateFormTagList = (tagId) => {
    let tempList = [...formTagList]
    if (tempList.includes(tagId)) {
      // Remove
      tempList = tempList.filter(item => item !== tagId)
    } else {
      tempList.push(tagId)
    }

    setFormTagList(tempList)
  }

  const isEmpty = (object) => {
    return Object.keys(object).length === 0 && object.constructor === Object
  }

  const onSave = async (e) => {
    e.preventDefault()

    if (formAccountId === '')
    {
      toast.error('ERROR Account is empty')
      return
    }
    if (formAmount === '')
    {
      toast.error('ERROR Transaction amount is empty')
      return
    }
    if (formIsSpecial && formIsPrepaid) {
      toast.error('ERROR A transaction cannot be special and prepaid')
      return
    }

    // create new
    const transactionId = localTransaction.id
    const newTransaction = {
      id: transactionId,
      type: formType, 
      transaction_date: formDate,
      note: formNote,
      amount: Math.round(evaluate(formAmount.toString()) * 100) / 100,
      account_id: formAccountId,
      user_id: userId,
      is_special: formIsSpecial,
      is_prepaid: formIsPrepaid,
    }

    let newTagList = []
    if (formTagList && formTagList.length > 0) {
      formTagList.forEach((item, index) => {
        const newTag = {
          id: uuidv4(),
          transaction_id: transactionId,
          transactionTag_id: item,
          user_id: userId
        }

        newTagList.push(newTag)
      })
    } else {
      // Error?
    }

    const result = await updateTransaction(newTransaction, newTagList)
    if (result) {
      toast.success('Success: Update transaction')
      navigate('/transactions')
    } else {
      toast.error('ERROR Cannot update transaction')
    }
  }

  const onDelete = async (e) => {
    e.preventDefault()

    if (window.confirm('Confirm delete transaction?')) { 
      const result = await deleteTransaction(localTransaction.id)
      if (result) {
        toast.success('Success: Delete transaction')
        navigate('/transactions')
      } else {
        toast.error('ERROR Cannot delete transaction')
      }
    }
  }

  if (isLoading) {
    return (<h1>Loading...</h1>)
  }

  return (
    <>
      <Header title={`Update ${formType}`} />
      <Inputbox 
        name='amount'
        id='amount'
        placeholder='Amount'
        value={formAmount}
        onChange={(e) => {setFormAmount(e.target.value)}} 
        annotation="Accepts Math Expression, e.g. 1+2-3"      
      />

      <Inputbox 
        name='transaction_date'
        id='transaction_date'
        type='date'
        placeholder='Transaction Date'
        value={formDate}
        onChange={(e) => {setFormDate(e.target.value)}}   
      />

      <div className="mb-4"></div>

      <div className='mb-2'>Type</div>

      <Checkbox
        label='Special (One-off transaction)'
        name='is_special'
        id='is_special'
        checked={formIsSpecial}
        onChange={(e) => {
          const newValue = !formIsSpecial
          setFormIsSpecial(newValue)
        }}   
      />
      <Checkbox
        label='Prepaid (Not calculated in total)'
        name='is_prepaid'
        id='is_prepaid'
        checked={formIsPrepaid}
        onChange={(e) => {
          const newValue = !formIsPrepaid
          setFormIsPrepaid(newValue)
        }}   
      />
      <div className={`${colorTheme.red_text_color} ${SmallerFont}`}>NOTE: A transaction can only be EITHER special or prepaid</div>

      <div className="mb-4"></div>

      <div className='mb-1'>Account</div>
        {localAccountList && localAccountList.map((item, index) => {
          return (<div key={index}>
            <Radiobox 
              label={getAccountName(item)}
              name='formAccount'
              id={item.id} 
              checked={formAccountId === item.id} 
              onChange={(e) => {
                setFormAccountId(item.id)
              }}
            />
          </div>)
        })}
        <div className="mb-4"></div>    

        <div className='mb-1'>Tag</div>
        <div className="flex items-center flex-wrap mb-4">
          {localTagList && localTagList.map((item, index) => {
            return (<div key={index} className='mr-4 mb-1'>
              <Checkbox 
                label={item.name}
                name='formTagList'
                id={item.id}
                checked={formTagList.includes(item.id)}
                onChange={(e) => {
                  updateFormTagList(item.id)
                }} 
              />
            </div>)
          })}
        </div>    

        <Inputbox 
          name='note'
          id='note'
          placeholder='Note'
          value={formNote}
          onChange={(e) => {setFormNote(e.target.value)}}   
        />

        
        <div className="mb-8"></div>
        <div className="flex items-center">        
          <PrimaryButton onClick={onSave} className={`mr-2`}>
            <IconLabel
              left={<FaCheck />}
              right='Update'
            />
          </PrimaryButton>
          <SecondaryButton onClick={onDelete}>
            <IconLabel
              left={<FaTrashAlt />}
              right='Delete'
            />          
          </SecondaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/transactions")}>Cancel</SecondaryButton>  
        </div>  
    </>
  )
}

export default TransactionsEditPage