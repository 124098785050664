import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { SmallerFont } from './Constants'

function SimpleTable({className, headerList, dataTable, option = {
  size: 'default',
  color: 'default',
  showAlternativeColor: false
}}) {
  const {colorTheme} = useContext(UserContext)

  const size = option.size
  const color = option.color
  let cellPadding = 'pr-2' // 'py-1 px-2'
  let alternativeRowBg = option.showAlternativeColor ? colorTheme.secondary_bg_color : ''
  let fontSize = ''
  let textColor = ''

  if (size === 'small') {
    fontSize = SmallerFont
  }

  if (color === 'gray') {
    textColor = colorTheme.secondary_text_color
  }  

  return (
    <>
      <table className={`${fontSize} ${textColor} ${className}`}>
        {headerList && headerList.length > 0 && (
          <thead className=''>
            <tr className={`${alternativeRowBg} `}>
              {headerList.map((item, index) => {
                return <th className={`${cellPadding} text-left [&:not(:first-child)]:text-right`} key={index}>{item}</th>
              })}
            </tr>
          </thead>
        )}
        {dataTable && dataTable.length > 0 && (
          <tbody>
            {dataTable.map((row, rowIndex) => {
              return (
                <tr key={rowIndex} className={`even:${alternativeRowBg}`}>
                  {row.map((col, colIndex) => {
                    return (
                      <td key={colIndex} className={`${cellPadding} [&:not(:first-child)]:text-right`}>
                        {col}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
    </>
  )
}

export default SimpleTable